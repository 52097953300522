import React, { useEffect, useState } from 'react';
import MockDoneBlock from './Partials/MockDoneBlock';
import MockTimerBanner from './Partials/MockTimerBanner';
import styles from './MockInnerPage.module.css';
import ContentGenerator from '../../../components/Partials/ContentGenerator';
import MockBackButton from './Partials/MockBackButton';
import { useLocation } from 'react-router-dom';
import { CodeEditor } from '../../../components/CodeEditor';
/**
 * TODO:
 * Refine "DONE" logic
 * Refine limit logic
 * Clear storage on component destruction useEffect
 */
const getPersistedData = () => {
	const persistentCurrentQuestion = JSON.parse(localStorage.getItem('currentQuestion'));
	const persistentPrevQuestion = JSON.parse(localStorage.getItem('prevQuestion'));
	const persistentQIndex = parseInt(localStorage.getItem('qIndex'));
	const persistentWhiteboardLimit = parseInt(localStorage.getItem('whiteboardLimit'));
	return {
		persistentCurrentQuestion,
		persistentPrevQuestion,
		persistentQIndex,
		persistentWhiteboardLimit,
	};
};

export const MockInnerPage = ({ ALL_QUESTIONS, mockTitle, totalMockTime }) => {
	// Whiteboard start
	let location = useLocation();
	const mockUrl = location.pathname.slice(location.pathname.length - 1);

	const getRandomQuestion = (whereFrom, qIdx) => {
		let questionContent = 'There is no question for such topic!';
		ALL_QUESTIONS?.forEach((qObj) => {
			if (qObj[whereFrom]) {
				questionContent = qObj[whereFrom][qIdx];
			}
		});
		return questionContent;
	};

	// Enrich all questions with title and content
	const QUESTIONS_DATABASE = {
		questions: ALL_QUESTIONS?.map((q, i) => {
			const whereFromKey = Object.keys(q)[0];
			return {
				qTitle: `Question ${i + 1}`,
				content: (qIdx) => getRandomQuestion(whereFromKey, qIdx),
			};
		}),
	};

	const { persistentCurrentQuestion, persistentPrevQuestion, persistentQIndex, persistentWhiteboardLimit } =
		getPersistedData();

	// Defaults
	const defaultCurrentQuestion = {
		qTitle: QUESTIONS_DATABASE.questions[0]?.qTitle,
		content: QUESTIONS_DATABASE.questions[0]?.content(0),
	};
	// States
	const [qIndex, setQIndex] = useState(persistentQIndex ? persistentQIndex : 0);
	const [currentQuestion, setCurrentQuestion] = useState(
		persistentCurrentQuestion ? persistentCurrentQuestion : defaultCurrentQuestion
	);
	const [prevQuestion, setPrevQuestion] = useState(persistentPrevQuestion ? persistentPrevQuestion : null);
	const [nextQuestion, setNextQuestion] = useState(null);

	const [trackDisabled, setTrackDisabled] = useState(true);
	const [showDone, setShowDone] = useState(false);
	const [isPrevBtnClicked, setIsPrevBtnClicked] = useState(false);
	const [resetCode, setResetCode] = useState(false);

	let limit = 0;
	if (mockUrl === '1') limit = 6;
	else if (mockUrl === '2') limit = 14;
	else if (mockUrl === '3') limit = 17;
	else if (mockUrl === '4') limit = 23;
	else if (mockUrl === '5') limit = 10;
	else limit = 6;

	const [whiteboardLimit, setWhiteBoardLimit] = useState(persistentWhiteboardLimit ? persistentWhiteboardLimit : 0);

	const randomIndexGenerator = (idx) => {
		const targetObj = ALL_QUESTIONS[idx];
		const targetKey = Object.keys(targetObj)[0];
		return Math.floor(Math.random() * ALL_QUESTIONS[idx][targetKey].length);
	};

	// Initial render and checks
	useEffect(() => {
		// Only store when prev button not clicked
		if (!isPrevBtnClicked) {
			localStorage.setItem('qIndex', qIndex);
		}
		if (!persistentCurrentQuestion) {
			localStorage.setItem('currentQuestion', JSON.stringify(currentQuestion));
		}
		// Enable prev button on refresh
		if (prevQuestion) {
			setTrackDisabled(false);
		}
	}, [qIndex, persistentCurrentQuestion, currentQuestion, isPrevBtnClicked, prevQuestion]);

	const getLastQuestion = () => {
		let prevWhiteIndex = whiteboardLimit - 1;
		setWhiteBoardLimit(prevWhiteIndex);
		localStorage.setItem('whiteboardLimit', prevWhiteIndex);

		setIsPrevBtnClicked(true);
		setResetCode((prevState) => !prevState);
		// update qIndex in storage
		let prevQIndex = qIndex - 1;
		localStorage.setItem('qIndex', prevQIndex);
		// setQIndex(prevQIndex);

		// Store current question to next in state and storage
		setNextQuestion(currentQuestion);
		localStorage.setItem('nextQuestion', JSON.stringify(currentQuestion));

		// Update current question with prev
		setCurrentQuestion(prevQuestion);
		localStorage.setItem('currentQuestion', JSON.stringify(prevQuestion));

		// Reset prev question
		setPrevQuestion(null);
		localStorage.removeItem('prevQuestion');

		// Disable prev
		setTrackDisabled(true);
	};

	const getNextQuestion = () => {
		let nextWhiteIndex = whiteboardLimit + 1;
		setWhiteBoardLimit(nextWhiteIndex);
		localStorage.setItem('whiteboardLimit', nextWhiteIndex);

		setIsPrevBtnClicked(false);
		setResetCode((prevState) => !prevState);

		if (qIndex === QUESTIONS_DATABASE.questions.length - 1 && !isPrevBtnClicked) {
			setShowDone(true);
			localStorage.clear();
			return;
		}

		// Store prev question
		setPrevQuestion(currentQuestion);
		localStorage.setItem('prevQuestion', JSON.stringify(currentQuestion));

		// If next question exists
		if (nextQuestion) {
			setCurrentQuestion(nextQuestion);
			localStorage.setItem('currentQuestion', JSON.stringify(nextQuestion));

			setNextQuestion(null);
			localStorage.removeItem('nextQuestion');

			// Enable prev
			setTrackDisabled(false);
			return;
		}

		// Important
		const nextIndex = qIndex + 1;

		setQIndex(nextIndex);

		// Random question index
		const newRandom = randomIndexGenerator(nextIndex);

		// Update current question in state and storage
		setCurrentQuestion({
			qTitle: QUESTIONS_DATABASE.questions[nextIndex]?.qTitle,
			content: QUESTIONS_DATABASE.questions[nextIndex].content(newRandom),
		});
		localStorage.setItem(
			'currentQuestion',
			JSON.stringify({
				qTitle: QUESTIONS_DATABASE.questions[nextIndex]?.qTitle,
				content: QUESTIONS_DATABASE.questions[nextIndex].content(newRandom),
			})
		);

		// Enable prev
		setTrackDisabled(false);
	};

	if (showDone) {
		// When time finished, clear localstorage and update ui
		localStorage.clear();
		return <MockDoneBlock />;
	}

	return (
		<div>
			<MockBackButton />
			<div>
				<h1 className={styles.mockHeading}>{mockTitle}</h1>
				<div className={styles.mockContentBody}>
					<section className={styles.mockHeader}>
						<div className={styles.mockInnerHeader}>
							<div>
								<div>Time remaining:</div>
								<div className={styles.iTimer}>
									<MockTimerBanner setShowDone={setShowDone} totalMockTime={totalMockTime} />
								</div>
							</div>
						</div>
					</section>
					<section className={styles.mockQuestion}>
						<h2>{currentQuestion?.qTitle}</h2>
						<ContentGenerator title={'mock'} taskDetails={currentQuestion?.content} />
					</section>
					{whiteboardLimit > limit && (
						<section>
							<CodeEditor initialCode={'// Write your solution below'} resetCode={resetCode} />
						</section>
					)}
					<section className={styles.btns}>
						<button onClick={getLastQuestion} disabled={trackDisabled}>
							PREV
						</button>
						<button onClick={getNextQuestion}>
							{qIndex >= QUESTIONS_DATABASE.questions.length - 1 && !isPrevBtnClicked ? 'DONE' : 'NEXT'}
						</button>
					</section>
				</div>
			</div>
		</div>
	);
};
