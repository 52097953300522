const ex1 = `Write a function named \`hello()\` which outputs \`Hello\` when invoked.
~~~js
hello() -> "Hello" 
~~~`;

const ex2 = `Write a function named as \`helloName()\` which takes a \`String\` name as an argument and outputs \`Hello {name}\` when invoked.
~~~js
helloName("TechGlobal") -> "Hello TechGlobal"
helloName("John") 		-> "Hello John"
~~~`;

const ex3 = `Write a function named \`combineString()\` which takes two \`String\` words as arguments and outputs them \`{word1} {word2}\` when invoked.
~~~js
combineString("TechGlobal", "School") -> "TechGlobal School"
combineString("John", "Doe") 		  -> "John Doe"
~~~`;

const ex4 = `Write a function named \`sum()\` which takes two number arguments and returns the sum of the numbers when invoked.
~~~js
sum(2, 4)  -> 6
sum(-1, 5) -> 4
~~~`;

const ex5 = `Write a function named \`product()\` that takes two number arguments and returns the product of the numbers when invoked.
~~~js
product(2, 4)  -> 8
product(-1, 5) -> -5
~~~`;

const ex6 = `Write a function named \`findBigger()\` which takes two number arguments and returns the bigger number when invoked.
~~~js
findBigger(2, 4) -> 4
findBigger(7, 5) -> 7
~~~`;

const ex7 = `Write a function named \`findSmaller()\` which takes two number arguments and returns the smaller number when invoked.
~~~js
findSmaller(2, 4) -> 2 
findSmaller(5, 3) -> 3 
~~~`;

const ex8 = `Write a function named \`findAbsoluteDifference()\` which takes two number arguments and returns the absolute difference of the numbers when invoked.
~~~js
findAbsoluteDifference(5, 10) -> 5
findAbsoluteDifference(7, 3)  -> 4
~~~`;

const ex9 = `Write a function named \`isOdd()\` which takes a number argument and returns \`true\` if the given number is odd or \`false\` otherwise when invoked.
~~~js
isOdd(2) -> false
isOdd(3) -> true
~~~`;

const ex10 = `Write a function named \`isEven()\` which takes a number argument and returns \`true\` if the given number is even or \`false\` otherwise when invoked.
~~~js
isEven(2) -> true
isEven(3) -> false
~~~`;

const ex11 = `Write a function named \`isPositive()\` which takes a number argument and returns \`true\` if the given number is greater than zero or \`false\` otherwise when invoked.
~~~js
isPositive(2) -> true
isPositive(0) -> false
~~~`;

const ex12 = `Write a function named \`isNegative()\` which takes a number argument and returns \`true\` if the given number is smaller than zero or \`false\` otherwise when invoked.
~~~js
isNegative(2)  -> false
isNegative(-1) -> true
~~~`;

const ex13 = `Write a function named \`isNeutral()\` which takes a number argument and returns \`true\` if the given number is zero or \`false\` otherwise when invoked.
~~~js
isNeutral(0)  -> true
isNeutral(5)  -> false
isNeutral(-2) -> false
~~~`;

const ex14 = `Write a function named \`isMultipleOfSeven()\` which takes a number argument and returns \`true\` if the given number is divisible by 7 or \`false\` otherwise when invoked.
~~~js
isMultipleOfSeven(14) -> true
isMultipleOfSeven(10) -> false
isMultipleOfSeven(0)  -> true
~~~`;

const ex15 = `Write a function named \`isTen()\` which takes a number argument and returns \`true\` if the given number is 10 or \`false\` otherwise when invoked.
~~~js
isTen(10) -> true
isTen(5)  -> false
~~~`;

export const basicFunctionsData = [
	{
		title: 'Greeting',
		content: ex1,
	},
	{
		title: 'Hello Name',
		content: ex2,
	},
	{
		title: 'Combine String',
		content: ex3,
	},
	{
		title: 'Sum',
		content: ex4,
	},
	{
		title: 'Product',
		content: ex5,
	},
	{
		title: 'Find Bigger',
		content: ex6,
	},
	{
		title: 'Find Smaller',
		content: ex7,
	},
	{
		title: 'Find Absolute Difference',
		content: ex8,
	},
	{
		title: 'Is Odd',
		content: ex9,
	},
	{
		title: 'Is Even',
		content: ex10,
	},
	{
		title: 'Is Positive',
		content: ex11,
	},
	{
		title: 'Is Negative',
		content: ex12,
	},
	{
		title: 'Is Neutral',
		content: ex13,
	},
	{
		title: 'Is Multiple of Seven',
		content: ex14,
	},
	{
		title: 'Is Ten',
		content: ex15,
	},
];
