import React, { useState } from 'react';
import Button from '../Partials/Buttons';
import styles from './ForgotPassword.module.css';

const ForgotPassword = ({ className }) => {
	const [email, setEmail] = useState('');
	const [submitted, setSubmitted] = useState(false);
	const [emptyEmail, setEmptyEmail] = useState(false);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		// we are not asked to handle errors in the requirements
		// if needed => add it
		setSubmitted(false);
		setEmptyEmail(false);
		if (email.length === 0) {
			setEmptyEmail(true);
		} else {
			setSubmitted(true);
		}
	};

	return (
		<div className={`${styles.content} ${className}`}>
			<h2 id='sub_heading'>Reset Password</h2>
			<br />
			<form onSubmit={handleFormSubmit}>
				<div>
					<label htmlFor='email'>
						Enter your email address and we'll send you a link to reset your password.{' '}
					</label>
					<br />
					<input
						type='email'
						id='email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className={styles.email}
					/>
				</div>
				<div className={styles.btn_container}>
					<Button className={styles.submit_btn} id='submit' children='SUBMIT' type='submit' />
				</div>
			</form>
			<br />
			{submitted && (
				<p id='confirmation_message' className='has-text-success'>
					A link to reset your password has been sent to your email address.
				</p>
			)}
			{emptyEmail && <p className='has-text-danger'>Email input cannot be left empty!</p>}
		</div>
	);
};

export default ForgotPassword;
