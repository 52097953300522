import { v4 as uuid } from 'uuid';
import styles from './DynamicElements.module.css';

export default function DynamicElements() {
	const uniqueId = uuid();
	return (
		<div>
			<ul>
				<li className={styles.boxWrapper}>
					<p id={`box_1_${uniqueId.slice(0, 6)}`}>Box 1</p>
				</li>
				<li className={`${styles.boxWrapper} pt-5`}>
					<p id={`box_2_${uniqueId.slice(30)}`}>Box 2</p>
				</li>
			</ul>
		</div>
	);
}
