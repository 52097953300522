import Button from '../Partials/Buttons';
import React, { useEffect, useRef, useState } from 'react';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DynamicTables.module.css';
const fixedProducts = [
	{
		quantity: 1,
		productName: 'iPhone',
		price: 1000,
	},
	{
		quantity: 3,
		productName: 'Airpods',
		price: 100,
	},
	{
		quantity: 2,
		productName: 'iPad',
		price: 500,
	},
];

export default function DynamicTables() {
	const ref = useRef(null);

	// TODO: this can be improved
	const [productList, setProductList] = useState(fixedProducts);
	const [isFormActive, setIsFormActive] = useState(false);
	const [quantity, setQuantity] = useState('');
	const [productName, setProductName] = useState('');
	const [price, setPrice] = useState('');
	let [totalAmount, setTotalAmount] = useState(1600);

	useEffect(() => {
		const useOutsideClick = (e) => {
			if (isFormActive && e.target.tagName !== 'BUTTON' && !ref.current.contains(e.target)) {
				resetFormAndCloseModal();
			}
		};
		document.addEventListener('click', useOutsideClick);

		let tAmount = productList.reduce((acc, product) => {
			return acc + product.quantity * product.price;
		}, 0);
		setTotalAmount(tAmount);

		// cleanup
		return () => {
			document.removeEventListener('click', useOutsideClick);
		};
	}, [productList, isFormActive]);

	function handleFormSubmit(event) {
		event.preventDefault();
		if (quantity !== undefined && productName !== undefined && price !== undefined) {
			const productNameList = productList.map((product) => product.productName);
			if (productNameList.includes(productName)) {
				alert(`You already have ${productName} in your list!`);
			} else {
				setProductList([
					...productList,
					{ quantity: Number.parseInt(quantity), productName, price: Number.parseInt(price) },
				]);
				resetFormAndCloseModal();
			}
		}
	}

	function resetFormAndCloseModal() {
		// TODO: this can be improved
		setQuantity('');
		setProductName('');
		setPrice('');
		setIsFormActive(false);
	}

	function TableRows() {
		return productList.map((product) => {
			return (
				<tr key={product.productName}>
					<td>{product.quantity}</td>
					<td>{product.productName}</td>
					<td>{product.price.toLocaleString('en-US')}</td>
					<td>{(product.quantity * product.price).toLocaleString('en-US')}</td>
				</tr>
			);
		});
	}

	return (
		<div className='table-container'>
			<table id='product_table' className='table is-bordered is-striped is-hoverable is-fullwidth'>
				<thead style={{ backgroundColor: 'var(--mainBlue' }}>
					<tr className={styles.tableHeaders}>
						<th className='header has-text-white'>Quantity</th>
						<th className='header has-text-white'>Product</th>
						<th className='header has-text-white'>Price $</th>
						<th className='header has-text-white'>Total $</th>
					</tr>
				</thead>
				<tbody>
					<TableRows />
				</tbody>
			</table>
			{isFormActive && (
				<div className='modal is-active'>
					<div className='modal-background'></div>
					<div ref={ref} className={`modal-card ${styles.modal}`}>
						<header className='modal-card-head'>
							<h2 id='modal_title' className='modal-card-title'>
								Add New Product
							</h2>
							<button className='delete' aria-label='close' onClick={resetFormAndCloseModal}></button>
						</header>
						<section className='modal-card-body'>
							<form id='name_form' onSubmit={handleFormSubmit}>
								<div className='field'>
									<label htmlFor='product_quantity'>Please select the quantity</label>
									<div className='control'>
										<input
											id='quantity'
											className='input'
											type='number'
											onChange={(e) => setQuantity(e.target.value)}
											value={quantity}
											min='1'
											required
										/>
									</div>
								</div>
								<div className='field'>
									<label htmlFor='product_name'>Please enter the name of the product</label>
									<div className='control'>
										<input
											id='product'
											className='input'
											placeholder='Ex: Mouse'
											type='text'
											onChange={(e) => setProductName(e.target.value)}
											value={productName}
											required
										/>
									</div>
								</div>
								<div className='field'>
									<label htmlFor='product_price'>Please enter the price of the product</label>
									<div className='control control has-icons-left'>
										<input
											id='price'
											className='input'
											type='number'
											onChange={(e) => setPrice(e.target.value)}
											value={price}
											min='0.01'
											step='0.01'
											required
										/>
										{price === '' && (
											<span className='icon is-small is-left'>
												<FontAwesomeIcon icon={faDollar} />
											</span>
										)}
									</div>
								</div>
								<div className='field is-grouped is-pulled-right'>
									<div className='control'>
										<Button className={styles.button} id='submit' children='SUBMIT' />
									</div>
								</div>
							</form>
						</section>
					</div>
				</div>
			)}
			<Button
				className={styles.button}
				onClick={() => setIsFormActive(true)}
				id='add_product_btn'
				children='ADD PRODUCT'
			/>
			<p id='total_amount' className='has-text-weight-bold is-size-5 pt-6'>
				Total = ${totalAmount.toLocaleString('en-US')}
			</p>
		</div>
	);
}
