const ex1 = `Write your code that asks the user their age.  
If the age is more than or equal to 16, print \`You are allowed to drive!\`  
Otherwise, print \`Sorry! You cannot drive!\`
~~~
Program: How old are you?
User: 45
Program: You are allowed to drive!

Program: How old are you?
User: 15
Program: Sorry! You cannot drive!
~~~`;

const ex2 = `Write your code that asks the user a number.  
If the number is divisible by 2, print \`Your number is even!\`  
Otherwise, print \`Your number is odd!\`
~~~
Program: What is your number?
User: 5
Program: Your number is odd!

Program: What is your number?
User: 100
Program: Your number is even!
~~~`;

const ex3 = `Write your code that asks the user a number.  
If the number is greater than zero, print \`Your number is positive!\`  
If the number is less than zero, print \`Your number is negative!\`  
Otherwise, print \`Your number is zero!\`
~~~
Program: What is your number?
User: 5
Program: Your number is positive!

Program: What is your number?
User: 0
Program: Your number is zero!

Program: What is your number?
User: -2
Program: Your number is negative!
~~~`;

const ex4 = `Write your code that requests two numbers from the user.  
Find the greater and print \`The greater number is {GREATER_NUMBER}.\`  
~~~
Program: Enter the first number.
User: 5
Program: Enter the second number.
User: 23
Program: The greater number is 23.

Program: Enter the first number.
User: 37
Program:  Enter the second number.
User: 3
Program: The greater number is 37.

Program: Enter the first number.
User: 10
Program:  Enter the second number.
User: 10
Program: The greater number is 10.
~~~`;

const ex5 = `Write your code that requests two numbers from the user.  
Find the smaller and print \`The smaller number is {SMALLER_NUMBER}.\`  
~~~
Program: Enter the first number.
User: 2
Program:  Enter the second number.
User: 7
Program: The smaller number is 2.

Program: Enter the first number.
User: 21
Program:  Enter the second number.
User: 9
Program: The smaller number is 9.

Program: Enter the first number.
User: 7
Program:  Enter the second number.
User: 7
Program: The smaller number is 7.
~~~`;

const ex6 = `Write your code that asks the user two numbers.  
If the numbers are equal, print \`Your numbers are equal!\`  
Otherwise, print \`Your numbers are different!\`
~~~
Program: Enter the first number.
User: 4
Program:  Enter the second number.
User: 4
Program: Your numbers are equal!

Program: Enter the first number.
User: 2
Program:  Enter the second number.
User: 3
Program: Your numbers are different!
~~~`;

const ex7 = `Write your code that asks the user about the weather, and assume 70 F or above is considered as good weather.  
If the weather is 70 F or above, print \`The weather is good today!\`  
Otherwise, print \`The weather is not good today! \`
~~~
Program: How is the weather today?
User: 65
Program: The weather is not good today!

Program: How is the weather today?
User: 70
Program: The weather is good today!

Program: How is the weather today?
User: 80
Program: The weather is good today!
~~~`;

const ex8 = `Write your code that asks the user a number.  
If the number is divisible by 3, print \`Fizz\`  
If the number is divisible by 5, print \`Buzz\`  
If the number is divisible by both 3 and 5, print \`FizzBuzz\`  
Otherwise, print the number itself.
~~~
Program: Enter a number.
User: 5
Program: Buzz

Program:  Enter a number.
User: 3
Program: Fizz

Program: Enter a number.
User: 45
Program: FizzBuzz

Program:  Enter a number.
User: 7
Program: 7
~~~`;

const ex9 = `Write your code that asks the user a number.  
If the number is divisible by 2, print \`Foo\`  
If the number is divisible by 3, print \`Bar\`  
If the number is divisible by both 2 and 3, print \`FooBar\`  
Otherwise, print the number itself.
~~~
Program:  Enter a number.
User: 3
Program: Bar

Program:  Enter a number.
User: 2
Program: Foo

Program:  Enter a number.
User: 12
Program: FooBar

Program:  Enter a number.
User: 5
Program: 5
~~~`;

export const conditionalStatementsExercisesData = [
	{
		title: 'Driver Permit',
		content: ex1,
	},
	{
		title: 'Even-Odd',
		content: ex2,
	},
	{
		title: 'Positive-Negative-Neutral',
		content: ex3,
	},
	{
		title: 'Find Greater Number',
		content: ex4,
	},
	{
		title: 'Find Smaller Number',
		content: ex5,
	},
	{
		title: 'Check If Equal',
		content: ex6,
	},
	{
		title: 'Check Weather',
		content: ex7,
	},
	{
		title: 'FizzBuzz',
		content: ex8,
	},
	{
		title: 'FooBar',
		content: ex9,
	},
];
