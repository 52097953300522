const ex1 = `Write a function named \`repeatingX()\` which takes a \`string\` argument and returns \`true\` if the letter x is followed by another x. Otherwise, return \`false\`.

**NOTE:** This method should be case-insensitive.

~~~js
repeatingX("xTechxGlobalx")	    -> false
repeatingX("Hello Xx World")	-> true
repeatingX("x x")			    -> false
~~~`;

const ex2 = `Write a function named \`isPerfectSquare()\` which takes a \`number\` as an argument and checks if it is a perfect square. It returns \`true\` if the number is a perfect square and \`false\` otherwise.

**NOTE:** A perfect square is a number that can be expressed as the product of an integer by itself or as the second exponent of an integer. For example, 25 is a perfect square because it is the product of integer 5 by itself, 5 × 5 = 25. However, 21 is not a perfect square number because it cannot be expressed as the product of two same integers.

~~~js
isPerfectSquare(25) 	-> true
isPerfectSquare(24) 	-> false
isPerfectSquare(0) 	    -> true
isPerfectSquare(1) 	    -> true
~~~`;

const ex3 = `Write a function named \`convertTemperature()\` which takes a \`number\` and a \`string\` arguments to be considered as a temperature value and a unit (either Celsius or Fahrenheit) as arguments and converts the temperature to the other unit.

**NOTE:** Use below formulas to convert temperature:
- Celsius to Fahrenheit: temp * 9/5) + 32
- Fahrenheit to Celsius: temp - 32) * 5/9

~~~js
convertTemperature(100, 'Celsius') 		-> 212
convertTemperature(32, 'Fahrenheit') 	-> 0
convertTemperature(0, 'Celsius') 		-> 32
convertTemperature(212, 'Fahrenheit') 	-> 100
~~~`;

const ex4 = `Write a function named \`sumOfEvenNumbers()\` which takes an \`array\` as an argument and returns the sum of all the even numbers in an array.
~~~js
sumOfEvenNumbers( [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ] ) 	    -> 30
sumOfEvenNumbers( [ 2, 4, 6, 8, 10, 12, 14, 16, 18, 20 ] ) 	-> 110
sumOfEvenNumbers( [ 1, 3, 5, 7, 9, 11, 13, 15, 17, 19 ] ) 	-> 0
sumOfEvenNumbers( [ ] ) 					                -> 0
~~~`;

const ex5 = `Write a function named \`capsOdds()\` which takes an \`array\` argument and returns the array with all the odd index elements capitalized (converted to uppercase).
~~~js
capsOdds(["Hello", "World"])			                     -> ["Hello", "WORLD"]
capsOdds(["Jan", "Feb", "Mar", "Apr"])		                 -> ["Jan", "FEB", "Mar", "APR"]
capsOdds(["Apple", "Banana", "123", "456", "Peach", "Kiwi"]) -> ["Apple", "BANANA", "123", "456", "Peach", "KIWI"]
~~~`;

const ex6 = `Write a function named \`toCamelCase()\` which takes a \`string\` as its argument and returns a new string in camelCase. Assume the string only contains letters and spaces
~~~js
toCamelCase("first name")       -> "firstName"
toCamelCase("last     name")    -> "lastName"
toCamelCase("   ZIP CODE")      -> "zipCode"
~~~`;

const ex7 = `Write a function named \`toSnakeCase()\` which takes a \`string\` as its argument and returns a new string in snake_case. Assume the string only contains letters and spaces

**NOTE:** In snake case words are separated by underscores (_) and are all lowercase.

~~~js
toSnakeCase("first name") 		        -> "first_name"
toSnakeCase("last    name") 		    -> "last_name"
toSnakeCase("    I love Java Script") 	-> "i_love_java_script"
~~~`;

const ex8 = `Write a function named \`alternatingCases()\` which takes a \`string\` argument and returns the string with alternating capitalization.

**NOTE:** The first letter should always be uppercase and non-letter characters are ignored.

~~~js
alternatingCases("Hello")			-> "HeLlO"
alternatingCases("basketball")		-> "BaSkEtBaLl"
alternatingCases("Tech Global")		-> "TeCh GlObAl"
~~~`;

const ex9 = `Write a function named \`isNeutral()\` that takes \`two strings\` comprised of + and -, and return a new string which shows how the two strings interact in the following way:

    - When positives and positives interact, they remain positive.
    - When negatives and negatives interact, they remain negative.
    - But when negatives and positives interact, they become neutral, and are shown as the number 0.

**Note:** The two strings will be the same length.

~~~js
isNeutral("-", "+")     		->  "0"
isNeutral("-+", "-+") 	 	    ->  "-+"
isNeutral("-++-", "-+-+")       ->  "-+00"
~~~`;

const ex10 = `Write a function named \`isTrueOrFalse()\` which takes a \`string\` with sets of character/words separated by space. Looking at the first letter of each word (case insensitive-"A" and "a" should be treated the same), you need to determine whether it falls into the positive/first half of the alphabet ("a"-"m") or the negative/second half ("n"-"z"). Return \`true\` if there are more (or equal) positive words than negative words, \`false\` otherwise.

**NOTE:** alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

**NOTE:** Ignore all the digits, spaces and special characters.

~~~js
isTrueOrFalse("A big brown fox caught a bad rabbit") 	-> true
isTrueOrFalse("Xylophones can obtain Xenon.")   		-> false
isTrueOrFalse("CHOCOLATE MAKES A GREAT SNACK")          -> true
~~~`;

export const exercise06ExercisesData = [
	{
		title: 'Repeating X',
		content: ex1,
	},
	{
		title: 'Is Perfect Square',
		content: ex2,
	},
	{
		title: 'Convert Temperature',
		content: ex3,
	},
	{
		title: 'Sum of Even Numbers',
		content: ex4,
	},
	{
		title: 'Capitalize Odd Index',
		content: ex5,
	},
	{
		title: 'Camel Case Converter',
		content: ex6,
	},
	{
		title: 'Snake Case Converter',
		content: ex7,
	},
	{
		title: 'Alternating Cases',
		content: ex8,
	},
	{
		title: 'Neutral Strings',
		content: ex9,
	},
	{
		title: 'Negative or Positive',
		content: ex10,
	},
];
