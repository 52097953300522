export const programs = [
	{
		id: 1,
		program: 'sdet_cy_6',
		title: 'Software Engineer in Test / QA',
		description:
			'Become a competitive Software Engineer in Test in the professional IT field. With the 7 months of the course, learn all the fundamental technologies needed to become a professional and how important testing is in the Software Development Life Cycle (SDLC)… Learn more about the technologies that TechGlobal provides...',
		url: 'https://www.techglobalschool.com/courses/software-engineer-in-test-(sdet)',
		img: {
			src: './images/program-1.png',
			alt: 'sdet cypress 6 month program',
		},
		reverse: false,
	},
	{
		id: 2,
		program: 'sdet_cy_1',
		title: 'Cypress Automation with JavaScript',
		description:
			"This course is intended to accelerate your expertise as an experienced working professional, opening doors to modern web testing and automation. If you're accustomed to compiled languages like Java, this course is your gateway to mastering the dynamic world of JavaScript and utilizing the power of Cypress to revolutionize your testing workflow.",
		url: 'https://www.techglobalschool.com/courses/cypress-automation-with-javascript',
		img: {
			src: './images/program-2.png',
			alt: 'sdet cypress 1 month program',
		},
		reverse: true,
	},
	{
		id: 3,
		program: 'sdet_py_1',
		title: 'Playwright Automation with TypeScript',
		description: `Gain a foundation in skills and knowledge needed to effectively use Playwright and TypeScript for test automation, improve testing efficiency and quality, and contribute to the success of software development projects by being valuable asset in the new world of software testing.`,
		url: 'https://www.techglobalschool.com/courses/playwright-automation-with-typescript',
		img: {
			src: './images/program-3.png',
			alt: 'playwright 1 month program',
		},
		reverse: false,
	},
];
