const ex1 = `Write a method named as \`has4()\` which takes an \`ArrayList\` of \`String\` as an argument and returns the count of elements that has 4 or more characters when invoked.
~~~java
has4(["Tech", "Global", "School"])		-> 3
has4(["abc", "123"])				    -> 0
has4(["Hello", "Hi"])				    -> 1
~~~`;

const ex2 = `Write a method named as \`divisible5()\` which takes an \`ArrayList\` of \`Integer\` as an argument and returns the count of elements that are divisible by 5 when invoked.
~~~java
divisible5([3, 4, 5, 6, 7])		-> 1
divisible5([5, 0, 10])			-> 3
divisible5([12, 34, 2])		    -> 0
~~~`;

const ex3 = `Write a method named as \`hasA()\` which takes an \`ArrayList\` of \`String\` as an argument and returns the count of elements that has the letter a or A when invoked. 
~~~java
hasA(["Tech", "Global", "School"])		-> 1
hasA(["Apple", "123"])			        -> 1
hasA(["Hello", "Hi"])				    -> 0
~~~`;

const ex4 = `Write a method named as \`positives()\` which takes an \`ArrayList\` of \`Integer\` as an argument and returns the count of elements that are more than zero when invoked.
~~~java
positives([3, 4, 5, 6, 7])		-> 5
positives([5, 0, 10])			-> 2
positives([-2, -10, 0])	        -> 0
~~~`;

const ex5 = `Write a method named as \`negatives()\` which takes an \`ArrayList\` of \`Integer\` as an argument and returns the count of elements that are less than zero when invoked.
~~~java
negatives([-1, -5, -6, -10])        -> 4
negatives([0, 5])			        -> 0
negatives([5, 10, 20])		        -> 0
~~~`;

const ex6 = `Write a method named as \`noE()\` which takes an \`ArrayList\` of \`String\` as an argument and returns the list back with all the elements that have the letter e or E removed when invoked.
~~~java
noE(["Tech", "Global", "School"])		-> ["Global", "School"]
noE(["Apple", "123", "Egg"])	    	-> ["123"]
noE(["Hi"])					            -> ["Hi"]
~~~`;

const ex7 = `Write a method named as \`noBStart()\` which takes an \`ArrayList\` of \`String\` as an argument and returns the list back with all the elements that have the letter e or E removed when invoked.  
~~~java
noBStart(["Bird", "boy", "bad", "B", "b"])		-> []
noBStart(["Apple", "ABC", "strawberry"])		-> ["Apple", "ABC", "strawberry"]
noBStart(["Hi", "123"])			            	-> ["Hi", "123"]
~~~`;

const ex8 = `Write a method named as \`noLongWords()\` which takes an \`ArrayList\` of \`String\` as an argument and returns the list back with all the elements that have more than 5 characters removed when invoked.
~~~java
noLongWords(["Tech", "Global", "School"])		-> ["Tech"]
noLongWords(["Hello", "Hi"])			    	-> ["Hello", "Hi"]
noLongWords(["Good", "morning"])		    	-> ["Good"]
~~~`;

const ex9 = `Write a method named as \`noEvenNumbers()\` which takes an \`ArrayList\` of \`Integer\` as an argument and the list back with all the elements that are even removed when invoked.
~~~java
noEvenNumbers([3, 4, 5, 6, 7])		-> [3, 5, 7]
noEvenNumbers([5, 0, 10, -3])		-> [5, -3]
noEvenNumbers([-2, -10, 0, 2])		-> []
~~~`;

const ex10 = `Write a method named as \`triple()\` which takes an \`ArrayList\` of \`Integer\` as an argument and the list back with all the elements tripled when invoked.
~~~java
triple([0, 1, 2, 3])		-> [0, 3, 6, 9]
triple([-2, 2, 5])	    	-> [-6, 6, 15]
triple([3, 3])			    -> [9, 9]
~~~`;

export const arrayListExercisesData = [
	{
		title: 'Count Words Has Length of Four',
		content: ex1,
	},
	{
		title: 'Count Numbers Divisible by 5',
		content: ex2,
	},
	{
		title: 'Count Words Has Letter A',
		content: ex3,
	},
	{
		title: 'Count Positive Numbers',
		content: ex4,
	},
	{
		title: 'Count Negative Numbers',
		content: ex5,
	},
	{
		title: 'Remove Words Has the Letter E',
		content: ex6,
	},
	{
		title: 'Remove Words Starts With the Letter B',
		content: ex7,
	},
	{
		title: 'Remove Long Words',
		content: ex8,
	},
	{
		title: 'Remove Even Numbers',
		content: ex9,
	},
	{
		title: 'Triple the Numbers',
		content: ex10,
	},
];
