import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import styles from './Partial.module.css';

const ExercisesLayout = ({ children }) => {
	return (
		<div className='exercises_layout'>
			<h2 id='sub_heading' className='pt-4'>
				Exercises
			</h2>
			<p style={{ fontSize: '11px', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
				Have issues using Repl?
				<a
					href='https://github.com/urakymzhan/tg-collection/blob/main/java/README.md'
					target='_blank'
					rel='noreferrer'
				>
					<FontAwesomeIcon
						icon={faCircleQuestion}
						color='green'
						cursor='pointer'
						className={styles.icon}
						style={{ fontSize: '14px' }}
					/>
				</a>
			</p>
			{children}
		</div>
	);
};

export default ExercisesLayout;
