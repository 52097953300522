import styles from './HomePage.module.css';
import Mission from './blocks/Mission';
import HeroBanner from './blocks/HeroBanner';
import Programs from './blocks/Programs';

const HomePage = () => {
	return (
		<div className={styles.homepage}>
			<HeroBanner />
			<Mission />
			<Programs />
		</div>
	);
};

export default HomePage;
