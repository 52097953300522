const ex1 = `Write your code that asks the user their name and prints the given name.
~~~
Program: What is your name?
User: John
Program: Your name is John
~~~`;

const ex2 = `Write your code that asks the user their name and prints the given name with the greeting as below.
~~~
Program: What is your name?
User: John
Program: Hi John
~~~`;

const ex3 = `Write your code that asks the user their first name and last name. Then, print the given full name.
~~~
Program: What is your first name?
User: John
Program: What is your last name?
User: Doe
Program: Your full name is John Doe.
~~~`;

const ex4 = `Write your code that asks the user their age and prints the given age.
~~~
Program: How old are you?
User: 45
Program: Your age is 45.
~~~`;

const ex5 = `Write your code that asks the user their favorite number and prints the given number.
~~~
Program: What is your favorite number?
User: 7
Program: Your favorite number is 7.
~~~`;

const ex6 = `Write your code that asks the user to enter 2 numbers. Then, find their sum and product.
~~~
Program: Welcome to the calculator app!
Program: Please enter the first number.
User: 10
Program: Please enter the second number.
User: 5
Program: The sum = 15
Program: The product = 50
~~~`;

const ex7 = `Write your code that asks the user to enter a mile unit and convert it to kilometers. Please assume that **1 mile equals 1.6 kilometers**.
~~~
Program: Please enter a mile unit.
User: 5
Program: 5 miles is around 8 kilometers.
~~~`;

const ex8 = `Write your code that asks the user to enter a kilogram unit and convert it to pounds. Please assume that **1 kilogram equals 2.2 pounds**.
~~~ 
Program: Please enter a kg unit.
User: 80
Program: 80 kilograms is around 176 pounds.
~~~`;

const ex9 = `Write your code that asks the user their full name, age, address, and favorite programming language. Then, print the given information as stated below.
~~~
Program: What is your full name?
User: John Doe
Program: How old are you?
User: 45
Program: Where do you live?
User: 123 Street 
Program: What is your favorite programming language?
User: Java 
Program: John Doe is 45 and lives on 123 Street. The favorite programming language is Java.
~~~`;

export const scannerExercisesData = [
	{
		title: 'Read Name',
		content: ex1,
	},
	{
		title: 'Hi Name',
		content: ex2,
	},
	{
		title: 'Read Fullname',
		content: ex3,
	},
	{
		title: 'Read Age',
		content: ex4,
	},
	{
		title: 'Read Favorite Number',
		content: ex5,
	},
	{
		title: 'Read Two Numbers and Find Sum and Product',
		content: ex6,
	},
	{
		title: 'Miles to Kilometers',
		content: ex7,
	},
	{
		title: 'Kilograms to Pounds',
		content: ex8,
	},
	{
		title: 'Survey',
		content: ex9,
	},
];
