import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useLocation } from 'react-router-dom';

import React from 'react';

const ContentGenerator = ({ title, taskDetails }) => {
	const location = useLocation();
	const isJavaScriptExercises = location.pathname.includes('js-exercises');
	const modifiedTitle = title.replace(/\s+/g, '-').toLowerCase();

	return (
		<div className='block'>
			<ReactMarkdown
				className={`pt-4 pb-4 ${title.includes('Test Case') ? 'pl-6 pr-5' : 'pl-4'}`}
				children={taskDetails}
				remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
				rehypePlugins={[rehypeRaw]}
				components={{
					code({ node, inline, className, children, ...props }) {
						const match = /language-(\w+)/.exec(className || '');
						return !inline && match ? (
							<SyntaxHighlighter
								children={String(children).replace(/\n$/, '')}
								style={coy}
								language={match[1]}
								PreTag='div'
								{...props}
							/>
						) : (
							<code className={className} {...props}>
								{children}
							</code>
						);
					},
				}}
			/>
			{!title.includes('Test Case') && !title.includes('mock') && (
				<iframe
					title={title}
					width='100%'
					height='600px'
					src={`https://replit.com/team/codingbootcamp01/${
						isJavaScriptExercises ? `js-${modifiedTitle}` : modifiedTitle
					}`}
				></iframe>
			)}
		</div>
	);
};

export default ContentGenerator;
