import { DummyPage } from '../app/frontend-testing/pages';
import JavaScriptInnerPage from '../app/javascript-exercises/pages/JavaScriptInnerPage';
import {
	jsOutputData,
	basicFunctionsData,
	conditionalStatementsData,
	jsLoopsData,
	jsArrays,
	jsObjects,
	exercise01ExercisesData,
	exercise02ExercisesData,
	exercise03ExercisesData,
	exercise04ExercisesData,
	exercise05ExercisesData,
	exercise06ExercisesData,
	exercise07ExercisesData,
} from '../app/javascript-exercises/pages/accordionData';

const generateComponent = (data, isNeeded) => {
	// In progress pages
	if (data === null && isNeeded === null) return <DummyPage />;

	let props = { needExplanation: isNeeded };
	if (data) {
		props.data = data;
	}
	return <JavaScriptInnerPage {...props} />;
};

const data = [
	{
		component: generateComponent(jsOutputData, true),
		url: 'js-output',
	},
	{
		component: generateComponent(null, true),
		url: 'js-essentials',
	},
	{
		component: generateComponent(null, true),
		url: 'js-numbers-math',
	},
	{
		component: generateComponent(null, true),
		url: 'js-strings',
	},
	{
		component: generateComponent(basicFunctionsData, true),
		url: 'js-basic-functions',
	},
	{
		component: generateComponent(conditionalStatementsData, true),
		url: 'js-conditional-statements',
	},
	{
		component: generateComponent(jsArrays, true),
		url: 'js-arrays',
	},
	{
		component: generateComponent(jsLoopsData, true),
		url: 'js-loops',
	},
	{
		component: generateComponent(jsObjects, true),
		url: 'js-objects',
	},
	{
		component: generateComponent(null, null),
		url: 'js-regex',
	},
	{
		component: generateComponent(exercise01ExercisesData, false),
		url: 'exercise-01',
	},
	{
		component: generateComponent(exercise02ExercisesData, false),
		url: 'exercise-02',
	},
	{
		component: generateComponent(exercise03ExercisesData, false),
		url: 'exercise-03',
	},
	{
		component: generateComponent(exercise04ExercisesData, false),
		url: 'exercise-04',
	},
	{
		component: generateComponent(exercise05ExercisesData, false),
		url: 'exercise-05',
	},
	{
		component: generateComponent(exercise06ExercisesData, false),
		url: 'exercise-06',
	},
	{
		component: generateComponent(exercise07ExercisesData, false),
		url: 'exercise-07',
	},
	{
		component: generateComponent(null, null),
		url: 'exercise-08',
	},
	{
		component: generateComponent(null, null),
		url: 'exercise-09',
	},
	{
		component: generateComponent(null, null),
		url: 'exercise-10',
	},
];

// Add id
for (let i = 0; i < data.length; i++) {
	data[i]['id'] = `card-${i + 1}`;
}

export { data as javaScriptRoute_data };
