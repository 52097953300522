import React, { useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import axios from 'axios';
import styles from './CodeEditor.module.css';
import { customTheme } from '../app/mock-interviews/innerPages/theme';
import { BASE_URL } from '../app/backend-testing/utils/env';

const LANGUAGES = ['nodejs', 'java', 'python3'];
const extensions = [javascript({ jsx: true })];

export const CodeEditor = ({ initialCode, resetCode }) => {
	const [userCode, setUserCode] = React.useState(initialCode);
	const [result, setResult] = React.useState('');
	const [error, setError] = React.useState('');
	const [lang, setLang] = React.useState(LANGUAGES[0]);

	const onChange = React.useCallback((code, viewUpdate) => {
		setUserCode(code);
	}, []);

	useEffect(() => {
		setUserCode(initialCode);
	}, [resetCode]);

	// Note: This is rate limited
	// const PROXY_CORS = 'https://cors-anywhere.herokuapp.com';

	// const JDA = process.env.REACT_APP_JDOODLE_BASE_API || 'https://api.jdoodle.com/v1';

	const submit = async (userCode, lanugage) => {
		try {
			const execution_data = {
				script: userCode,
				language: lanugage,
				// versionIndex: '0',
				// // token: token,
				// clientId: process.env.REACT_APP_JDOODLE_CLIENT_ID || '6cf1cc311c4a4296817ed28fc580bd',
				// clientSecret:
				// 	process.env.REACT_APP_JDOODLE_CLIENT_SECRET ||
				// 	'd31f6e797483d0ef758436f94e06d2c81bffde29aeff90edeb34bc78b694894d',
			};

			const options = {
				method: 'POST',
				url: `${BASE_URL}/editor`,
				data: execution_data,
			};

			const response = await axios.request(options);
			console.log('response', response);
			const data = response?.data?.data;
			return data;
		} catch (error) {
			console.error('error', error);
			return error;
		}
	};

	const submitCode = async () => {
		try {
			if (userCode === '' || userCode === '// Write your solution below') {
				setResult('No solution found. Write your code');
				return;
			}
			const data = await submit(userCode, LANGUAGES[0]);
			let { output } = data || '';

			// remove jdoodle text
			if (output?.startsWith('/home') || output?.includes('jdoodle')) {
				const index = output.indexOf('js:2');
				setResult(output.slice(index + 5));
				return;
			}
			if (output === '') {
				setResult('Nothing returned. Maybe you forgot to console log something?');
				return;
			}
			setResult(output);
			setError('');
		} catch (error) {
			console.error('error', error);

			setError(error.message);
			setResult('');
		}
	};

	return (
		<div className={styles.editorContainer}>
			<div className={styles.controls}>
				<button onClick={submitCode}>Run</button>
			</div>
			<div className={styles.editorBox}>
				<div className={styles.logoBox}>
					<div className={lang === 'nodejs' ? styles.active : null} onClick={() => setLang('nodejs')}>
						<img src='/images/javascript.png' alt='javascript' />
					</div>
					{/* Disabling other languages */}
					<div className={lang === 'java' ? styles.active : null} onClick={() => setLang('nodejs')}>
						<img src='/images/java.png' alt='java' />
					</div>
					<div className={lang === 'python3' ? styles.active : null} onClick={() => setLang('nodejs')}>
						<img src='/images/python.png' alt='python' />
					</div>
				</div>
				<CodeMirror
					theme={customTheme}
					value={userCode}
					minHeight='200px'
					extensions={extensions}
					onChange={onChange}
					className={styles.codeMirror}
				/>
			</div>
			<div className={styles.output}>
				<h4>Output</h4>
			</div>
			<div className={styles.output}>
				<div>
					{error && <p>{error}</p>}
					<pre>{result}</pre>
				</div>
			</div>
		</div>
	);
};
