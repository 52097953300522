import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FallbackSpinner = () => {
	return (
		<h1 style={{ textAlign: 'center', marginTop: '50px' }}>
			<FontAwesomeIcon icon={faSpinner} />
		</h1>
	);
};

export default FallbackSpinner;
