import styles from './Xpath.module.css';

export default function XPathCSS() {
	return (
		<div>
			<ul>
				<li>
					<h3 className={styles.subheader}>Programming Languages</h3>
					<ul>
						<li>Java</li>
						<li>JavaScript</li>
						<li>C#</li>
					</ul>
				</li>
				<br />
				<li>
					<h3 className={styles.subheader}>Automation Tools</h3>
					<ul>
						<li>Selenium WebDriver</li>
						<li>WebDriverIO</li>
						<li>Cypress</li>
						<li>Playwright</li>
					</ul>
				</li>
			</ul>
		</div>
	);
}
