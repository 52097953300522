import React, { useState } from 'react';
import styles from './Alerts.module.css';
import Button from '../Partials/Buttons';

const Alerts = () => {
	const [result, setResult] = useState('');

	const handleWarning = () => {
		if (typeof window !== 'undefined') {
			alert('You are on TechGlobal Training application.');
			setResult('You accepted warning by clicking OK.');
		} else {
			setResult('Some error happened. Refresh the page and try 1 more time!');
		}
	};

	const handleConfirmation = () => {
		if (typeof window !== 'undefined') {
			if (window.confirm('Would you like to stay on TechGlobal Training application?')) {
				setResult('You confirmed the alert by clicking OK.');
			} else {
				setResult('You rejected the alert by clicking Cancel.');
			}
		} else {
			setResult('Some error happened. Refresh the page and try 1 more time!');
		}
	};

	const handlePrompt = () => {
		if (typeof window !== 'undefined') {
			let message = prompt('What would you like to say to TechGlobal?');
			if (message != null) {
				setResult(`You entered  "${message.toString()}" in the alert and clicked OK.`);
			} else {
				setResult('You rejected the alert by clicking Cancel.');
			}
		} else {
			setResult('Some error happened. Refresh the page and try 1 more time!');
		}
	};
	return (
		<div className={styles.wrapper}>
			<div className={styles.buttons}>
				<ul>
					<li>
						<Button
							className={styles.button}
							id='warning_alert'
							onClick={handleWarning}
							children='Warning alert'
						/>
					</li>
					<li>
						<Button
							className={styles.button}
							id='confirmation_alert'
							onClick={handleConfirmation}
							children='Confirmation alert'
						/>
					</li>
					<li>
						<Button
							className={styles.button}
							id='prompt_alert'
							onClick={handlePrompt}
							children='Prompt alert'
						/>
					</li>
				</ul>
			</div>
			<div className={styles.results}>
				<h2 style={{ marginTop: 0 }} id='result_id'>
					Result:{' '}
				</h2>
				<p id='action'>{result}</p>
			</div>
		</div>
	);
};

export default Alerts;
