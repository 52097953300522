export default function RadioButtons() {
	return (
		<div>
			<div id='radio-button-group_1'>
				<div>
					<label className='radio'>
						<input className='mr-1' type='radio' name='language' id='radio_1_option_1'></input>Java
					</label>
				</div>
				<div>
					<label className='radio'>
						<input className='mr-1' type='radio' name='language' id='radio_1_option_2'></input>JavaScript
					</label>
				</div>
				<div>
					<label className='radio'>
						<input className='mr-1' type='radio' name='language' id='radio_1_option_3'></input>C#
					</label>
				</div>
			</div>
			<h2 id='secondary_heading' className='title is-4 pt-6 mb-2'>
				Practice more
			</h2>
			<div id='radio-button-group_2'>
				<div>
					<label className='radio'>
						<input className='mr-1' type='radio' name='automation-tool' id='radio_2_option_1'></input>
						Selenium
					</label>
				</div>
				<div>
					<label className='radio'>
						<input className='mr-1' type='radio' name='automation-tool' id='radio_2_option_2'></input>
						Cypress
					</label>
				</div>
				<div>
					<label className='radio'>
						<input className='mr-1' type='radio' name='automation-tool' id='radio_2_option_3'></input>
						Playwright
					</label>
				</div>
			</div>
		</div>
	);
}
