import { useState } from 'react';
import Accordion from '../../../../components/Accordion/Accordion';
import { frontEndProject3TestCases as data } from './frontEndProjectData';
import Button from '../Partials/Buttons';
import styles from './Projects.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Project3 = () => {
	const adult = 'Adult (16-64)';
	const [passengers, setPassengers] = useState([{ id: 1, age: adult }]);
	const [submitted, setSubmitted] = useState(false);
	const [isRoundTrip, setIsroundTrip] = useState(false);
	const [departDate, setDepartDate] = useState(new Date());
	const [returnDate, setReturnDate] = useState(new Date());
	const [cabinClass, setCabinClass] = useState();
	const [departState, setDepartState] = useState();
	const [arrivalState, setArrivalState] = useState();
	const [isError, setIsError] = useState();

	function handleTripType(e) {
		setSubmitted(false);
		setIsroundTrip(e.target.value === 'Round trip' ? true : false);
	}

	function handleCabinSelect(e) {
		setIsError();
		setSubmitted(false);
		setCabinClass(e.target.value);
	}

	function handleDepartDate(date) {
		setSubmitted(false);
		setDepartDate(date);
		if (date > returnDate) {
			setReturnDate(date);
		}
	}

	function handleReturnDate(date) {
		setSubmitted(false);
		setReturnDate(date);
	}

	function handleDepartState(e) {
		setSubmitted(false);
		setDepartState(e.target.value);
	}

	function handleArrivalState(e) {
		setSubmitted(false);
		setArrivalState(e.target.value);
	}

	function handlePassengerNumber(e) {
		console.log('passengers', passengers);
		setSubmitted(false);
		let newPassengers = [];
		for (let i = 0; i < e.target.value; i++) {
			if (passengers[i]) {
				newPassengers.push({ id: i + 1, age: passengers[i].age });
			} else {
				newPassengers.push({ id: i + 1, age: adult });
			}
		}
		setPassengers(newPassengers);
	}

	function handlePassengerAge(e, passengerId) {
		setSubmitted(false);
		passengers[passengerId - 1].age = e.target.value;
		setPassengers(passengers);
	}

	function handleSubmit(event) {
		event.preventDefault();
		setSubmitted(false);
		if (!cabinClass || !departState || !arrivalState || departState === arrivalState) {
			if (!cabinClass) {
				setIsError('cabin class');
			} else if (!departState) {
				setIsError('departure state');
			} else if (!arrivalState) {
				setIsError('arrival state');
			} else if (departState === arrivalState) {
				setIsError('a different arrival state');
			}
		} else {
			setIsError('');
			setSubmitted(true);
		}
	}

	function stateSelector(direction) {
		return (
			<div className={`field`}>
				<label className='label'>{direction}</label>
				<div className='select' style={{ width: '100%' }}>
					<select
						onChange={direction === 'From' ? handleDepartState : handleArrivalState}
						style={{ width: '100%' }}
					>
						<option hidden>Select state...</option>
						<option value='AL'>Alabama</option>
						<option value='AK'>Alaska</option>
						<option value='AZ'>Arizona</option>
						<option value='AR'>Arkansas</option>
						<option value='CA'>California</option>
						<option value='CO'>Colorado</option>
						<option value='CT'>Connecticut</option>
						<option value='DE'>Delaware</option>
						<option value='DC'>District Of Columbia</option>
						<option value='FL'>Florida</option>
						<option value='GA'>Georgia</option>
						<option value='HI'>Hawaii</option>
						<option value='ID'>Idaho</option>
						<option value='IL'>Illinois</option>
						<option value='IN'>Indiana</option>
						<option value='IA'>Iowa</option>
						<option value='KS'>Kansas</option>
						<option value='KY'>Kentucky</option>
						<option value='LA'>Louisiana</option>
						<option value='ME'>Maine</option>
						<option value='MD'>Maryland</option>
						<option value='MA'>Massachusetts</option>
						<option value='MI'>Michigan</option>
						<option value='MN'>Minnesota</option>
						<option value='MS'>Mississippi</option>
						<option value='MO'>Missouri</option>
						<option value='MT'>Montana</option>
						<option value='NE'>Nebraska</option>
						<option value='NV'>Nevada</option>
						<option value='NH'>New Hampshire</option>
						<option value='NJ'>New Jersey</option>
						<option value='NM'>New Mexico</option>
						<option value='NY'>New York</option>
						<option value='NC'>North Carolina</option>
						<option value='ND'>North Dakota</option>
						<option value='OH'>Ohio</option>
						<option value='OK'>Oklahoma</option>
						<option value='OR'>Oregon</option>
						<option value='PA'>Pennsylvania</option>
						<option value='RI'>Rhode Island</option>
						<option value='SC'>South Carolina</option>
						<option value='SD'>South Dakota</option>
						<option value='TN'>Tennessee</option>
						<option value='TX'>Texas</option>
						<option value='UT'>Utah</option>
						<option value='VT'>Vermont</option>
						<option value='VA'>Virginia</option>
						<option value='WA'>Washington</option>
						<option value='WV'>West Virginia</option>
						<option value='WI'>Wisconsin</option>
						<option value='WY'>Wyoming</option>
					</select>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className='content'>
				<p>
					This project aims to enhance students' understanding of frontend automation, specifically in the
					context of form submission and date-picking processes.
				</p>
				<ul>
					<li>
						Through this project, students will gain hands-on experience in identifying web elements related
						to scheduling and manipulating input fields.
					</li>
					<li>
						Additionally, they will develop expertise in validating form submissions and conducting both
						positive and negative testing on these functions.
					</li>
				</ul>
				<p>
					Students are expected to automate all the provided test cases and submit their work to the
					TechGlobal Instructor Team. This project will prove to be both a fulfilling and educational
					opportunity for students to broaden their knowledge and skills!
				</p>
			</div>
			{data.map((testCase, idx) => (
				<Accordion dataToAccordion={testCase} key={idx} />
			))}
			<br />
			<div className='has-background-white-ter pt-6 pr-6 pl-6 pb-4'>
				<div className='mb-5'>
					<h1 className='is-size-3' style={{ color: 'var(--mainBlue)' }}>
						Book your trip
					</h1>
				</div>
				<form onSubmit={handleSubmit}>
					<div className={`${styles.container}`}>
						<div className='field'>
							<div className='control'>
								<label className='label'>Trip type</label>
								<div className='is-flex'>
									<label className='radio ml-0'>
										<input
											className='mr-1'
											type='radio'
											value='One way'
											checked={!isRoundTrip}
											onChange={handleTripType}
										/>
										One way
									</label>
									<label className='radio'>
										<input
											className='mr-1'
											type='radio'
											value='Round trip'
											checked={isRoundTrip}
											onChange={handleTripType}
										/>
										Round trip
									</label>
								</div>
							</div>
						</div>
						<div className='field'>
							<label className='label'>Cabin Class</label>
							<div className='select' style={{ width: '100%' }}>
								<select style={{ width: '100%' }} onChange={handleCabinSelect}>
									<option hidden>Select cabin class...</option>
									<option>Premium Economy</option>
									<option>Business</option>
									<option>First</option>
								</select>
							</div>
						</div>

						{stateSelector('From')}
						{stateSelector('To')}

						<div className='field'>
							<label className='label'>Depart</label>
							<div className={`control ${styles.datePicker}`}>
								<DatePicker
									selected={departDate}
									onChange={handleDepartDate}
									minDate={new Date()}
									placeholderText='MM/DD/YY'
								/>
							</div>
						</div>
						<div className='field'>
							<label className='label'>Return</label>
							<div className={`control ${styles.datePicker}`}>
								<DatePicker
									selected={isRoundTrip ? returnDate : ''}
									onChange={handleReturnDate}
									minDate={departDate}
									disabled={!isRoundTrip}
									placeholderText='MM/DD/YY'
								/>
							</div>
						</div>
						<div className='field'>
							<label className='label'>Number of passengers</label>
							<div className='select' style={{ width: '100%' }}>
								<select style={{ width: '100%' }} onChange={handlePassengerNumber}>
									<option value='1'>1</option>
									<option value='2'>2</option>
									<option value='3'>3</option>
									<option value='4'>4</option>
									<option value='5'>5</option>
									<option value='6'>6</option>
									<option value='7'>7</option>
									<option value='8'>8</option>
									<option value='9'>9</option>
								</select>
							</div>
						</div>

						{passengers.map((passenger) => {
							const { id } = passenger;
							return (
								<div className={`field`} key={id}>
									<label className='label'>Passenger {id}</label>
									<div className='select' style={{ width: '100%' }}>
										<select style={{ width: '100%' }} onChange={(e) => handlePassengerAge(e, id)}>
											<option>Adult (16-64)</option>
											<option>Senior (65+)</option>
											<option>Young Adult (12-15)</option>
											<option>Child (2-11)</option>
											<option>Infant in seat (under 2)</option>
										</select>
									</div>
								</div>
							);
						})}
					</div>

					<div>
						<div className={`control ${styles.book}`}>
							<Button children='BOOK' type='submit' />
						</div>
					</div>

					{submitted && (
						<div className='ml-3' style={{ width: '100%' }}>
							<div className='field is-flex'>
								<div style={{ width: '48%', color: 'var(--mainBlue)' }}>
									<h1 className='is-underlined'>DEPART</h1>
									<h3 className='is-italic'>
										{departState} to {arrivalState}
									</h3>
									<p>
										{departDate === null ? 'Please select a date.' : `${departDate.toDateString()}`}
									</p>
								</div>
								{isRoundTrip && (
									<div style={{ width: '48%' }}>
										<div className='ml' style={{ color: 'var(--mainBlue)' }}>
											<h1 className='is-underlined'>RETURN</h1>
											<h3 className='is-italic'>
												{arrivalState} to {departState}
											</h3>
											<p>
												{returnDate === null
													? 'Please select a date.'
													: `${returnDate.toDateString()}`}
											</p>
										</div>
									</div>
								)}
							</div>
							<div className='mt-4 is-family-monospace has-text-black'>
								<p>Number of Passengers: {passengers.length}</p>
								{passengers.map((passenger) => {
									return (
										<p key={passenger.id}>
											Passenger {passenger.id}: {passenger.age}
										</p>
									);
								})}
								<p>Cabin class: {cabinClass}</p>
							</div>
						</div>
					)}
					{isError && (
						<div
							className={`notification is-danger ${styles.errorContainer}`}
						>{`Please select ${isError}!`}</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default Project3;
