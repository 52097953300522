const ex1 = `Write a function named \`doubleOrTriple()\` which takes an \`array\` of numbers as argument and a boolean value. It will return the array elements doubled if true or tripled if the boolean value is false.
~~~js
doubleOrTriple([1, 5, 10], true)	-> [2, 10, 20]
doubleOrTriple([3, 7, 2], false)	-> [9, 21, 6]
doubleOrTriple([-1, -2], true) 		-> [-2, -4]
~~~`;

const ex2 = `Write a function named \`splitString()\` which takes a \`string\` and a \`number\` arguments 
and returns the \`string\` back split by the given \`number\`. 

NOTE: Return empty string if the string shorter than splitting number or the string length is not divisible by the given number.

~~~js
splitString("JavaScript", 5)	-> "JavaS cript"
splitString("Java", 2)			-> "Ja va"
splitString("Automation", 3)	-> ""
~~~`;

const ex3 = `Write a function named \`countPalindrome()\` which takes a \`string\` and returns the number of  palindrome words.

**Note:** A palindrome word is a word that reads the same forwards and backwards. Example: level, radar, deed, refer.
~~~js
countPalindrome("Mom and Dad")				                -> 2
countPalindrome("See you at noon")				            -> 1
countPalindrome("Kayak races attracts racecar drivers")	    -> 2
~~~`;

const ex4 = `Write a function named \`sum()\` which takes an \`array\` of numbers and a boolean value as arguments. It will return the sum of the numbers positioned at even indexes if true. And, return sum of numbers positioned at odd indexes if false.
~~~js
sum([1, 5, 10], true)		    -> 11
sum([3, 7, 2, 5, 10], false)	-> 12
sum([-1, 1, -2, 2], true)	    -> -3
~~~`;

const ex5 = `Write a function named \`nthChars()\` which takes a \`string\` and a \`number\` as arguments and returns the string back with every nth characters.
~~~js
nthChars("Java", 2)		    -> "aa"
nthChars("JavaScript", 5)	-> "St"
nthChars("Java", 3) 		-> "v"
~~~`;

const ex6 = `Write a function named \`canFormString()\` which takes two \`string\` arguments and returns true if the second string can be formed by rearranging the characters of first string. Return false otherwise.

**NOTE:** This method is case-insensitive and ignore the white spaces.
~~~js
canFormString("Hello", "Hi")			    -> false
canFormString("programming", "gaming")	    -> true
canFormString("halogen", "hello")		    -> false
~~~`;

const ex7 = `Write a function named \`isAnagram()\` which takes two \`string\` arguments and returns true if the given strings are anagram. Return false otherwise.

**NOTE:** An anagram is a word or phrase formed by rearranging the letters of another word or phrase. In the context of strings, checking if two strings are anagrams of each other means verifying if they contain the same characters in the same quantities, regardless of the order of those characters.
~~~js
isAnagram("Apple", "Peach") 			    -> false
isAnagram("listen", "silent")			    -> true
isAnagram("astronomer", "moon starer")	    -> true
~~~`;

const ex8 = `Write a function named \`count()\` which takes an \`array\` of numbers and a boolean value as arguments. It will return the total count of the even numbers if the boolean value is true. And, return the total count of the odd numbers if the boolean value is false.
~~~js
count([1, 5, 10], true)		        -> 1
count([3, 7, 2, 5, 10], false)	    -> 3
count([-1, 1, -2, 2], true)	        -> 2
~~~`;

const ex9 = `Write a function named \`sumDigitsDouble()\` which takes a \`string\` and returns the sum of the digits in the given String multiplied by 2. Return -1 if the given string does not have any digits. Ignore negative numbers.
~~~js
sumDigitsDouble("Javascript")	-> -1
sumDigitsDouble("23abc45")		-> 28
sumDigitsDouble("Hi-23")		-> 10
~~~`;

const ex10 = `Write a function named \`countOccurrence()\` which takes two \`string\` arguments and returns how many times that the first string can form the second string.
~~~js
countOccurrence("Javascript", "Java")	    -> 1
countOccurrence("Hello", "World")		    -> 0
countOccurrence("Can I can a can", "anc")	-> 3
~~~`;

export const exercise04ExercisesData = [
	{
		title: 'Double Or Triple',
		content: ex1,
	},
	{
		title: 'Split String',
		content: ex2,
	},
	{
		title: 'Count Palindrome',
		content: ex3,
	},
	{
		title: 'Sum Even Odd Indexes',
		content: ex4,
	},
	{
		title: 'Nth Chars',
		content: ex5,
	},
	{
		title: 'Can Form String',
		content: ex6,
	},
	{
		title: 'Is Anagram',
		content: ex7,
	},
	{
		title: 'Count Even or Odd Numbers',
		content: ex8,
	},
	{
		title: 'Sum Digits Double',
		content: ex9,
	},
	{
		title: 'Count Occurrence',
		content: ex10,
	},
];
