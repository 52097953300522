import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import styles from './SortableTables.module.css';

export default function SortableTables() {
	const products = [
		{
			quantity: '1',
			productName: 'iPhone',
			price: '1000',
			total: 1000,
		},
		{
			quantity: '3',
			productName: 'Airpods',
			price: '100',
			total: 300,
		},
		{
			quantity: '2',
			productName: 'iPad',
			price: '500',
			total: 1000,
		},
	];

	const [data, setData] = useState(products);
	const [order, setOrder] = useState('ASC');

	// const sorting = (col) => {
	// 	if (order === 'ASC') {
	// 		const sorted = [...data].sort((a, b) => (a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1));
	// 		setData(sorted);
	// 		setOrder('DSC');
	// 	}
	// 	if (order === 'DSC') {
	// 		const sorted = [...data].sort((a, b) => (a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1));
	// 		setData(sorted);
	// 		setOrder('ASC');
	// 	}
	// };

	const sortingNum = (col) => {
		if (order === 'DSC' || order === 'ASC') {
			const sorted2 = [...data].sort((a, b) =>
				Number(a[col].replace(/\D/g, '')) > Number(b[col].replace(/\D/g, '')) ? 1 : -1
			);
			setData(sorted2);
			setOrder('DSC');
		}
		// if (order === 'DSC') {
		// 	const sorted = [...data].sort((a, b) =>
		// 		Number(a[col].replace(/\D/g, '')) < Number(b[col].replace(/\D/g, '')) ? 1 : -1
		// 	);
		// 	setData(sorted);
		// 	setOrder('ASC');
		// }
	};

	const sortingNum2 = (col) => {
		// if (order === 'ASC') {
		// 	const sorted2 = [...data].sort((a, b) =>
		// 		Number(a[col].replace(/\D/g, '')) > Number(b[col].replace(/\D/g, '')) ? 1 : -1
		// 	);
		// 	setData(sorted2);
		// 	setOrder('DSC');
		// }
		if (order === 'ASC' || order === 'DSC') {
			const sorted = [...data].sort((a, b) =>
				Number(a[col].replace(/\D/g, '')) < Number(b[col].replace(/\D/g, '')) ? 1 : -1
			);
			setData(sorted);
			setOrder('ASC');
		}
	};

	function Rows() {
		return data.map((val, key) => {
			return (
				<tr key={key}>
					<td>{val.quantity}</td>
					<td>{val.productName}</td>
					<td>{val.price}</td>
					<td>{val.total}</td>
				</tr>
			);
		});
	}

	return (
		<div>
			<p>See some of the Apple products</p>
			<br />
			<div className={`table-container ${styles.tableContainer}`}>
				<table id='sortable_table' className='table is-bordered is-striped is-hoverable is-fullwidth'>
					<thead className={styles.tableHeader}>
						<tr>
							<th className={styles.th}>
								<div>Quantity</div>
								<div className={styles.sort}>
									<FontAwesomeIcon
										id='quantity_asc'
										onClick={() => sortingNum('quantity')}
										icon={faArrowUp}
										color='white'
										cursor='pointer'
										className='ml-2'
									/>
									<FontAwesomeIcon
										id='quantity_desc'
										onClick={() => sortingNum2('quantity')}
										icon={faArrowDown}
										color='white'
										cursor='pointer'
										className='ml-2'
									/>
								</div>
							</th>
							<th className={styles.th}>Product</th>
							<th className={styles.th}>
								<div>Price $</div>
								<div className={styles.sort}>
									<FontAwesomeIcon
										id='price_asc'
										onClick={() => sortingNum('price')}
										icon={faArrowUp}
										color='white'
										cursor='pointer'
									/>
									<FontAwesomeIcon
										id='price_desc'
										onClick={() => sortingNum2('price')}
										icon={faArrowDown}
										color='white'
										cursor='pointer'
										className='ml-2'
									/>
								</div>
							</th>
							<th className={styles.th}>Total $</th>
						</tr>
					</thead>
					<tbody>
						<Rows />
					</tbody>
				</table>
			</div>
		</div>
	);
}
