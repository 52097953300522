const ex1 = `Write a function named \`noDigit()\` which takes a \`string\` argument and returns a new \`string\` with all digits removed from the original string.
~~~js
noDigit("")				    -> ""
noDigit("Javascript")	    -> "Javascript"
noDigit("123Hello")			-> "Hello"
~~~`;

const ex2 = `Write a function named \`noVowel()\` which takes a \`string\` argument and returns a new \`string\` with all vowels removed from the original string.
~~~js
noVowel("TechGlobal")	-> "TchGlbl"
noVowel("xyz")		    -> "xyz"
noVowel("Javascript")	-> "Jvscrpt"
~~~`;

const ex3 = `Write a function named \`sumOfDigits()\` which takes a \`string\` argument and returns sum of all digits from the original \`string\`.
~~~js
sumOfDigits("Javascript")		-> 0
sumOfDigits("John's age is 29")	-> 11
sumOfDigits("$125.0")		    -> 8
~~~`;

const ex4 = `Write a function named \`hasUpperCase()\` which takes a \`string\` argument and return \`true\` if there is an uppercase letter and \`false\` otherwise.
~~~js
hasUpperCase("javascript")			-> false
hasUpperCase("John")			    -> true
hasUpperCase("$125.0")			    -> false
~~~`;

const ex5 = `Write a function named \`hasLowerCase()\` which takes a \`string\` argument and returns \`true\` if there is a lowercase letter and \`false\` if it there isn't.
~~~js
hasLowerCase("")			-> false
hasLowerCase("JAVASCRIPT")	-> false
hasLowerCase("hello")		-> true
~~~`;

const ex6 = `Write a function named \`noZero()\` which takes an \`array\` of numbers as argument and returns the \`array\` back with all zeros removed.
~~~js
noZero([1])			    -> [1]
noZero([1, 1, 10])		-> [1, 1, 10]
noZero([0, 1, 10])		-> [1, 10]
~~~`;

const ex7 = `Write a function named \`no13()\` which takes an \`array\` of numbers as argument and return a new \`array\` with all 13s replaced with 0s.
~~~js
no13([1, 2, 3 ,4])		    -> [1, 2, 3 ,4] 
no13([13, 2, 3])		    -> [0, 2, 3]
no13([13, 13, 13 , 13, 13])	-> [0, 0, 0, 0, 0]
~~~`;

const ex8 = `Write a function named \`middleInt()\` which takes three \`number\` arguments and return the middle number.
~~~js
middleInt(1, 2, 2)	-> 2
middleInt(5, 5, 8)	-> 5
middleInt(5, 3, 5)	-> 5
~~~`;

const ex9 = `Write a function named \`numberAndSquare()\` which takes an \`array\` of numbers as argument and returns a multidimensional \`array\` with all numbers squared.
~~~js
numberAndSquare([1, 2, 3])		-> [[1, 1], [2, 4], [3, 9]]
numberAndSquare([0, 3, -6])		-> [[0, 0], [3, 9], [-6, 36]]
numberAndSquare([1, 4])		    -> [[1,1], [4, 16]]
~~~`;

const ex10 = `Write a function named \`containsValue()\` which takes a string \`array\` and a \`string\` as arguments and returns a \`boolean\` value. Search the \`string\` variable inside of the \`array\` and return \`true\` if it exists in the \`array\`. If it doesn't exist, return \`false\`.

**NOTE**: Assume that \`array\` size is at least 1.

**NOTE**: The method is case-sensitive

~~~js
containsValue(["abc", "foo", "javascript"], "hello")			    -> false
containsValue(["abc", "def", "123"], "Abc")				            -> false
containsValue(["abc", "def", "123", "Javascript", "Hello"], "123")	-> true
~~~`;

const ex11 = `Write a function named \`reverseSentence()\` which takes a \`string\` as argument and returns the words in reverse order. If there is no enough words reverse, return "There is not enough words!".
~~~js
reverseSentence("Hello")			    -> "There is not enough words!"
reverseSentence("Javascript is fun")	-> "Fun is javascript"
reverseSentence("This is a sentence")	-> "Sentence a is this"
~~~`;

const ex12 = `Write a function named \`removeStringSpecialsDigits()\` which takes a \`string\` as argument and return a \`string\` without the special characters or digits.
~~~js
removeStringSpecialsDigits("123Javascript #$%is fun")	-> "Javascript is fun" 
removeStringSpecialsDigits("Cypress") 			        -> "Cypress"
removeStringSpecialsDigits("Automation123#$%")		    -> "Automation"
~~~`;

const ex13 = `Write a function named \`removeArraySpecialsDigits()\` which takes a \`string array\` as argument and return back without the special characters or digits.
~~~js
removeArraySpecialsDigits(["123Javascript", "#$%is", "fun"])	-> ["Javascript", "is", "fun"]
removeArraySpecialsDigits(["Cypress", "123$%", "###"]) 		    -> ["Cypress", "", ""]
removeArraySpecialsDigits(["Automation", "123#$%tool"])		    -> ["Automation", "tool"]
~~~`;

const ex14 = `Write a function named \`getCommons()\` which takes two \`string arrays\` as arguments and returns all the common words.
~~~js
getCommons( ["Javascript", "is", "fun"], ["abc", "xyz", "123"])		        -> []
getCommons( ["Javascript", "is", "fun"], ["Javascript", "C#", "Python"])	-> ["Javascript"]
getCommons( ["Javascript", "C#", "C#"], ["Python", "C#", "C++"])		    -> ["C#"]
~~~`;

const ex15 = `Write a function named \`noXInVariables()\` which takes an \`array\` as argument and return an array that all the x or X removed from the elements. 

**NOTE**: If the element is existing of x or X letters only, then completely remove the element.
~~~js
noXInVariables(["abc", 123, "#$%"])				-> ["abc", 123, "#$%"]
noXInVariables(["xyz", 123, "#$%"])				-> ["yz", 123, "#$%"]
noXInVariables(["x", 123, "#$%"])				-> [123, "#$%"]
~~~`;

const ex16 = `Write a function named \`arrFactorial()\` which takes an \`array\` of numbers as argument and return the \`array\` with every number replaced with their factorials.

**NOTE**: 0! Is evaluated to 1.
~~~js
arrFactorial([1, 2, 3 ,4])	    -> [1, 2, 6, 24]
arrFactorial([0, 5])		    -> [1,120]
arrFactorial([5 , 0, 6])		-> [120, 1, 720]
~~~`;

const ex17 = `Write a function named \`categorizeCharacters()\` which takes a \`string\` word as argument and return an \`array\` as letters at index of 0, digits at index of 1 and specials at index of 2.
~~~js
categorizeCharacters("1234")		    -> [ '' , '1234', '' ] 
categorizeCharacters("abc123$#%")		-> [ 'abc', '123', '$#%' ]
categorizeCharacters("12ab$%3c%")	    -> [ 'abc', '123', '$%%' ]
~~~`;

export const exercise02ExercisesData = [
	{
		title: 'No Digit',
		content: ex1,
	},
	{
		title: 'No Vowel',
		content: ex2,
	},
	{
		title: 'Sum Of Digits',
		content: ex3,
	},
	{
		title: 'Has Upper Case',
		content: ex4,
	},
	{
		title: 'Has Lower Case',
		content: ex5,
	},
	{
		title: 'No Zero',
		content: ex6,
	},
	{
		title: 'No 13',
		content: ex7,
	},
	{
		title: 'Middle Int',
		content: ex8,
	},
	{
		title: 'Number and Square',
		content: ex9,
	},
	{
		title: 'Contains Value',
		content: ex10,
	},
	{
		title: 'Reverse Sentence',
		content: ex11,
	},
	{
		title: 'Remove String Specials Digits',
		content: ex12,
	},
	{
		title: 'Remove Array Specials Digits',
		content: ex13,
	},
	{
		title: 'Return Commons',
		content: ex14,
	},
	{
		title: 'No X In Variables',
		content: ex15,
	},
	{
		title: 'Arr Factorial',
		content: ex16,
	},
	{
		title: 'Categorize Characters',
		content: ex17,
	},
];
