import styles from './Programs.module.css';
import { programs } from './progams';

const Programs = () => {
	return (
		<div className={styles.programs}>
			{programs?.map((program) => (
				<div className={styles.program} key={program.id}>
					<div className={`${styles.block}  ${program.reverse ? styles.right : null}`}>
						<h4>{program?.title}</h4>
						<p>{program?.description}</p>
						<a href={program?.url} target={'_blank'} rel='noreferrer'>
							<button>Read More</button>
						</a>
					</div>
					<div className={styles.block}>
						<img src={require(`${program?.img?.src}`)} alt={program?.img?.alt} />
					</div>
				</div>
			))}
		</div>
	);
};

export default Programs;
