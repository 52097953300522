const ex1 = `Write a function named \`countWords()\` which takes a \`string\` argument, and returns the total count of words in the given \`string\` when invoked.
~~~js
countWords("     Javascript is fun       ")			 -> 3
countWords("Cypress is an UI automation tool.    ")	 -> 6
countWords("1 2 3 4")								 -> 4
~~~
`;

const ex2 = `Write a function named \`countA()\` which takes a \`string\` argument, and returns the total count of \`A\` or \`a\` in the given \`string\`.
~~~js
countA("TechGlobal is a QA bootcamp")		-> 4
countA("QA stands for Quality Assurance")	-> 5
countA("Cypress")							-> 0
~~~`;

const ex3 = `Write a function named \`countPos()\` which takes an \`array\` of numbers as an argument, and returns the total count of positive numbers.
~~~js
countPos([-45, 0, 0, 34, 5, 67])		-> 3
countPos([-23, -4, 0, 2, 5, 90, 123])	-> 4
countPos([0, -1, -2, -3])				-> 0
~~~`;

const ex4 = `Write a function named \`removeDuplicates()\` which takes an \`array\` as an argument, and returns it back with duplicates removed. 
~~~js
removeDuplicates([10, 20, 35, 20, 35, 60, 70, 60])					-> [10, 20, 35, 60, 70]
removeDuplicates(["1", "2", "3", "2", "3"])							-> ["1", "2", "3"]
removeDuplicates(["javascript", "C#", "ruby", "ruby", "C#", "C++"])	-> ["javascript", "C#", "ruby", "C++"]
~~~`;

const ex5 = `Write a function named \`removeExtraSpaces()\` which takes a \`string\` as argument, and returns a \`string\` that extra spaces have removed. 
~~~js
removeExtraSpaces("  I  am    learning   Javascript   ")	-> "I am learning Javascript"
removeExtraSpaces("Javascript  is fun    ")					-> "Javascript is fun"
removeExtraSpaces("  Cypress   is number  1")				-> "Cypress is number 1"
~~~`;

const ex6 = `Write a function named \`add()\` which takes two \`array\` of numbers as argument, and returns a new \`array\` with sum of given arrays elements.
~~~js
add([3, 0, 0, 7, 5, 10], [6, 3, 2])				-> [9, 3, 2, 7, 5, 10]
add([10, 3, 6, 3, 2], [6, 8, 3, 0, 0, 7, 5, 1])	-> [16, 11, 9, 3, 2, 7, 5, 1]
add([-5, 6, -3, 11], [5, -6, 3, -11])			-> [0, 0, 0, 0]
~~~`;

const ex7 = `Write a function named \`noSpace()\` which takes a \`string\` argument and returns a new \`string\` with all spaces removed.
~~~js
noSpace("")				-> ""
noSpace("Javascript")	-> "Javascript"
noSpace("    Hello   ")	-> "Hello"
~~~`;

const ex8 = `Write a function named \`replaceFirstLast()\` which takes a \`string\` argument and returns a new \`string\` with first and last characters replaced.  
**NOTE:** If the length is less than 2, return an empty \`string\`.  
**NOTE:** Ignore spaces.
~~~js
replaceFirstLast("")			-> ""
replaceFirstLast("Hello")		-> "oellH"
replaceFirstLast("Tech Global")	-> "lech GlobaT"
~~~`;

const ex9 = `Write a function named \`hasVowel()\` which takes a \`string\` argument and returns \`true\` if the \`string\` has a vowel, returns \`false\` if the \`string\` doesn't contain any vowel letter.  
**NOTE:** Vowels are = a, e, o, u, i.  
**NOTE:**  Ignore upper/lower cases.
~~~js
hasVowel("")			-> false
hasVowel("Javascript")	-> true
hasVowel("Tech Global")	-> true
~~~`;

const ex10 = `Write a function named \`checkAge()\` which takes a \`number\` yearOfBirth as argument and will return a message below based on the age.  
If the age is less than 16, return "AGE IS NOT ALLOWED"  
If the age is 16 or more, return "AGE IS ALLOWED"  
If the age is more than 120 or negative, return "AGE IS NOT VALID"  
**NOTE:** Consider someone born in 2013 is 10 years old as of 2023.
~~~js
checkAge(2015)	-> "AGE IS NOT ALLOWED"
checkAge(2006)	-> "AGE IS ALLOWED"
checkAge(2050)	-> "AGE IS NOT VALID"
~~~`;

const ex11 = `Write a function named \`averageOfEdges()\` which takes three \`number\` arguments and will return average of min and max numbers.
~~~js
averageOfEdges(0, 0, 0)		-> 0
averageOfEdges(0, 0, 6)		-> 3
averageOfEdges(-2, -2, 10)	-> 4
~~~`;

const ex12 = `Write a function named \`noA()\` which takes an \`array\` of strings as argument and will return a new \`array\` with all elements starting with "A" or "a" replaced with "###".
~~~js
noA(["javascript", "hello", "123", "xyz"])	-> ["javascript", "hello", "123", "xyz"]
noA(["apple", "123", "ABC", "javascript"])	-> ["###", "123", "###", "javascript"]
noA(["apple", "abc", "ABC", "Alex", "A"])	-> ["###", "###", "###", "###", "###"]
~~~`;

const ex13 = `Write a function named \`no3and5()\` which takes an \`array\` of integer numbers as argument and will return a new \`array\` with elements replaced by conditions below.  
If element can be divided by 5, replace it with 99  
If element can be divided by 3, replace it with 100  
If element can be divided by both 3 and 5, replace it with 101
~~~js
no3and5([7, 4, 11, 23, 17])			-> [7, 4, 11, 23, 17]
no3and5([3, 4, 5, 6])				-> [100, 4, 99, 100]
no3and5([10, 11, 12, 13, 14, 15])	-> [99, 11, 100, 13, 14, 101]
~~~`;

const ex14 = `Write a method named \`secondMax()\` takes an \`array\` argument and returns the second max \`number\` from the array.  
**NOTE:** Assume that you will not be given empty array and if the array has only 1 element, it will be returned as second max number.  
**NOTE:** Be careful if  there are multiple max numbers.  
~~~js
secondMax([7, 4, 4, 4, 23, 23, 23])	-> 7
secondMax([3, 4, 5, 6])				-> 5
secondMax([10])						-> 10
~~~`;

const ex15 = `Write a method named \`secondMin()\` takes an \`array\` argument and returns the second min \`number\` from the array.  
**NOTE:** Assume that you will not be given empty array and if the array has only 1 element, it will be returned as second min number.  
**NOTE:** Be careful if  there are multiple min numbers.  
~~~js
secondMin([7, 4, 4, 4, 23, 23, 23])	-> 7
secondMin([3, 4, 5, 6])				-> 4
secondMin([10])						-> 10
~~~`;

const ex16 = `Write a function named \`findClosestTo10()\` which takes an \`array\` of numbers as argument, and returns the closest element to 10 from the given \`array\`.  
**NOTE:** Assume that length of array is always more than zero.  
**NOTE:** Ignore the 10 itself.  
**NOTE:** If there are more than one numbers are close equally, return the smaller number.
~~~js
findClosestTo10([10, -13, 5, 70, 15, 57])	-> 5
findClosestTo10([10, -13, 8, 12, 15, -20])	-> 8
findClosestTo10([0, -1, -2])				-> 0
~~~`;

const ex17 = `Write a function named \`countPrimes()\` which takes an \`array\` of numbers as argument, and will return the number of the prime numbers in the given \`array\`.  
**NOTE:** Prime number is a number that can be divided only by 1 and itself.  
**NOTE:** Negative numbers cannot be prime.  
**Examples:** 2,3,5,7,11,13,17,19,23,29,31,37 etc.  
**NOTE:** Smallest prime number is 2.
~~~js
countPrimes([-10, -3, 0, 1])		-> 0
countPrimes([7, 4, 11, 23, 17])		-> 4
countPrimes([41, 53, 19, 47, 67])	-> 5
~~~`;

const ex18 = `Write a method named \`mostRepeated()\` takes an \`array\` argument and returns the most counted element from the \`array\`.  
**NOTE:** Assume that you will not be given empty array and the count of one element will always be more than the others.
~~~js
mostRepeated([4, 7, 4, 4, 4, 23, 23, 23])						-> 4
mostRepeated(["pen", "pencil", "pen", "123", "pen", "pencil"])	-> "pen"
mostRepeated(["TechGlobal"])									-> "TechGlobal"
~~~`;

export const exercise01ExercisesData = [
	{
		title: 'Count Words',
		content: ex1,
	},
	{
		title: 'Count A',
		content: ex2,
	},
	{
		title: 'Count Pos',
		content: ex3,
	},
	{
		title: 'Remove Duplicates',
		content: ex4,
	},
	{
		title: 'Remove Extra Spaces',
		content: ex5,
	},
	{
		title: 'Add',
		content: ex6,
	},
	{
		title: 'No Space',
		content: ex7,
	},
	{
		title: 'Replace First Last',
		content: ex8,
	},
	{
		title: 'Has Vowel',
		content: ex9,
	},
	{
		title: 'Check Age',
		content: ex10,
	},
	{
		title: 'Average Of Edges',
		content: ex11,
	},
	{
		title: 'No A',
		content: ex12,
	},
	{
		title: 'No 3 And 5',
		content: ex13,
	},
	{
		title: 'Second Max',
		content: ex14,
	},
	{
		title: 'Second Min',
		content: ex15,
	},
	{
		title: 'Find Closest To 10',
		content: ex16,
	},
	{
		title: 'Count Primes',
		content: ex17,
	},
	{
		title: 'Most Repeated Element',
		content: ex18,
	},
];
