const ex1 = `Write a function named as \`sayHello()\` which is \`void\`(doesn't return a value) and outputs \`Hello\` five times when invoked.
~~~js
sayHello()	->	"Hello"
				"Hello"
				"Hello"
				"Hello"
				"Hello"
~~~`;

const ex2 = `Write a function named as \`countToSeven()\` which is \`void\`(doesn't return a value) and outputs the numbers from 1 to 7 (both inclusive) when invoked.
~~~js
countToSeven()	->	1
					2
					3
					4
					5
					6
					7
~~~`;

const ex3 = `Write a function named as \`countThreeToZero()\` which is \`void\`(doesn't return a value) and outputs the numbers backward from 3 to 0 (both inclusive) when invoked.
~~~js
countToZero()	->	3
					2
					1
					0
~~~
`;

const ex4 = `Write a function named as \`doubleWord()\` which is \`void\`(doesn't return a value) and takes a \`string\` word as an argument and outputs the given word twice when invoked.
~~~js
doubleWord("Tech")	->	"Tech"
						"Tech"
doubleWord("Global")->	"Global"
						"Global"
~~~
`;

const ex5 = `Write a function named as \`logChars()\` which is \`void\`(doesn't return a value) and takes a \`string\` word as an argument and outputs the words letter by letter when invoked.  
**NOTE**: Assume you will not be given an empty word.
~~~js
logChars("Tech")	->	"T"
						"e"
						"c"
						"h"
 
logChars("Global")	->	"G"
						"l"
						"o"
						"b"
						"a"
						"l" 
~~~
`;

const ex6 = `Write a function named as \`logCharsReversed()\` which is \`void\`(doesn't return a value) and takes a \`string\` word as an argument and outputs the words letter by letter in a reverse order when invoked.  
**NOTE**: Assume you will not be given an empty word.
~~~js
logCharsReversed("Tech")	->	"h"
								"c"
								"e"
								"T"
 
logCharsReversed("Global")	->	"l"
								"a"
								"b"
								"o"
								"l"
								"G" 
~~~
`;

const ex7 = `Write a function named as \`sum()\` which takes a positive \`number\` argument and returns the sum of the numbers from 1 to the given number (both inclusive) when invoked.
~~~js
sum(3) -> 6
sum(5) -> 15
~~~`;

const ex8 = `Write a function named as \`sumEvens()\` which takes a positive \`number\` argument and returns the sum of the even numbers from 1 to the given number (both inclusive) when invoked.
~~~js
sumEvens(3) -> 2
sumEvens(5) -> 6
~~~`;

const ex9 = `Write a function named as \`sumOdds()\` which takes a positive \`number\` argument and returns the sum of the odd numbers from 1 to the given number (both inclusive) when invoked.
~~~js
sumOdds(3) -> 4
sumOdds(5) -> 9
~~~`;

const ex10 = `Write a function named as \`countEvens()\` which takes a positive \`number\` argument and returns the count of the even numbers from 1 to the given number (both inclusive) when invoked.
~~~js
countEvens(3) -> 1
countEvens(5) -> 2
~~~`;

const ex11 = `Write a function named as \`countOdds()\` which takes a positive \`number\` argument and returns the count of the odd numbers from 1 to the given number (both inclusive) when invoked.
~~~js
countOdds(3) -> 2
countOdds(5) -> 3
~~~`;

const ex12 = `Write a method named as \`countThreeDividend()\` which takes a positive \`number\` argument and returns the count of the numbers divisible by 3 from 1 to the given number (both inclusive) when invoked.

~~~js
countThreeDividend(3) -> 1
countThreeDividend(8) -> 2
~~~`;

const ex13 = `Write a function named as \`sum()\` which takes two \`number\` arguments and returns the sum of the numbers from the start
to end number (both inclusive) when invoked.
~~~js
sum(1, 3) -> 6
sum(5, 2) -> 14
~~~`;

const ex14 = `Write a function named as \`product()\` which takes two \`number\` arguments and returns the product of the numbers from the start to end number (both inclusive) when invoked.
~~~js
product(1, 3)  -> 6
product(5, 2)  -> 120
product(-1, 2) -> -0
~~~`;

const ex15 = `Write a function named as \`countFiveDividend()\` which takes two \`number\` arguments and returns the count of the numbers divisible by 5 from the start to end number (both inclusive) when invoked.
~~~js
countFiveDividend(2, 4)  -> 0
countFiveDividend(7, 4)  -> 1
countFiveDividend(0, 15) -> 4
~~~`;

const ex16 = `Write a function named as \`countA()\` which takes a \`string\` word as an argument and returns the count of the uppercase or lowercase letter A in the \`string\` when invoked.
NOTE: Assume you will not be given an empty word.
~~~js
countA("Tech")    -> 0
countA("Global")  -> 1
countA("America") -> 2
~~~`;

// Diffculty levels will be implemented later
export const jsLoopsData = [
	{
		title: 'Five Times Hello',
		content: ex1,
		// difficulty: 'Easy',
	},
	{
		title: 'Numbers 1 to 7',
		content: ex2,
		// difficulty: 'Easy',
	},
	{
		title: 'Numbers 3 to 0',
		content: ex3,
		// difficulty: 'Easy',
	},
	{
		title: 'Double The Word',
		content: ex4,
		// difficulty: 'Easy',
	},
	{
		title: 'String Characters',
		content: ex5,
		// difficulty: 'Medium',
	},
	{
		title: 'String Characters Reversed',
		content: ex6,
		// difficulty: 'Medium',
	},
	{
		title: 'Sum Numbers From 1 to Given Number',
		content: ex7,
	},
	{
		title: 'Sum Even Numbers From 1 to Given Number',
		content: ex8,
	},
	{
		title: 'Sum Odd Numbers From 1 to Given Number',
		content: ex9,
	},
	{
		title: 'Count Evens from 1 to Given Number',
		content: ex10,
	},
	{
		title: 'Count Odds from 1 to Given Number',
		content: ex11,
	},
	{
		title: 'Count Numbers Divisible by 3 from 1 to Given Number',
		content: ex12,
	},
	{
		title: 'Sum of Numbers From Start to End',
		content: ex13,
	},
	{
		title: 'Product of Numbers From Start to End',
		content: ex14,
	},
	{
		title: 'Count Numbers Divisible by 5 From Start to End',
		content: ex15,
	},
	{
		title: 'Count String Character A',
		content: ex16,
	},
];
