import SortableTables from '../SortableTables/SortableTables';

export default function Tables() {
	const companies = [
		{
			rank: 1,
			company: 'Amazon',
			employees: '1,523,000',
			country: 'USA',
		},
		{
			rank: 2,
			company: 'Alibaba',
			employees: '245,700',
			country: 'China',
		},
		{
			rank: 3,
			company: 'Microsoft',
			employees: '221,000',
			country: 'USA',
		},
		{
			rank: 4,
			company: 'Apple',
			employees: '154,000',
			country: 'USA',
		},
		{
			rank: 5,
			company: 'Samsung',
			employees: '116,915',
			country: 'S. Korea',
		},
	];

	function Rows() {
		return companies.map((val, key) => {
			return (
				<tr key={key}>
					<td>{val.rank}</td>
					<td>{val.company}</td>
					<td>{val.employees}</td>
					<td>{val.country}</td>
				</tr>
			);
		});
	}

	return (
		<div>
			<h2>Static Table</h2>
			<p style={{ width: '100%' }}>See some of the top publicly traded tech companies by number of employees</p>
			<br />
			<div className='table-container'>
				<table
					id='static_table'
					className={`table is-bordered is-striped is-hoverable`}
					style={{ width: '100%' }}
				>
					<thead style={{ backgroundColor: 'var(--mainBlue)', color: 'white' }}>
						<tr>
							<th className='header has-text-white'>Rank</th>
							<th className='header has-text-white'>Company</th>
							<th className='header has-text-white'>Employees</th>
							<th className='header has-text-white'>Country</th>
						</tr>
					</thead>
					<tbody>
						<Rows />
					</tbody>
				</table>
			</div>
			<hr />
			<h2>Sortable Table</h2>
			<SortableTables />
		</div>
	);
}
