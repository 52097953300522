const ex1 = `Write a function named as \`older30()\` which takes an \`array of user objects\` as an argument and 
returns the count of users who are older than 30 when invoked.

~~~js
older30([
  {
    'firstName': 'Tech',
    'lastName': 'Global',
    'email': 'tech.global@techglobal.com',
    'age': 3
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  }
]) -> 0

older30([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 47
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) -> 2

older30([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 31
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) -> 2
~~~`;

const ex2 = `Write a function named as \`averageAge()\` which takes an \`array of user objects\` as an argument 
and returns the average age of users when invoked.

~~~js
averageAge([
  {
    'firstName': 'Tech',
    'lastName': 'Global',
    'email': 'tech.global@techglobal.com',
    'age': 4
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  }
]) -> 17

averageAge([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 46
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) -> 36

averageAge([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 31
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) -> 31.5
~~~
`;
const ex3 = `Write a function named as \`oldest()\` which takes an \`array of user objects\` as an argument and 
returns the oldest user’s name and age in the format of  \`{name} is the oldest with the age of {age}\`. when invoked.

NOTE: If the user array is empty, then return -1.

~~~js
oldest([
  {
    'firstName': 'Tech',
    'lastName': 'Global',
    'email': 'tech.global@techglobal.com',
    'age': 4
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  }
]) -> 'Jane is the oldest with the age of 30.'

oldest([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 46
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) ->  'John is the oldest with the age of 46.'

oldest([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 31
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) ->  'Alex is the oldest with the age of 32.'
~~~
`;
const ex4 = `Write a function named as \`youngest()\` which takes an \`array of user objects\` as an argument and returns the youngest userU+2019s name and 
age in the format of  \`{name} is the youngest with the age of {age}.\` when invoked.

NOTE: If the user array is empty, then return -1.

~~~js
youngest([
  {
    'firstName': 'Tech',
    'lastName': 'Global',
    'email': 'tech.global@techglobal.com',
    'age': 4
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  }
]) ->  'Tech is the youngest with the age of 30.'

youngest([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 46
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) -> 'Jane is the youngest with the age of 46.'

youngest([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 31
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) ->  'John is the youngest at the age of 32.'
~~~
`;
const ex5 = `
Write a function named \`getNames()\` which takes an \`array of user objects\` as an argument and returns the full names of users as an array when invoked.

NOTE: If the user array is empty, then return -1.

~~~js
getNames([
  {
    'firstName': 'Tech',
    'lastName': 'Global',
    'email': 'tech.global@techglobal.com',
    'age': 4
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  }
]) ->  [ 'Tech Global', 'Jane Doe' ]

getNames([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 46
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) ->  [ 'John Doe', 'Jane Doe', 'Alex Smith' ]

getNames([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 31
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) ->  [ 'John Doe', 'Alex Smith' ]
~~~
`;
const ex6 = `Write a function named \`getEmails()\` which takes an \`array of user objects\` as an argument and 
returns the emails addresses of users as an array when invoked.

NOTE: If the user array is empty, then return -1.

~~~js
getEmails([
  {
    'firstName': 'Tech',
    'lastName': 'Global',
    'email': 'tech.global@techglobal.com',
    'age': 4
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  }
]) -> [ 'tech.global@techglobal.com', 'janedoe@gmail.com' ]

getEmails([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 46
  },
  {
    'firstName': 'Jane',
    'lastName': 'Doe',
    'email': 'janedoe@gmail.com',
    'age': 30
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) ->  [ 'johndoe@gmail.com', 'janedoe@gmail.com', 'alexsmith@outlook.com' ]

getEmails([
  {
    'firstName': 'John',
    'lastName': 'Doe',
    'email': 'johndoe@gmail.com',
    'age': 31
  },
  {
    'firstName': 'Alex',
    'lastName': 'Smith',
    'email': 'alexsmith@outlook.com',
    'age': 32
  }
]) ->  [ 'johndoe@gmail.com', 'alexsmith@outlook.com' ]
~~~
`;
const ex7 = `Write a function named as \`mostExpensive()\` which takes an \`object\` as an argument and returns 
the most expensive product name from the items array within the given object when invoked.

NOTE: If the items array is empty, then return undefined.

~~~js
mostExpensive({
  'userId': 12345,
  'items': []
}) -> undefined

mostExpensive({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Smart Watch',
      'price': 199.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Samsung',
        'model': 'Galaxy Watch 4',
        'color': 'Black'
      }
    }
  ]
}) -> 'Smart Watch'

mostExpensive({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Camera',
      'price': 699.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Canon',
        'resolution': '24MP',
        'type': 'DSLR'
      }
    },
    {
      'productId': 2,
      'productName': 'Drone',
      'price': 799.00,
      'quantity': 1,
      'specifications': {
        'brand': 'DJI',
        'camera': '4K',
        'flight time': '30 minutes'
      }
    },
    {
      'productId': 3,
      'productName': 'Smart Speaker',
      'price': 129.00,
      'quantity': 2,
      'specifications': {
        'brand': 'Amazon',
        'voice assistant': 'Alexa',
        'connectivity': 'Wi-Fi'
      }
    }
  ]
}) ->  'Drone'
~~~
`;
const ex8 = `Write a function named as \`mostAffordable()\` which takes an \`object\` as an argument and returns 
the most affordable product name from the items array within the given object when invoked.

NOTE: The total price should be calculated by multiplying the price of each item by its quantity and summing up all the individual prices. If the items array is empty, then return zero.

~~~js
mostAffordable({
  'userId': 12345,
  'items': []
}) -> undefined

mostAffordable({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Smart Watch',
      'price': 199.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Samsung',
        'model': 'Galaxy Watch 4',
        'color': 'Black'
      }
    }
  ]
}) -> 'Smart Watch'

mostAffordable({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Camera',
      'price': 699.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Canon',
        'resolution': '24MP',
        'type': 'DSLR'
      }
    },
    {
      'productId': 2,
      'productName': 'Drone',
      'price': 799.00,
      'quantity': 1,
      'specifications': {
        'brand': 'DJI',
        'camera': '4K',
        'flight time': '30 minutes'
      }
    },
    {
      'productId': 3,
      'productName': 'Smart Speaker',
      'price': 129.00,
      'quantity': 2,
      'specifications': {
        'brand': 'Amazon',
        'voice assistant': 'Alexa',
        'connectivity': 'Wi-Fi'
      }
    }
  ]
}) ->  'Smart Speaker'
~~~
`;
const ex9 = `Write a function named as \`calculateTotalPrice()\` which takes an \`object\` as an argument and 
returns the total price of the products  from the items array within the given object when invoked.

NOTE: If the items array is empty, then return undefined.

~~~js
calculateTotalPrice({
  'userId': 12345,
  'items': []
}) -> 0

calculateTotalPrice({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Smart Watch',
      'price': 199.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Samsung',
        'model': 'Galaxy Watch 4',
        'color': 'Black'
      }
    }
  ]
}) -> 199.00

calculateTotalPrice({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Tablet',
      'price': 299.00,
      'quantity': 2,
      'specifications': {
        'brand': 'Samsung',
        'screen': '10 inches',
        'processor': 'Exynos 850'
      }
    }
  ]
}) -> 598.00

calculateTotalPrice({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Camera',
      'price': 699.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Canon',
        'resolution': '24MP',
        'type': 'DSLR'
      }
    },
    {
      'productId': 2,
      'productName': 'Drone',
      'price': 799.00,
      'quantity': 1,
      'specifications': {
        'brand': 'DJI',
        'camera': '4K',
        'flight time': '30 minutes'
      }
    },
    {
      'productId': 3,
      'productName': 'Smart Speaker',
      'price': 129.00,
      'quantity': 2,
      'specifications': {
        'brand': 'Amazon',
        'voice assistant': 'Alexa',
        'connectivity': 'Wi-Fi'
      }
    }
  ]
}) -> 1726.00
~~~
`;
const ex10 = `Write a function named \`getProductNames()\` which takes an \`object\` as an argument and returns an array of product names from the items array within the given object when invoked.

NOTE: If the items array is empty, then return empty array.

~~~js
getProductNames({
  'userId': 12345,
  'items': []
}) -> []

getProductNames({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Smart Watch',
      'price': 199.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Samsung',
        'model': 'Galaxy Watch 4',
        'color': 'Black'
      }
    }
  ]
}) -> ['Smart Watch']

getProductNames({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Laptop',
      'price': 999.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Dell',
        'screen': '15.6 inches',
        'processor': 'Intel Core i7'
      }
    },
    {
      'productId': 2,
      'productName': 'Smartphone',
      'price': 499.00,
      'quantity': 2,
      'specifications': {
        'brand': 'Apple',
        'model': 'iPhone 12',
        'color': 'Space Gray'
      }
    }
  ]
}) -> ['Laptop', 'Smartphone']
~~~
`;
const ex11 = `Write a function named \`getProductBrands()\` which takes an \`object\` as an argument and returns an array of product brands from the items array within the given object when invoked.

NOTE: If the items array is empty, then return empty array.

~~~js
getProductBrands({
  'userId': 12345,
  'items': []
}) -> []

getProductBrands({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Smart Watch',
      'price': 199.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Samsung',
        'model': 'Galaxy Watch 4',
        'color': 'Black'
      }
    }
  ]
}) -> ['Samsung']

getProductBrands({
  'userId': 12345,
  'items': [
    {
      'productId': 1,
      'productName': 'Camera',
      'price': 699.00,
      'quantity': 1,
      'specifications': {
        'brand': 'Canon',
        'resolution': '24MP',
        'type': 'DSLR'
      }
    },
    {
      'productId': 2,
      'productName': 'Drone',
      'price': 799.00,
      'quantity': 1,
      'specifications': {
        'brand': 'DJI',
        'camera': '4K',
        'flight time': '30 minutes'
      }
    }
  ]
}) -> ['Canon', 'DJI']
~~~
`;
export const jsObjects = [
	{
		title: 'Users Older Than 30',
		content: ex1,
	},
	{
		title: 'Average Age of Users',
		content: ex2,
	},
	{
		title: 'Older User',
		content: ex3,
	},
	{
		title: 'Youngest User',
		content: ex4,
	},
	{
		title: 'Fetch Full Names',
		content: ex5,
	},
	{
		title: 'Fetch Email Addresses',
		content: ex6,
	},
	{
		title: 'Find The Most Expensive Product',
		content: ex7,
	},
	{
		title: 'Find The Most Affordable Product',
		content: ex8,
	},
	{
		title: 'Calculate The Total Price Of The Products',
		content: ex9,
	},
	{
		title: 'Get Product Names',
		content: ex10,
	},
	{
		title: 'Get Product Brands',
		content: ex11,
	},
];
