import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import styles from './Dropdown.module.css';
import './reset.css';
import Button from '../Partials/Buttons';

const productList = ['', 'iPhone 14 Pro Max', 'Apple Watch Series 8', 'MacBook Pro 13', 'iPad Pro 11'];
const colorList = ['', 'Yellow', 'Green', 'Silver', 'Black'];
const deliveryList = [
	{
		label: 'Delivery',
		value: 'Delivery',
	},
	{
		label: 'Pick up',
		value: 'Pick up',
	},
];

const Dropdown = () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [product, setProduct] = useState('');
	const [color, setColor] = useState('');
	const [delivery, setDelivery] = useState('');

	useEffect(() => {
		setIsSubmitted(false);
	}, [product, color, delivery]);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		if (!product || !color || !delivery) {
			setIsSubmitted(false);
			// alert('You must select all options');
			return;
		}
		setIsSubmitted(true);
	};

	return (
		<div>
			<form onSubmit={handleFormSubmit}>
				<div className={styles.row}>
					<p className='has-text-black pb-1'>Please select a product</p>
					<select
						onChange={(e) => {
							setProduct(e.target.value);
						}}
						required
						value={product}
						id='product_dropdown'
						className={`${styles.select} mb-2`}
					>
						{productList.map((product, index) =>
							product === '' ? (
								<option key={index} value={product} selected disabled>
									{product} Select...
								</option>
							) : (
								<option key={index} value={product}>
									{product}
								</option>
							)
						)}
					</select>
				</div>

				<div className={styles.row}>
					<p className='has-text-black pb-1'>Please select a color</p>
					<select
						onChange={(e) => {
							setColor(e.target.value);
						}}
						required
						value={color}
						id='color_dropdown'
						className={`${styles.select} mb-2`}
					>
						{colorList.map((color, index) =>
							color === '' ? (
								<option key={index} value={color} disabled>
									{color} Select...
								</option>
							) : (
								<option key={`${index}-${color}`} value={color}>
									{color}
								</option>
							)
						)}
					</select>
				</div>

				<div className={styles.row}>
					<p className='has-text-black pb-1'>Please select a shipment type</p>

					<div id='shipment_dropdown' className={`${styles.select} mb-2`}>
						<Select
							required
							options={deliveryList}
							onChange={(value) => {
								setDelivery(value[0].value);
							}}
						/>
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button id='submit' children='SUBMIT' />
				</div>
			</form>

			<h2 className='title is-4 pt-6 mb-2' id='result_id'>
				Result:
			</h2>

			{isSubmitted && (
				<div>
					<p id='result' style={{ color: 'var(--mainBlue)' }}>
						{`Your ${color} ${product} ${
							delivery === 'Delivery' ? 'will be delivered to you.' : 'is ready to be picked up.'
						}`}
					</p>
				</div>
			)}
		</div>
	);
};

export default Dropdown;
