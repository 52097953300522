import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './LoginForm.module.css';
import Button from '../Partials/Buttons';
import ForgotPassword from '../ForgotPassword/ForgotPassword';

const LoginForm = ({ embeddedInProject }) => {
	// const navigate = useNavigate();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isForgetPasswordActive, setIsForgetPasswordActive] = useState(false);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if (username !== 'TechGlobal') {
			setErrorMessage('Invalid Username entered!');
			return;
		} else if (password !== 'Test1234') {
			setErrorMessage('Invalid Password entered!');
			return;
		}
		// navigate('/logout'); if it requires new page
		setIsLoggedIn(true);
		// cleanup
		setUsername('');
		setPassword('');
		setErrorMessage('');
	};

	if (isLoggedIn) {
		return (
			<div>
				<h2 id='success_lgn' className={styles.success_lgn}>
					You are logged in
				</h2>
				<Button className={styles.logout} onClick={() => setIsLoggedIn(false)} id='logout' children='LOGOUT' />
			</div>
		);
	}
	return (
		<div className={`${styles.content} ${embeddedInProject ? 'm-auto' : ''}`}>
			<div className={styles.form}>
				{errorMessage && (
					<div>
						<p id='error_message' className={styles.error_message}>
							{errorMessage}
						</p>
					</div>
				)}
				<form onSubmit={handleFormSubmit}>
					<div className={`${styles.credentials} is-italic is-underlined mb-5`}>
						<p>
							Valid username: <span>TechGlobal</span>
						</p>
						<p>
							Valid password: <span>Test1234</span>
						</p>
					</div>
					<div>
						<label htmlFor='username'>Please enter your username</label>
						<input
							type='text'
							id='username'
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							className={styles.username}
						/>
					</div>
					<div>
						<label htmlFor='password'>Please enter your password</label>
						<input
							type='password'
							id='password'
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className={styles.password}
						/>
					</div>
					<div>
						<Button className={styles.login_btn} id='login_btn' children='LOGIN' type='submit' />
						{embeddedInProject ? (
							<Link onClick={() => setIsForgetPasswordActive(true)}>Forgot Password?</Link>
						) : (
							<Link
								to={'/frontend/forgot-password'}
								id='forgot-password'
								className={styles.forgot_password}
							>
								Forgot Password?
							</Link>
						)}
					</div>
				</form>
			</div>
			{isForgetPasswordActive && (
				<div className='modal is-active'>
					<div className='modal-background'></div>
					<div className={`modal-card ${styles.modal}`} style={{ borderRadius: '6px' }}>
						<header className='modal-card-head'>
							<h2 id='modal_title' className='modal-card-title'>
								Reset Password
							</h2>
							<button
								className='delete'
								aria-label='close'
								onClick={() => setIsForgetPasswordActive(false)}
							></button>
						</header>
						<section className='modal-card-body'>
							<ForgotPassword className='m-auto' />
						</section>
					</div>
				</div>
			)}
		</div>
	);
};

export default LoginForm;
