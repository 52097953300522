import Accordion from '../../../../components/Accordion/Accordion';
import { frontEndProject8TestCases as accordionData } from './frontEndProjectData';
import Project8Instructions from './Partials/Project8Instructions';
import { ShoppingCard } from './Partials/ShoppingCard';

const Accordions = () => accordionData?.map((testCase, index) => <Accordion dataToAccordion={testCase} key={index} />);

const Project8 = () => {
	return (
		<>
			<Project8Instructions />
			<Accordions />
			<ShoppingCard />
		</>
	);
};

export default Project8;
