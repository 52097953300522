import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import PageNotFound from '../pages/PageNotFound';
import HomePage from '../pages/homepage/HomePage';
import { frontEndRoute_data } from './frontEndRoute-data';
import { javaRoute_data } from './javaRoute-data';
import { Login } from '../pages/Login';
import { javaScriptRoute_data } from './javaScriptRoute-data';
import MockInnerPageController from '../app/mock-interviews/innerPages/MockInnerPageController';

// https://reactjs.org/docs/react-api.html#reactsuspense
const FrontEndTestingHomePage = React.lazy(() =>
	import('../app/frontend-testing/FrontEndTestingHomePage/FrontEndTestingHomePage')
);
const BackendTestingHomePage = React.lazy(() => import('../app/backend-testing/BackendTestingHomePage'));
const JavaPracticesHomePage = React.lazy(() =>
	import('../app/java-exercises/javaExercisesHomePage/JavaExercisesHomePage')
);
const MockInterviewsPage = React.lazy(() => import('../app/mock-interviews/MockInterviewsPage'));
const JavaScriptPracticesHomePage = React.lazy(() =>
	import('../app/javascript-exercises/javaScriptExercisesHomePage/JSExercisesHomePage')
);
const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: [
			{
				path: '/',
				element: <HomePage />,
			},
			{
				path: 'frontend',
				element: <FrontEndTestingHomePage />,
			},
			// Frontend Testing Inner page routes
			...frontEndRoute_data.map((rd) => {
				return {
					path: `frontend/${rd.url}`,
					element: rd.component(),
				};
			}),
			{
				path: 'backend',
				element: <BackendTestingHomePage />,
			},
			{
				path: 'java-exercises',
				element: <JavaPracticesHomePage />,
			},
			// Java Inner page routes
			...javaRoute_data.map((rd) => {
				return {
					path: `java-exercises/${rd.url}`,
					element: rd.component(),
				};
			}),
			// JavaScript Inner page routes
			...javaScriptRoute_data.map((rd) => {
				return {
					path: `js-exercises/${rd.url}`,
					element: rd.component,
				};
			}),
			// Mock pages start
			...['java', 'javascript'].map((pageType) => {
				return {
					path: `mock-interviews/${pageType}`,
					element: <MockInterviewsPage pageType={pageType} />,
				};
			}),
			...['1', '2', '3', '4', '5'].map((suffix) => {
				return {
					path: `mock-interviews/javascript/mock-interview-${suffix}`,
					element: <MockInnerPageController />,
				};
			}),
			// Mock pages end
			{
				path: 'js-exercises',
				element: <JavaScriptPracticesHomePage />,
			},
			{
				path: '/login',
				element: <Login />,
			},
			// {
			// 	path: '/editor',
			// 	element: <CodeEditor />,
			// },
			{
				path: '*',
				element: <PageNotFound />,
			},
		],
	},
]);

export default router;
