const ex1 = `Write a method named \`sayHello()\` which is \`void\` and prints \`Hello\` five times when invoked.
~~~java
sayHello() -> "Hello"
			  "Hello"
			  "Hello"
			  "Hello"
			  "Hello"
~~~
`;

const ex2 = `Write a method named \`countToSeven()\` which is \`void\` and prints numbers from 1 to 7 (both inclusive) when invoked.
~~~java
countToSeven() -> 1
				  2
				  3
				  4
				  5
				  6
				  7
~~~`;
const ex3 = `Write a method named \`countThreeToZero()\` which is \`void\` and prints numbers backward from 3 to 0 (both inclusive)
when invoked.
~~~java
countThreeToZero() -> 3
					  2
					  1
					  0
~~~`;
const ex4 = `Write a method named \`doubleWord()\` which is \`void\` and takes a \`string\` word as an argument and prints the given
word twice when invoked.
~~~java
doubleWorld("Tech")   -> "Tech"
					     "Tech"

doubleWorld("Global") -> "Global"
						 "Global"
~~~`;

const ex5 = `Write a method named \`printChars()\` which is \`void\` and takes a \`String\` word as an argument and prints word
characters one by one when invoked.

**NOTE:** Assume you will not be given an empty array.

~~~java
printChars("tech")  ->  "t" 
					    "e"
					    "c"
					    "h"
						
printChars("global") -> "g"
					    "l"
					    "o"
					    "b"
					    "a"
					    "l"
~~~`;

const ex6 = `Write a method named \`printCharsReversed()\` which is \`void\` and takes a \`String\` word as an argument and prints word
characters one by one when invoked.

**NOTE:** Assume you will not be given an empty array.

~~~java
printCharsReversed("tech")   -> "h"
							    "c"
							    "e"
							    "t"
						
printCharsReversed("global") -> "l"
					   		    "a"
					   		    "b"
					   		    "o"
					   		    "l"
					   		    "g"
~~~`;

const ex7 = `Write a method named \`sum()\` which takes a positive \`int\` argument and returns the sum of the numbers
from 1 to the given number (both inclusive) when invoked.
~~~java
sum(3) -> 6
sum(5) -> 15
~~~`;

const ex8 = `Write a method named \`sumEvens()\` which takes a positive \`int\` argument and returns the sum of the even numbers
from 1 to the given number (both inclusive) when invoked.
~~~java
sumEvens(3) -> 2
sumEvens(5) -> 6
~~~`;

const ex9 = `Write a method named \`sumOdds()\` which takes a positive \`int\` argument and returns the sum of the odd numbers
from 1 to the given number (both inclusive) when invoked.
~~~java
sumOdds(3) -> 4
sumOdds(5) -> 9
~~~`;

const ex10 = `Write a method named \`countEvens()\` which takes a positive \`int\` argument and returns the count of the even
numbers from 1 to the given number (both inclusive) when invoked.
~~~java
countEvens(3) -> 1
countEvens(5) -> 2
~~~`;

const ex11 = `Write a method named \`countOdds()\` which takes a positive \`int\` argument and returns the count of the odd
numbers from 1 to the given number (both inclusive) when invoked.
~~~java
countOdds(3) -> 1
countOdds(5) -> 2
~~~`;

const ex12 = `Write a method named \`countThreeDividend()\` which takes a positive \`int\` argument and returns the count of the
numbers divisible by 3 from 1 to the given number (both inclusive) when invoked.
~~~java
countThreeDividend(3) -> 1
countThreeDividend(8) -> 2
~~~`;

const ex13 = `Write a method named \`sum()\` which takes two positive \`int\` arguments and returns the sum of the numbers from the start
to end number (both inclusive) when invoked.
~~~java
sum(1, 3) -> 6
sum(5, 2) -> 14
~~~`;

const ex14 = `Write a method named \`product()\` which takes two \`int\` arguments and returns the product of the numbers from the start
to end number (both inclusive) when invoked.
~~~java
product(1, 3)  -> 6
product(5, 2)  -> 120
product(-1, 2) -> 0
~~~`;

const ex15 = `Write a method named \`countFiveDividend()\` which takes two \`int\` arguments and returns the count of the numbers
divisible by 5 from the start to end number (both inclusive) when invoked.
~~~java
countFiveDividend(2, 4)  -> 0
countFiveDividend(7, 4)  -> 1
countFiveDividend(8, 15) -> 4
~~~`;

const ex16 = `Write a method named \`countA()\` which takes a \`String\` arguments and returns the count of the uppercase or lowercase
letter A in the \`String\` when invoked.

**NOTE:** Assume you will not be given an empty word.
~~~java
countA("Tech")    -> 0			
countA("Global")  -> 1
countA("America") -> 2
~~~`;

export const loopsExercisesData = [
	{
		title: 'Five Times Hello',
		content: ex1,
	},
	{
		title: 'Numbers 1 to 7',
		content: ex2,
	},
	{
		title: 'Numbers 3 to 0',
		content: ex3,
	},
	{
		title: 'Double The Word 2',
		content: ex4,
	},
	{
		title: 'String Characters',
		content: ex5,
	},
	{
		title: 'String Characters Reversed',
		content: ex6,
	},
	{
		title: 'Sum Numbers From 1 to Given Number',
		content: ex7,
	},
	{
		title: 'Sum Even Numbers From 1 to Given Number',
		content: ex8,
	},
	{
		title: 'Sum Odd Numbers From 1 to Given Number',
		content: ex9,
	},
	{
		title: 'Count Evens from 1 to Given Number',
		content: ex10,
	},
	{
		title: 'Count Odds from 1 to Given Number',
		content: ex11,
	},
	{
		title: 'Count Numbers Divisible by 3 from 1 to Given Number',
		content: ex12,
	},
	{
		title: 'Sum of Numbers From Start to End',
		content: ex13,
	},
	{
		title: 'Product of Numbers From Start to End',
		content: ex14,
	},
	{
		title: 'Count Numbers Divisible by 5 From Start to End',
		content: ex15,
	},
	{
		title: 'Count String Character A',
		content: ex16,
	},
];
