export const frontEndProject1TestCases = [
	{
		title: 'Test Case 01 - Validate the Contact Us information',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate the heading is “Contact Us”
3. Validate the address is “2800 S River Rd Suite 310, Des Plaines, IL 60018”
4. Validate the email is “info@techglobalschool.com"
5. Validate the phone number is “(224) 580-2150”`,
	},
	{
		title: 'Test Case 02 - Validate the Full name input box',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate that the Full name input box is displayed
3. Validate that the Full name input box is required
4. Validate that the label of the Full name input box is “Full name *”
5. Validate that the placeholder of the Full name input box is “Enter your full name”
`,
	},
	{
		title: 'Test Case 03 - Validate the Gender radio button',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate the label is “Gender *”
3. Validate that the Gender is required
4. Validate the options are “Female”, “Male” and “Prefer not to disclose”
5. Validate the options are clickable and not selected
6. Click on the “Male” option and validate it is selected while the others are not selected
7. Click on the “Female” option and validate it is selected while the others are not selected
`,
	},
	{
		title: 'Test Case 04 - Validate the Address input box',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate that the Address input box is displayed
3. Validate that the Address input box is not required
4. Validate that the label of the Address input box is “Address”
5. Validate that the placeholder of the Address input box is “Enter your address*”
`,
	},
	{
		title: 'Test Case 05 - Validate the Email input box',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate that the Email input box is displayed
3. Validate that the Email input box is required
4. Validate that the label of the Email input box is “Email *”
5. Validate that the placeholder of the Email input box is “Enter your email”
`,
	},
	{
		title: 'Test Case 06 - Validate the Phone input box',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate that the Phone input box is displayed
3. Validate that the Phone input box is not required
4. Validate that the label of the Phone input box is “Phone”
5. Validate that the placeholder of the Address input box is “Enter your phone number”
`,
	},
	{
		title: 'Test Case 07 - Validate the Message text area',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate that the Message text area is displayed
3. Validate that the Message text area is not required
4. Validate that the label of the Message text area is “Message”
5. Validate that the placeholder of the Message text area is “Type your message here…”
`,
	},
	{
		title: 'Test Case 08 - Validate the Consent checkbox',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate the label is “I give my consent to be contacted.”
3. Validate that the Consent checkbox is required
4. Validate that the Consent checkbox is clickable
5. Click on the “I give my consent to be contacted.” checkbox and validate it is selected
6. Click on the “I give my consent to be contacted.” checkbox again and validate it is not selected
`,
	},
	{
		title: 'Test Case 09 - Validate the SUBMIT button',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Validate the “SUBMIT” button is displayed
3. Validate the “SUBMIT” button is clickable
4. Validate that the button text is “SUBMIT”
`,
	},
	{
		title: 'Test Case 10 - Validate the form submission',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-1
2. Enter a first name
3. Select a gender
4. Enter an address
5. Enter an email
6. Enter a phone number
7. Enter a message
8. Select the “I give my consent to be contacted.” checkbox
9. Click on the “SUBMIT” button
10. Validate the form message “Thanks for submitting!” is displayed under the “SUBMIT” button
`,
	},
];

export const frontEndProject2TestCases = [
	{
		title: 'Test Case 01 - Validate the login form',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Validate that the username input box is displayed
3. Validate that the username input box is not required
4. Validate that the label of the username input box is “Please enter your username”
5. Validate that the password input box is displayed
6. Validate that the password input box is not required
7. Validate that the label of the password input box is “Please enter your password”
8. Validate the “LOGIN” button is displayed
9. Validate the “LOGIN” button is clickable
10. Validate that the button text is “LOGIN”
11. Validate the “Forgot Password?” link is displayed
12. Validate that the “Forgot Password?” link is clickable
13. Validate that the link text is “Forgot Password?”
`,
	},
	{
		title: 'Test Case 02 - Validate the valid login',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as “TechGlobal”
3. Enter the password as “Test1234”
4. Click on the “LOGIN” button
5. Validate the success message is displayed as “You are logged in”
6. Validate the logout button displayed with the text “LOGOUT”
`,
	},
	{
		title: 'Test Case 03 - Validate the logout',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as “TechGlobal”
3. Enter the password as “Test1234”
4. Click on the “LOGIN” button
5. Click on the “LOGOUT” button
6. Validate that the login form is displayed
`,
	},
	{
		title: 'Test Case 04 - Validate the Forgot Password? Link and Reset Password modal',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Click on the “Forgot Password?” link
3. Validate that the modal heading “Reset Password” is displayed
4. Validate that the close button is displayed
5. Validate that the email input box is displayed
6. Validate that the label of the email input box is “Enter your email address and we'll send you a link to reset your password.”
7. Validate the “SUBMIT” button is displayed
8. Validate the “SUBMIT” button is clickable
9. Validate that the button text is “SUBMIT”
`,
	},
	{
		title: 'Test Case 05 - Validate the Reset Password modal close button',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Click on the “Forgot Password?” link
3. Validate that the “Reset Password” modal is displayed 
4. Click on the close button
5. Validate that the “Reset Password” modal is closed 
`,
	},
	{
		title: 'Test Case 06 - Validate the Reset Password form submission',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Click on the “Forgot Password?” link
3. Enter an email
4. Click on the “SUBMIT” button
5. Validate the form message “A link to reset your password has been sent to your email address.” is displayed under the “SUBMIT” button
`,
	},
	{
		title: 'Test Case 07 - Validate the invalid login with the empty credentials',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Leave username empty
3. Leave password empty
4. Click on the “LOGIN” button
5. Validate the failure message is displayed as “Invalid Username entered!” above the form
`,
	},
	{
		title: 'Test Case 08 - Validate the invalid login with the wrong username',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as “John”
3. Enter the password as “Test1234”
4. Click on the “LOGIN” button
5. Validate the failure message is displayed as “Invalid Username entered!” above the form
`,
	},
	{
		title: 'Test Case 09 - Validate the invalid login with the wrong password',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as “TechGlobal”
3. Enter the password as “1234”
4. Click on the “LOGIN” button
5. Validate the failure message is displayed as “Invalid Password entered!” above the form
`,
	},
	{
		title: 'Test Case 10 - Validate the invalid login with the wrong username and password',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-2
2. Enter the username as “John”
3. Enter the password as “1234”
4. Click on the “LOGIN” button
5. Validate the failure message is displayed as “Invalid Username entered!” above the form
`,
	},
];

export const frontEndProject3TestCases = [
	{
		title: 'Test Case 01 - Validate the default Book your trip form',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-3
2. Validate that the “One way” radio button is displayed enabled and selected by default
3. Validate that the “Round trip” radio button is displayed enabled and not selected by default
4. Validate that the “Cabin Class” label and dropdown are displayed
5. Validate that the “From” label and dropdown are displayed
6. Validate that the “To” label and dropdown are displayed
7. Validate that the “Depart” label and date picker is displayed
8. Validate that the “Return” label and date picker is displayed and disabled
9. Validate that the “Number of passengers” label and dropdown are displayed and 1 is the default
10. Validate that the “Passenger 1” category label and dropdown are displayed and “Adult (16-64)” is the default
11. Validate that the “BOOK” button is displayed and enabled
`,
	},
	{
		title: 'Test Case 02 - Validate the Book your trip form when Round trip is selected',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-3
2. Click on the “Round trip” radio button and validate it is selected
3. Validate that the “One way” radio button is not selected
4. Validate that the “Cabin Class” label and dropdown are displayed
5. Validate that the “From” label and dropdown are displayed
6. Validate that the “To” label and dropdown are displayed
7. Validate that the “Depart” label and date picker is displayed
8. Validate that the “Return” label and date picker is displayed
9. Validate that the “Number of passengers” label and dropdown are displayed and 1 is the default
10. Validate that the “Passenger 1” label and dropdown are displayed and “Adult (16-64)” is the default
11. Validate that the “BOOK” button is displayed and enabled
`,
	},
	{
		title: 'Test Case 03 - Validate the booking for 1 passenger and one way',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-3
2. Select the “One way” radio button  
3. Select “Business” for the “Cabin Class” dropdown  
4. Select “Illinois” for the “From” dropdown  
5. Select “Florida” for the “To” dropdown  
6. Select the next week for the ”Depart”  
7. Select “1” for the “Number of passengers” dropdown  
8. Select “Senior (65+)” for the Passenger 1 dropdown  
9. Click on the “BOOK” button  
10. Validate the booking information displayed below  
### DEPART  
IL to FL  
{dynamic date}  
Number of passengers: 1  
Passenger 1: Senior (65+)  
Cabin Class: Business  
`,
	},
	{
		title: 'Test Case 04 - Validate the booking for 1 passenger and round trip',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-3
2. Select the “Round trip” radio button  
3. Select “First” for the “Cabin Class” dropdown  
4. Select “California” for the “From” dropdown
5. Select “Illinois” for the “To” dropdown  
6. Select the next week for the ”Depart”  
7. Select the next month for the “Return”  
8. Select “1” for the “Number of passengers” dropdown  
9. Select “Adult (16-64)” for the Passenger 1 dropdown  
10. Click on the “BOOK” button  
11. Validate the booking information displayed below  
### DEPART
CA to IL  
{dynamic date}  
Number of passengers: 1  
Passenger 1: Adult (16-64)  
Cabin Class: First
<br/><br/>
### RETURN  
IL to CA  
{dynamic date}  
`,
	},
	{
		title: 'Test Case 05 - Validate the booking for 2 passengers and one way',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-3
2. Select the “One way” radio button
3. Select “Premium Economy” for the “Cabin Class” dropdown
4. Select “New York” for the “From” dropdown
5. Select “Texas” for the “To” dropdown
6. Select the next day for the ”Depart”
7. Select “2” for the “Number of passengers” dropdown
8. Select “Adult (16-64)” for the Passenger 1 dropdown
9. Select “Child (2-11)” for the Passenger 2 dropdown
10. Click on the “BOOK” button
11. Validate the booking information displayed below
### DEPART
NY to TX  
{dynamic date}  
Number of passengers: 2  
Passenger 1: Adult (16-64)  
Passenger 2: Child (2-11)  
Cabin Class: Premium Economy
`,
	},
];

export const frontEndProject4TestCases = [
	{
		title: 'Test Case 01 - Validate the default content of the inventory table',
		content: `\`Given\` the user is on https://techglobal-training.com/frontend/project-4  
\`Then\` the user should see the “Inventory” heading  
\`And\` the user should see the table with the headers below  
~~~
| Quantity | Product | Price $ | Total $ |  
~~~
\`And\` the user should see the table with the rows below
~~~
| 1 | iPhone  | 1,000 | 1,000 |  
| 3 | Airpods | 100   | 300   |  
| 2 | iPad    | 500   | 1,000 |  
~~~
\`And\` the user should see the “ADD PRODUCT” button is enabled  
\`And\` the user should see the “Total = $2,300” text displayed
`,
	},
	{
		title: 'Test Case 02 - Validate the Add New Product modal',
		content: `\`Given\` the user is on https://techglobal-training.com/frontend/project-4  
\`When\` the user clicks on the “ADD PRODUCT” button  
\`Then\` the user should see the “Add New Product” modal with its heading  
\`And\` the user should see the “X” button is enabled  
\`And\` the user should see the “Please select the quantity” label  
\`And\` the user should see the “Quantity” input box is enabled  
\`And\` the user should see the “Please enter the name of the product” label  
\`And\` the user should see the “Product” input box is enabled  
\`And\` the user should see the “Please enter the price of the product” label  
\`And\` the user should see the “Price” input box is enabled  
\`And\` the user should see the “SUBMIT” button is enabled
`,
	},
	{
		title: 'Test Case 03 - Validate the Add New Product modal X button',
		content: `\`Given\` the user is on https://techglobal-training.com/frontend/project-4  
\`When\` the user clicks on the “ADD PRODUCT” button  
\`Then\` the user should see the “Add New Product” modal with its heading  
\`When\` the user clicks on the “X” button  
\`Then\` the user should not see the “Add New Product” modal
`,
	},
	{
		title: 'Test Case 04 - Validate the new product added',
		content: `\`Given\` the user is on https://techglobal-training.com/frontend/project-4  
\`When\` the user clicks on the “ADD PRODUCT” button  
\`And\` the user enters the quantity as “2”  
\`And\` the user enters the product as “Mouse”  
\`And\` the user enters the price as “100”  
\`And\` the user clicks on the “SUBMIT” button  
\`Then\` the user should see the table with the new row below
~~~
| 2 | Mouse | 100 | 200 |
~~~
\`And\` the user should see the “Total = $2,500” text displayed
`,
	},
];

export const frontEndProject5TestCases = [
	{
		title: 'Test Case 01 - Validate the main content',
		content: `\`Given\` the user is on https://techglobal-training.com/frontend/project-5  
\`Then\` the user should see the “Pagination” heading  
\`And\` the user should see the “World City Populations 2022” heading  
\`And\` the user should see the “What are the most populated cities in the world? Here is a list of the top five most populated cities in the world:” paragraph
`,
	},
	{
		title: 'Test Case 02 - Validate the Pagination Next/Previous buttons',
		content: `\`Given\` the user is on https://techglobal-training.com/frontend/project-5  
\`Then\` the user should see the “Previous” button is disabled  
\`And\` the user should see the “Next” button is enabled  
\`When\` the user clicks on the “Next” button  
\`Then\` the user should see the “Previous” button is enabled  
\`When\` the user clicks on the “Next” button till it becomes disabled  
\`Then\` the user should see the “Previous” button is enabled  
\`And\` the user should see the “Next” button is disabled  
`,
	},
	{
		title: 'Test Case 03 - Validate the Pagination Cities content',
		content: `\`Given\` the user is on https://techglobal-training.com/frontend/project-5  
\`Then\` the user should see “Tokyo” City with the info below and an image  
~~~
|City: Tokyo| Country: Japan| Population: 37,435,191|
~~~
\`When\` the user clicks on the “Next” button  
\`Then\` the user should see “Delhi” City with the info below and an image  
~~~
|City: Delhi| Country: India| Population: 29,399,141|
~~~  
\`When\` the user clicks on the “Next” button  
\`Then\` the user should see “Shanghai“ City with the info below and an image
~~~
|City: Shanghai| Country: China| Population: 26,317,104|
~~~  
\`When\` the user clicks on the “Next” button  
\`Then\` the user should see “Sao Paulo“ City with the info below and an image  
~~~
|City: Sao Paulo| Country: Brasil| Population: 21,846,507|
~~~  
\`When\` the user clicks on the “Next” button  
\`Then\` the user should see “Mexico City” City with the info below and an image  
~~~
|City: Mexico City| Country: Mexico| Population: 21,671,908|
~~~  
`,
	},
];

export const frontEndProject6TestCases = [
	{
		title: 'Test Case 01 - Todo-App Modal Verification',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-6.
2.	Confirm that the todo-app modal is visible with the title “My Tasks.”
3.	Validate that the New todo input field is enabled for text entry.
4.	Validate ADD button is enabled.
5.	Validate Search field is enabled.
6.	Validate that the task list is empty, displaying the message “No tasks found!”
`,
	},
	{
		title: 'Test Case 02 - Single Task Addition and Removal',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-6
2.	Enter a new task in the todo input field and add it to the list.
3.	Validate that the new task appears in the task list.
4.	Validate that the number of tasks in the list is exactly one.
5.	Mark the task as completed by clicking on it.
6.	Validate item is marked as completed.
7.	Click on the button to remove the item you have added.
8.	Remove the completed task by clicking the designated removal button.
9.	Validate that the task list is empty, displaying the message “No tasks found!”.
`,
	},
	{
		title: 'Test Case 03 - Multiple Task Operations',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-6
2.	Enter and add 5 to-do items individually.
3.	Validate that all added items match the items displayed on the list.
4.	Mark all the tasks as completed by clicking on them.
5.	Click on the “Remove completed tasks!” button to clear them.
6.	Validate that the task list is empty, displaying the message “No tasks found!”.
`,
	},

	{
		title: 'Test Case 04 - Search and Filter Functionality in todo App',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-6
2.	Enter and add 5 to-do items individually.
3.	Validate that all added items match the items displayed on the list.
4.	Enter the complete name of the previously added to-do item into the search bar.
5.	Validate that the list is now filtered to show only the item you searched for.
6.	Validate that the number of tasks visible in the list is exactly one.		
`,
	},
	{
		title: 'Test Case 05 - Task Validation and Error Handling',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-6
2.	Attempt to add an empty task to the to-do list.
3.	Validate that the task list is empty, displaying the message “No task found!”.
4.	Enter an item name exceeding 30 characters into the list.
5.	Validate error message appears and says “Error: Todo cannot be more than 30 characters!”.
6.	Add a valid item name to the list.
7.	Validate that the active task count is exactly one.
8.	Try to enter an item with the same name already present on the list.
9.	Validate that an error message is displayed, indicating “Error: You already have {ITEM} in your todo list.”.	
`,
	},
];

export const frontEndProject8TestCases = [
	{
		title: 'Test Case 01 - Available Courses Section Validation',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-8 
2.  Validate the heading is “Available Courses”
3.  Validate that there are 3 courses displayed
4.  Validate that each course has an image, name, TechGlobal School tag, and a price of more than zero
5.  Validate the first 2 courses have discount tags
6.  Validate that there is an “Add to Cart” button under each course which is displayed, enabled, and has the text “Add to Cart”
`,
	},
	{
		title: 'Test Case 02 - Cart Section Validation',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-8 
2.  Validate the heading is “Items Added to Cart”
3.  Validate that the cart is empty by default
4.  Validate that the total price is zero “$0” by default
5.  Validate that there is a “Place Order” button is displayed, disabled, and has the text “Place Order”
`,
	},
	{
		title: 'Test Case 03 - Add a Course to the Cart and Validate',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-8 
2.  Click on the “Add to Cart” button for one of the courses 
3.  Validate that the course is displayed in the cart with its image, name, and discount amount if available
4.  Validate that the course price is added to the total price excluding the discount amount
5.  Click on the “Place Order” button 
6.  Validate a success message is displayed with the text “Your order has been placed.”
7.  Validate that the cart is empty
`,
	},
	{
		title: 'Test Case 04 - Add Two Courses to the Cart and Validate',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-8 
2.  Click on the “Add to Cart” button for one of the courses 
3. Click on the “Add to Cart” button for another course 
4.  Validate that the courses are displayed in the cart with their image, name, and discount amount if available
5.  Validate that the course prices are added to the total price excluding the discount amounts
6.  Click on the “Place Order” button 
7.  Validate a success message is displayed with the text “Your order has been placed.”
8.  Validate that the cart is empty
`,
	},
	{
		title: 'Test Case 05 - Add All Three Courses to the Cart and Validate',
		content: `1. Navigate to https://techglobal-training.com/frontend/project-8 
2.  Click on the “Add to Cart” button for all three courses 
3.  Validate that the courses are displayed in the cart with their image, name, and discount amount if available
4.  Validate that the course prices are added to the total price excluding the discount amounts
5.  Click on the “Place Order” button 
6.  Validate a success message is displayed with the text “Your order has been placed.”
7.  Validate that the cart is empty
`,
	},
];
