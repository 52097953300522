import styles from './Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBars, faTimes, faDisplay, faGear } from '@fortawesome/free-solid-svg-icons';
import logo from './images/TechGlobal-LOGO-transparent.png';
import { Image } from 'react-bulma-components';
import { Link, NavLink } from 'react-router-dom';
import testingsJSON from './testings.json';
import exercisesJSON from './exercises.json';
import { useState, useEffect } from 'react';
import { faJs, faJava } from '@fortawesome/free-brands-svg-icons';

// active link styles
const style = {
	dropdown: {
		textDecoration: 'underline',
	},
	tab: {
		color: 'var(--mainBlue)',
	},
};

const DropdownItem = ({ itemTitle, routeTo, id, setIsMenuVisible, isMobile }) => {
	return (
		<div className={isMobile ? styles.dropdownItemNotFlex : styles.dropdownItemFlex}>
			{!isMobile && (
				<FontAwesomeIcon
					icon={
						itemTitle.includes('JS')
							? faJs
							: itemTitle.includes('Java')
							? faJava
							: itemTitle.includes('Frontend')
							? faDisplay
							: faGear
					}
					size='2x'
					color='var(--darkBlue)'
				/>
			)}
			<NavLink
				to={routeTo}
				id={id}
				className={`dropdown-item py-4 px-0`}
				style={({ isActive }) => (isActive ? style.dropdown : undefined)}
				onClick={() => setIsMenuVisible(false)}
			>
				{itemTitle}
			</NavLink>
		</div>
	);
};

// Desktop navbar
const Navbar = () => {
	const [isMenuVisible, setIsMenuVisible] = useState(false);

	return (
		<nav className={`${styles.navbar} has-shadow is-white`}>
			<div className='navbar-brand'>
				<NavLink
					onClick={() => setIsMenuVisible(!isMenuVisible)}
					style={{
						backgroundColor: '#fff',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<FontAwesomeIcon style={{ fontSize: '25px', color: '#000' }} icon={faBars} />
				</NavLink>
			</div>
			<div
				className={`${styles.navbar_menu} navbar-menu ${isMenuVisible ? 'is-active' : ''} has-text-centered`}
				id='nav-links'
			>
				<div>
					<div className={`${styles.burger_items}`}>
						<div>
							<NavLink onClick={() => setIsMenuVisible(!isMenuVisible)}>
								<Image src={logo} alt='techglobal-logo' className={styles.logo} id='logo' />
							</NavLink>
						</div>
						{/* close icon */}
						<div>
							<NavLink
								onClick={() => setIsMenuVisible(!isMenuVisible)}
								style={{
									backgroundColor: 'transparent',
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<FontAwesomeIcon
									style={{
										fontSize: '25px',
										color: '#000',
									}}
									icon={faTimes}
								/>
							</NavLink>
						</div>
					</div>

					<div className={styles.mobileMenus}>
						{testingsJSON.map((list, idx) => (
							<DropdownItem
								isMobile={true}
								key={idx}
								itemTitle={list.itemTitle}
								routeTo={list.routeTo}
								id={list.id}
								setIsMenuVisible={setIsMenuVisible}
							/>
						))}
						{exercisesJSON.map((list, idx) => (
							<DropdownItem
								isMobile={true}
								key={idx}
								itemTitle={list.itemTitle}
								routeTo={list.routeTo}
								id={list.id}
								setIsMenuVisible={setIsMenuVisible}
							/>
						))}
						<button>
							<Link to={'/login'} onClick={() => setIsMenuVisible(false)}>
								Mock Interviews
							</Link>
						</button>
					</div>
				</div>
			</div>
		</nav>
	);
};

// TODO: Remove content on mobile hover

const Header = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleWindowResize = () => setWindowWidth(window.innerWidth);
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	// const location = useLocation();
	// const pathname = location?.pathname;
	// const isBlueBgPage = pathname === '/backend';

	// const dynamicStyle = isBlueBgPage
	// 	? { backgroundColor: 'var(--mainBlue)' }
	// 	: { backgroundColor: 'var(--mainWhite)' };
	return (
		<div className={styles.header}>
			<div className={styles.headerContainer}>
				<NavLink to='/'>
					<Image src={logo} alt='techglobal-logo' className={styles.logo} id='logo' />
				</NavLink>
				{windowWidth > 1024 ? (
					<div className={styles.menus}>
						<CustomDropDown dropDownList={testingsJSON} title='Testing' />
						<CustomDropDown dropDownList={exercisesJSON} title='Exercises' />
						<div>
							<Link to='/login'>Mock Interviews</Link>
						</div>
					</div>
				) : (
					<Navbar />
				)}
			</div>
		</div>
	);
};

const CustomDropDown = ({ dropDownList, title }) => {
	return (
		<div className={`${styles.dropdown} dropdown is-hoverable`} id='dropdown-container'>
			<div className='dropdown-trigger'>
				<div className={`button ${styles.button}`} id={`dropdown-${title.toLowerCase()}`}>
					<span>{title}</span>
					<FontAwesomeIcon icon={faAngleDown} pull='right' />
				</div>
			</div>
			<div className='dropdown-menu' id='dropdown-menu' role='menu'>
				<div className={`dropdown-content ${styles.dropdownContent}`}>
					{dropDownList.map((list, idx) => (
						<DropdownItem
							key={idx}
							itemTitle={list.itemTitle}
							routeTo={list.routeTo}
							id={list.id}
							isMobile={false}
						/>
					))}
				</div>
			</div>
		</div>
	);
};
export default Header;
