import React, { useState } from 'react';
import Button from '../Partials/Buttons';

export const FileUpload = () => {
	const supportedFileTypes = ['pdf', 'txt', 'pptx', 'docx', 'png', 'jpeg', 'jpg'];
	const [selectedFileName, setSelectedFileName] = useState('');
	const [displayFileName, setDisplayFileName] = useState(false);

	const submitFile = () => setDisplayFileName(true);
	const handleFileSelect = (e) => {
		const selectedFile = e.target.files[0];
		setDisplayFileName(false);
		setSelectedFileName(selectedFile?.name);
	};
	return (
		<form action=''>
			<p className='mb-2'>
				Please select a file to upload. Acceptable files are .pdf .txt .pptx .docx .png .jpeg .jpg
			</p>
			<input id='file_upload' type='file' accept='*' name='fileSelect' onChange={handleFileSelect} />

			{!displayFileName && (
				<div className='mt-4'>
					<Button id='file_submit' style={{ width: '100px' }} onClick={submitFile} children='UPLOAD' />
				</div>
			)}

			<h2 className='title pt-6 mb-2' id='result_id'>
				Result:
			</h2>
			{displayFileName && (
				<p style={{ color: 'var(--mainBlue)' }} id='result'>
					{supportedFileTypes.some((fileType) => selectedFileName?.endsWith(fileType)) ? (
						<div class='notification is-success'>
							You uploaded <strong>{selectedFileName}</strong>
						</div>
					) : !selectedFileName ? (
						<div class='notification is-danger'>Please select a file!</div>
					) : (
						<div class='notification is-danger'>
							This file type is not allowed. You can try one of the{' '}
							<strong>.pdf .txt .pptx .docx .png .jpeg .jpg</strong> file types to upload.
						</div>
					)}
				</p>
			)}
		</form>
	);
};

export default FileUpload;
