import { DynamicTables } from '../index.js';
import Accordion from '../../../../components/Accordion/Accordion';
import { frontEndProject4TestCases as data } from './frontEndProjectData';

const Project4 = () => {
	return (
		<div>
			<div className='content'>
				<p>
					This project aims to provide students with a comprehensive understanding of frontend automation,
					focusing specifically on dynamic tables and the implementation of best practices for BDD components.
				</p>
				<ul>
					<li>
						By participating in this project, students will gain valuable hands-on experience in creating
						feature files and implementing their corresponding steps.
					</li>
					<li>
						Moreover, they will have the opportunity to develop proficiency in testing the HTML tables and
						applying the BDD approach effectively.
					</li>
				</ul>
				<p>
					Students are expected to automate all the provided test cases and submit their work to the
					TechGlobal Instructor Team. This project will prove to be both a fulfilling and educational
					opportunity for students to broaden their knowledge and skills!
				</p>
			</div>
			{data.map((testCase, idx) => (
				<Accordion dataToAccordion={testCase} key={idx} />
			))}
			<br />
			<div className='has-background-white-ter pt-5 pr-6 pl-6 pb-2'>
				<div className='mb-2'>
					<h1 className='is-size-3' style={{ color: 'var(--mainBlue)' }}>
						Inventory
					</h1>
				</div>
				<DynamicTables />
			</div>
		</div>
	);
};

export default Project4;
