import styles from './HtmlElements.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../Partials/Buttons';
import { useState } from 'react';
import testingImage from './automation_testing_image.png';
import { useRef } from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

// ShadowDOM
function ShadowRoot({ children }) {
	const [shadowRoot, setShadowRoot] = useState();
	const rootRef = useRef(null);

	useEffect(() => {
		if (rootRef.current.shadowRoot === null) {
			setShadowRoot(rootRef.current.attachShadow({ mode: 'open' }));
		}
	}, []);

	return <div ref={rootRef}>{shadowRoot ? createPortal(children, shadowRoot) : null}</div>;
}

export default function HtmlElements() {
	const [showClicked, setShowClicked] = useState(false);
	const [date1, setDate1] = useState();
	const [date2, setDate2] = useState(new Date());
	const [blurText, setBlurText] = useState('');
	const [emailValue, setEmailValue] = useState('');

	const handleChange = (event) => {
		if (event.type === 'focus') {
			event.target.style.outline = '2px solid green';
		} else {
			event.target.style.outline = '';
			setBlurText('John');
		}
	};
	return (
		<div className={`${styles.mainContainer}`}>
			<div data-identifier='Paragraphs'>
				<h3>Paragraphs</h3>
				<p id='hello_paragraph'>Hello World!</p>
				<p id='testing_paragraph'>I like automation testing!</p>
			</div>
			<div data-identifier='Headings'>
				<h3>Headings</h3>
				<h4 id='languages_heading'>Programming Languages</h4>
				<h4 id='tools_heading'>Automation Tools</h4>
			</div>
			<div className={`${styles.buttonsContainer}`} data-identifier='Buttons'>
				<h3>Buttons</h3>
				<div>
					<Button
						id='register_button'
						children='Register'
						onClick={(e) => setShowClicked(e.target.innerText)}
						className={`is-inline ${styles.btn}`}
					/>
					<Button
						id='signin_button'
						children='Sign in'
						className={`is-inline ${styles.btn}`}
						onClick={(e) => setShowClicked(e.target.innerText)}
					/>
					{showClicked && <span className='mt-1 is-block'>{`You clicked on “${showClicked}”`}</span>}
				</div>
			</div>
			<div data-identifier='Ordered Lists'>
				<h3>Ordered Lists</h3>
				<ol id='ordered_list' className={styles.orderedList}>
					<li id='ordered_list_item1'>Cypress</li>
					<li id='ordered_list_item2'>Playwright</li>
					<li id='ordered_list_item3'>Selenium Webdriver</li>
				</ol>
			</div>
			<div data-identifier='Unordered Lists'>
				<h3>Unordered Lists</h3>
				<ul id='unordered_list'>
					<li id='unordered_list_item1'>JavaScript</li>
					<li id='unordered_list_item2'>Java</li>
					<li id='unordered_list_item3'>C#</li>
				</ul>
			</div>
			<div className='is-flex is-flex-direction-column' data-identifier='Links'>
				<h3>Links</h3>
				<a id='facebook_link' href='https://www.facebook.com/techglobaleducation'>
					Facebook
				</a>
				<a id='instagram_link' href='https://www.instagram.com/techglobal.school/?hl=en'>
					Instagram
				</a>
			</div>
			<div data-identifier='Images'>
				<h3>Images</h3>
				<img id='testing_image' src={testingImage} alt='automation-testing' style={{ width: '80px' }} />
			</div>
			<div id='checkbox-button-group' data-identifier='Checkboxes'>
				<h3>Checkboxes</h3>
				<div>
					<label id='apple_check' className='checkbox'>
						<input className='mr-2' type='checkbox' id='checkbox_1'></input>
						Apple
					</label>
				</div>
				<div>
					<label id='microsoft_check' className='checkbox'>
						<input className='mr-2' type='checkbox' id='checkbox_2'></input>
						Microsoft
					</label>
				</div>
				<div>
					<input className='mr-2' type='checkbox' id='checkbox_3'></input>
					<label id='tesla_check' className='checkbox is-inline'>
						Tesla
					</label>
				</div>
			</div>
			<div id='radio-button-group' data-identifier='Radio Buttons'>
				<h3>Radio Buttons</h3>
				<div>
					<label id='java_radio' className='radio'>
						<input className='mr-1' type='radio' name='language' id='radio_1_option_1'></input>Java
					</label>
				</div>
				<div>
					<label id='js_radio' className='radio'>
						<input className='mr-1' type='radio' name='language' id='radio_1_option_2'></input>JavaScript
					</label>
				</div>
				<div>
					<input className='mr-1' type='radio' name='language' id='radio_1_option_3'></input>C#
					<label id='c_radio' className='radio'></label>
				</div>
			</div>
			<div data-identifier='Text Inputs'>
				<h3>Text Inputs</h3>
				<input id='text_input1' type='text' placeholder='Enter text here' />
				<div className='mt-3'>
					<label>Enter text below</label>
					<div>
						<input id='text_input2' type='text' />
					</div>
				</div>
			</div>
			<div data-identifier='Date Inputs'>
				<h3>Date Inputs</h3>
				<DatePicker
					id='date_input1'
					placeholderText='mm/dd/yyyy'
					selected={date1}
					onChange={(date) => setDate1(date)}
				/>
				<div className='mt-3'>
					<label>Enter a date</label>
					<DatePicker id='date_input2' selected={date2} onChange={(date) => setDate2(date)} />
				</div>
			</div>
			<div className={`${styles.dropdowns}`} data-identifier='Dropdowns'>
				<h3>Dropdowns</h3>
				<select id='company_dropdown1'>
					<option hidden>Select a company...</option>
					<option value='Apple'>Apple</option>
					<option value='Microsoft'>Microsoft</option>
					<option value='Tesla'>Tesla</option>
				</select>
				<div className='mt-3'>
					<label>Select a company</label>
					<div>
						<select id='company_dropdown2'>
							<option value='Apple'>Apple</option>
							<option value='Microsoft'>Microsoft</option>
							<option value='Tesla'>Tesla</option>
						</select>
					</div>
				</div>
			</div>
			<div data-identifier='Focus & Blur & AutoFill'>
				<h3>Focus & Blur & AutoFill</h3>
				<div className='mt-3'>
					<input
						type='email'
						onFocus={handleChange}
						onBlur={handleChange}
						value={emailValue}
						onChange={(e) => setEmailValue(e.target.value)}
						name='email'
						placeholder='Your Email'
					/>
				</div>
				<div className='mt-3 mb-3'>
					<input
						type='text'
						value={blurText}
						placeholder='First Name'
						onChange={(e) => setBlurText(e.target.value)}
					/>
				</div>
				<button disabled={emailValue.length > 0 && blurText.length > 0 ? false : true}>Submit</button>
			</div>

			<div data-identifier='Shadow DOM'>
				<h3>Shadow DOM</h3>
				<p>
					I am <strong>outside</strong> of Shadow DOM
				</p>
				<ShadowRoot>
					<p style={{ color: 'green' }}>
						I am <strong>inside</strong> the Shadow DOM
					</p>
				</ShadowRoot>
			</div>
		</div>
	);
}
