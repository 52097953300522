import FileUpload from '../FileUpload/FileUpload';

/* eslint-disable jsx-a11y/anchor-is-valid */
const FileDownload = () => {
	const onButtonClick = () => {
		fetch('SampleText.txt').then((response) => {
			response.blob().then((blob) => {
				const fileURL = window.URL.createObjectURL(blob);
				let alink = document.createElement('a');
				alink.href = fileURL;
				alink.download = 'SampleText.txt';
				alink.click();
			});
		});
	};
	return (
		<div>
			<h2>Download a file</h2>
			<p className='pb-3'>Please click on the file below to download</p>
			<a id='file_download' onClick={onButtonClick}>
				TechGlobal School.pptx
			</a>
			<hr />
			<h2>Upload a file</h2>
			<FileUpload />
		</div>
	);
};

export default FileDownload;
