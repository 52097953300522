import Frame from 'react-frame-component';
import { useState } from 'react';
import { useEffect } from 'react';

export default function IFrames() {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth || 1200);

	useEffect(() => {
		function handleResize() {
			setScreenWidth(window.innerWidth);
		}

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [fullName, setFullName] = useState('');

	function handleFormSubmit() {
		if (firstName !== '' && lastName !== '') {
			setFullName(`${firstName} ${lastName}`);
		}
	}

	const initialContent = () => {
		return '<!DOCTYPE html><html style="overflow: hidden; min-width: 50px!important"><head><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css"></head><body><div id="mountHere"></div></body></html>';
	};

	const renderFrame = () => {
		const isMobile = screenWidth < 768;
		const isTablet = 768 < screenWidth && screenWidth < 1024;

		// Desktop > 1024
		// let frameWidth = '300';
		let frameHeight = '280';

		let textSize = '1rem';

		if (isTablet) {
			// frameWidth = '250';
			frameHeight = '220';
			textSize = '0.8rem';
		}
		if (isMobile) {
			// frameWidth = '150';
			frameHeight = '200';
			textSize = '0.6rem';
		}
		return (
			<>
				<Frame
					initialContent={initialContent()}
					id='form_frame'
					name='form_frame'
					width='100%'
					height={`${frameHeight}`}
				>
					<form
						id='name_form'
						onSubmit={handleFormSubmit}
						style={{ width: '100%', minWidth: '100px !important', fontSize: `${textSize}` }}
					>
						<p className='label mb-4'>Please fill out your information below</p>
						<div className='field'>
							<label htmlFor='first_name'>Please enter your first name</label>
							<div className='control mt-2'>
								<input
									id='first_name'
									className='input'
									type='text'
									style={{ padding: '5px 10px', width: '100%', fontSize: `${textSize}` }}
									onChange={(e) => setFirstName(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className='field'>
							<label htmlFor='last_name'>Please enter your last name</label>
							<div className='control mt-2'>
								<input
									id='last_name'
									className='input'
									type='text'
									style={{ padding: '5px 10px', width: '100%', fontSize: `${textSize}` }}
									onChange={(e) => setLastName(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className='field is-pulled-right'>
							<button
								id='submit'
								className='button is-link'
								style={{
									width: '100%',
									padding: '5px 10px',
									'background-color': '#225ad7',
									fontSize: `${textSize}`,
								}}
							>
								<span style={{ color: 'white' }}>SUBMIT</span>
							</button>
						</div>
					</form>
				</Frame>
				<h2 className='label' id='result_id'>
					Result:
				</h2>
				{fullName && (
					<p id='result' style={{ color: 'var(--mainBlue)' }}>
						You entered: {fullName}
					</p>
				)}
			</>
		);
	};
	return <div>{renderFrame()}</div>;
}
