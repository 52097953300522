import React from 'react';
import styles from './Actions.module.css';
import Button from '../Partials/Buttons';
import { useState } from 'react';
import BasicSwap from './BasicSwap';

const m1 = 'You clicked on a button!';
const m2 = 'You right-clicked on a button!';
const m3 = 'You double-clicked on a button!';

const Actions = () => {
	const [clickMessage, setClickMessage] = useState('');
	const [rightClickMessage, setRightClickMessage] = useState('');
	const [doubleClickMessage, setDoubleClickMessage] = useState('');
	const [iValue, setIValue] = useState('');

	const setTouchClickMessage = () => {
		// IOS doesn't support onContextMenu
		// Faking it simply. Anyways we don't do actual mobile testing
		setTimeout(() => {
			setRightClickMessage(m2);
		}, 1000);
	};
	return (
		<div className={styles.wrapper}>
			<div>
				<div>
					<div className={styles.colmn}>
						<Button id='click' onClick={() => setClickMessage(m1)} children='Click on me' />
						<p id='click_result'>{clickMessage}</p>
					</div>
					<div className={styles.colmn}>
						<Button
							id='right-click'
							onContextMenu={(e) => {
								e.preventDefault();
								setRightClickMessage(m2);
							}}
							onTouchStart={() => setTouchClickMessage()}
							children='Right-Click on me'
						/>
						<p id='right_click_result'>{rightClickMessage}</p>
					</div>
					<div className={styles.colmn}>
						<Button
							id='double-click'
							onDoubleClick={() => setDoubleClickMessage(m3)}
							children='Double-Click on me'
						/>
						<p id='double_click_result'>{doubleClickMessage}</p>
					</div>
					<BasicSwap />
					<div>
						<input
							id='input_box'
							type='text'
							placeholder='Enter your message...'
							value={iValue}
							onChange={(e) => setIValue(e.target.value)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Actions;
