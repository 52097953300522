const ex1 = `Write a method named \`first()\` which takes an \`int[]\` as an argument and returns the first element when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
first([3, 5, 8]) -> 3
first([10, -5])  -> 10
first([0])       -> 0
~~~`;

const ex2 = `Write a method named \`last()\` which takes an \`String[]\` as an argument and returns the last element when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
last(["Tech", "Global"])	-> "Global"
last(["Tic", "Tac", "Toe"])	-> "Toe"
last(["Apple"])				-> "Apple"
~~~`;

const ex3 = `Write a method named \`arrLength()\` which takes an \`int[]\` as an argument and returns the size of the array when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
arrLength([2, 3, 5, 8])	-> 4
arrLength([10, -5])		-> 2
arrLength([0])			-> 1
~~~`;

const ex4 = `Write a method named \`noNegatives()\` which takes an \`int[]\` as an argument and returns it back with all negative elements replaced with zero when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
noNegatives([3, -5, 8])	 -> [3, 0, 8]
noNegatives([-10])		 -> [0]
noNegatives([-2, -3, 7]) -> [0, 0, 7]
~~~`;

const ex5 = `Write a method named \`swapEnds()\` which takes an \`String[]\` as an argument and returns it back with the first and last elements replaced with each other when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
swapEnds(["Tech", "Global"])	-> ["Global", "Tech"]
swapEnds(["Tic", "Tac", "Toe"])	-> ["Toe", "Tac", "Tic"]
swapEnds(["Apple"])				-> ["Apple"]
~~~`;

const ex6 = `Write a method named \`reverseArr()\` which takes an \`int[]\` as an argument and returns it back reversed when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
reverseArr([3, 5, 8])		-> [8, 5, 3]
reverseArr([10])			-> [10]
reverseArr([-2, -3, 7, 10]) -> [10, 7, -3, -2]
~~~`;

const ex7 = `Write a method named \`sumFirstLast()\` which takes an \`int[]\` as an argument and returns the sum of first and last elements of the array when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
sumFirstLast([3, 5, 8])		  -> 11
sumFirstLast([10])			  -> 20
sumFirstLast([-2, -3, 7, 10]) -> 8
~~~`;

const ex8 = `Write a method named \`has10()\` which takes an \`int[]\` as an argument and returns \`true\` if the array has an element equals 10, or returns \`false\` otherwise when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
has10([3])		   -> false
has10([-10, 0, 7]) -> false
has10([ 7, 10])	   -> true
~~~`;

const ex9 = `Write a method named \`hasPositive()\` which takes an \`int[]\` as an argument and returns \`true\` if the array contains a number bigger than zero, or returns \`false\` otherwise when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
hasPositive([0, 0])		  -> false
hasPositive([-10, 0, -5]) -> false
hasPositive([ 3, -5])	  -> true
~~~`;

const ex10 = `Write a method named \`hasNegative()\` which takes an \`int[]\` as an argument and returns \`true\` if the array contains a number smaller than zero, or returns \`false\` otherwise when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
hasNegatives([0, 0])	 -> false
hasNegatives([10, 0, 7]) -> false
hasNegatives([ 3, -5])	 -> true
~~~`;

const ex11 = `Write a method named \`multipleOfFive()\` which takes an \`int[]\` as an argument and returns \`true\` if all the array elements are multiple of 5, or returns \`false\` otherwise when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
multipleOfFive([5, 10, 15, 0, -5]) -> true
multipleOfFive([-10, 0, 7])		   -> false
multipleOfFive([ 7, 2])			   -> false
~~~`;

const ex12 = `Write a method named \`replaceLong()\` which takes a \`String[]\` argument and returns it back with all elements that have a length of five or more replaced with an empty String when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
replaceLong(["Tech", "Global"])		 -> ["Tech", ""]
replaceLong(["One", "Two", "Three"]) -> ["One", "Two", ""]
replaceLong(["Apple"])				 -> [""]
~~~`;

const ex13 = `Write a method named \`hasA()\` which takes a \`String[]\` as an argument and returns \`true\` if any element in the array starts with A-a, returns false otherwise when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
hasA(["Tech", "Global"]) -> false
hasA(["", "abc", "xyz"]) -> true
hasA(["Apple"])			 -> true
~~~`;

const ex14 = `Write a method named \`noEmpty()\` which takes a \`String[]\` as an argument and returns \`true\` if all the elements in the array are not empty, or returns false otherwise when invoked.  
**NOTE:** Assume you will not be given an empty array.
~~~java
noEmpty(["Tech", "Global"])	-> true
noEmpty(["", "abc", "xyz"])	-> false
noEmpty(["Apple"])			-> true
~~~`;

export const arraysExercisesData = [
	{
		title: 'First Element',
		content: ex1,
	},
	{
		title: 'Last Element',
		content: ex2,
	},
	{
		title: 'Array Size',
		content: ex3,
	},
	{
		title: 'No Negatives',
		content: ex4,
	},
	{
		title: 'Swap Ends',
		content: ex5,
	},
	{
		title: 'Reverse Array',
		content: ex6,
	},
	{
		title: 'Sum First & Last',
		content: ex7,
	},
	{
		title: 'Has Ten',
		content: ex8,
	},
	{
		title: 'Has Positive',
		content: ex9,
	},
	{
		title: 'Has Negative',
		content: ex10,
	},
	{
		title: 'Multiple of Five',
		content: ex11,
	},
	{
		title: 'Replace Long Words',
		content: ex12,
	},
	{
		title: 'Has Word Starts With A',
		content: ex13,
	},
	{
		title: 'No Empty Element',
		content: ex14,
	},
];
