const ex1 = `Write a method named as \`findMax()\` which takes an \`ArrayList\` of \`Integers\` as an argument and returns the greatest element when invoked.<br/>
NOTE: If the \`ArrayList\` is empty, then return -1. 

~~~java
findMax([4, 3, 2, 1])		 		-> 4
findMax([1, 2, 3, -4])				-> 3
findMax([])					        -> 0
~~~
`;

const ex2 = `Write a method \`findMin()\` that returns the \`minimum\` value in an \`ArrayList\` of \`Integers\`.
If there are no elements in the ArrayList, return 0.

Write a method named as \`findMin()\` which takes an \`ArrayList\` of \`Integers\` as an argument and returns the smallest element when invoked.<br/>
NOTE: If the ArrayList is empty, then return -1.


~~~java
findMin([4, 3, 2, 1])		 		-> 1
findMin([1, 2, 3, -4])				-> -4
findMin([])					        -> 0
~~~
`;

const ex3 = `Write a method named \`reversedNumbers()\` which takes an \`LinkedList\` of \`Integer\` and returns the list back reversed when invoked.

~~~java
reversedNumbers([5, 2, 3, 7])		-> [7, 3, 2, 5]
reversedNumbers([5, 5, 5])			-> [5, 5, 5]
reversedNumbers([-2, -10, 0, 2])	-> [2, 0, -10, -2]
~~~
`;

const ex4 = `Write a method named \`reversedWords()\` which takes an \`LinkedList\` of \`String\` and returns the list back reversed when invoked.

~~~java
reversedWords(["Tech", "Global"])				-> ["Global", "Tech"]
reversedWords(["", "Hi", "Hello", "123"])		-> ["123", "Hello", "Hi", ""]
reversedWords(["abc"])							-> ["abc"]
~~~
`;

const ex5 = `Write a method named as \`sortNumbers()\` which takes an \`LinkedList\` of \`Integer\` and returns the list back sorted when invoked.

~~~java
sortNumbers([5, 2, 3, 7])		-> [2, 3, 5, 7]
sortNumbers([5, 5, 5])			-> [5, 5, 5]
sortNumbers([-2, -10, 0, 2])	-> [-10, -2, 0, 2]
~~~
`;
const ex6 = `Write a method named \`sortWords()\` which takes a \`LinkedList\` of \`String\` and returns the list back sorted when invoked.

~~~java
reversedWords(["Tech", "Global"])				-> ["Global", "Tech"]
reversedWords(["", "hi", "Hello", "123"])		-> ["", "123", "Hello", "hi"]
reversedWords(["abc", "ABC"])					-> ["ABC", "abc"]
~~~
`;
const ex7 = `Write a method named as \`middle()\` which takes a \`LinkedList\` of \`Integer\` and returns the middle number when invoked. <br/>
NOTE: If the size of the list is even, then there would be two middle elements. In this case, always return the element which has a smaller index.


~~~java
middle([5, 2, 3, 7, 6])			-> 3
middle([5, 5, 5])				-> 5
middle([2, 10, 0, 2])			-> 10
middle([5, 4, 8, 9, 10, 1])		-> 8
~~~
`;
const ex8 = `Write a method named as \`remove()\` which takes a \`LinkedList\` of \`Integer\` and an \`int\` number and returns the list back with the all existence of the number removed when invoked. <br/>
NOTE: If the number does not exist in the list, then return the list back unchanged.

~~~java
middle([5, 2, 3, 7, 6])			-> 3
middle([5, 5, 5])				-> 5
middle([2, 10, 0, 2])			-> 10
middle([5, 4, 8, 9, 10, 1])		-> 8
~~~
`;
const ex9 = `Write a method named as \`countUniqueNumbers()\` which takes an \`ArrayList\` of \`Integer\` and returns the total count of unique numbers in the list when invoked.

~~~java
countUniqueNumbers([5, 1, 2, 5])	-> 3
countUniqueNumbers([3, 3, 3])		-> 1
countUniqueNumbers([2, 10, 0, -5])	-> 4
~~~
`;
const ex10 = `Write a method named as \`countUniqueWords()\` which takes an \`ArrayList\` of \`String\` and returns the total count of unique words in the list when invoked.<br/>
NOTE: This method is case-sensitive.

~~~java
countUniqueWords(["Tech", "Global"])					-> 2
countUniqueWords(["", "Hi", "Hello", "123", "", "Hi"])	-> 4
countUniqueWords(["abc", "ABC", "Abc", "ABc", "aBC"])	-> 5
countUniqueWords(["Tech", "Global", "Tech", "Global"])	-> 2
~~~
`;
const ex11 = `Write a method named as \`countUniqueCharacters()\` which takes a \`String\` and returns the total count of unique characters in the String when invoked.<br/>
NOTE: This method is case-sensitive. Space is also a unique character.

~~~java
countUniqueCharacters("hello") 		-> 4
countUniqueCharacters("world") 		-> 5
countUniqueCharacters("ABCabc") 	-> 6
countUniqueCharacters("12323") 		-> 3
countUniqueCharacters("ab cd e") 	-> 6
~~~
`;
const ex12 = `Write a method named as \`countUniqueWords()\` which takes a \`String\` and returns the total count of unique words in the String when invoked.<br/>
NOTE: This method is case-sensitive. Space is also a unique character.

~~~java
countUniqueWords("Java is fun") 	-> 3
countUniqueWords("Hello World") 	-> 2
countUniqueWords("back to back") 	-> 2
countUniqueWords("hello hello") 	-> 1
~~~
`;
const ex13 = `Write a method named as \`noDuplicates()\` which takes an \`ArrayList\` of \`Integer\` and returns the list back with all duplicates removed when invoked.

~~~java
noDuplicates([1, 2, 3, 2, 2])		-> [1, 2, 3, 2]
noDuplicates([10, 20, 30, 20, 40]) 	-> [10, 20, 30, 40]
noDuplicates([4, 5, 6])				-> [4, 5, 6]
~~~
`;
const ex14 = `Write a method named \`nElements()\` which takes an \`ArrayList\` of \`Integer\` and an \`int\` number and returns a new list that contains only the first N elements when invoked.<br>
NOTE: If the number is greater than the size of the list, then return the list unchanged.
If the number is less than or equals zero, then return an empty list.


~~~java
nElements([5, 2, 7, 3, 9], 3) 	-> [5, 2, 7]
nElements([5, 10], 3) 			-> [5, 10]
nElements([1, 2, 3], 0) 		-> []
nElements([4, 5], -5) 			-> []
~~~
`;
const ex15 = `Write a method named as \`sumMaxMin()\` which takes an \`ArrayList\` of \`Integer\` and returns the sum of max and min numbers in the list when invoked.<br>
NOTE: If the list is empty, then return zero.

~~~java
sumMaxMin([5, 2, 7, 3, 9])		-> 11
sumMaxMin([-3, -1, 0, 2]) 		-> -1
sumMaxMin([5]) 					-> 10
sumMaxMin([]) 					-> 0
~~~
`;
const ex16 = `Write a method named as \`median()\` which takes an \`ArrayList\` of \`Integer\` and returns the median number in the list when invoked.<br>
NOTE: Median number is the element in the middle after sorting the list.
Assume you will always be given a list with the odd counts of the elements.


~~~java
median([5, 2, 7, 3, 9])				-> 5
median([-3, -1, 0, 2, -5, 3, -10]) 	-> -1
median([10]) 						-> 10
~~~
`;
const ex17 = `Write a method named as \`countCharacters()\` which takes a \`String\` and returns each unique character in the String mapped with the count of the character when invoked.<br>
NOTE: This method is case-sensitive.

~~~java
countCharacters("Tech") 	-> {"T"=1, "e"=1, "c"=1, "h"=1}
countCharacters("hello") 	-> {"h"=1, "e"=1, "l"=2, "o"=1}
countCharacters("Aaa") 		-> {"A"=1, "a"=2}
countCharacters("a b c") 	-> {"a"=1, " "=2, "b"=1, "c"=1}
~~~
`;
const ex18 = `Write a method named as \`countWords()\` which takes a \`String\` and returns each unique word in the String mapped with the count of the word when invoked.<br>
NOTE: This method is case-sensitive.

~~~java
countWords("Java is fun") 		-> {"Java"=1, "is"=1, "fun"=1}
countWords("back to back") 		-> {"back"=2, "to"=1}
countWords("Tech") 				-> {"Tech"=1}
countWords("Hello hello") 		-> {"Hello"=1, "hello"=1}
~~~
`;
const ex19 = `Write a method named as \`wordLength()\` which takes a \`String\` and returns each unique word in the String mapped with the length of the word when invoked.<br>
NOTE: Assume there will always be at least one word in the String.

~~~java
wordLength("Java is fun") 			-> {"Java"=4, "is"=2, "fun"=3}
wordLength("TechGlobal School") 	-> {"TechGlobal"=10, "School"=6}
wordLength("Hello Hello") 			-> {"Hello"=5}
wordLength("A a") 					-> {"A"=1, "a"=1}
~~~
`;
const ex20 = `Write a method named as \`longestWord()\` which takes a \`String\` and returns the longest word in the String when invoked.<br>
NOTE: Assume there will always be one word in the String longer than the others.

~~~java
longestWord("Java is fun") 			-> "Java"
longestWord("TechGlobal School") 	-> "TechGlobal"
longestWord("Hello") 				-> "Hello"
~~~
`;

export const collectionsExercisesData = [
	{
		title: 'Find max in ArrayList',
		content: ex1,
	},
	{
		title: 'Find min in ArrayList',
		content: ex2,
	},
	{
		title: 'Reverse The Numbers LinkedList',
		content: ex3,
	},
	{
		title: 'Reverse The Words LinkedList',
		content: ex4,
	},
	{
		title: 'Sort The Numbers LinkedList',
		content: ex5,
	},
	{
		title: 'Sort The Words LinkedList',
		content: ex6,
	},
	{
		title: 'Middle of the Linked List',
		content: ex7,
	},
	{
		title: 'Remove The Specified Element From LinkedList',
		content: ex8,
	},
	{
		title: 'Count Unique Numbers',
		content: ex9,
	},
	{
		title: 'Count Unique Words',
		content: ex10,
	},
	{
		title: 'Count Unique Characters',
		content: ex11,
	},
	{
		title: 'Count Unique Words Two',
		content: ex12,
	},
	{
		title: 'Remove Duplicates',
		content: ex13,
	},
	{
		title: 'Keep The N Element',
		content: ex14,
	},
	{
		title: 'Find Sum of Max and Min',
		content: ex15,
	},
	{
		title: 'Find The Median',
		content: ex16,
	},
	{
		title: 'Count Characters',
		content: ex17,
	},
	{
		title: 'Count Words',
		content: ex18,
	},
	{
		title: 'Length of Each Word',
		content: ex19,
	},
	{
		title: 'Longest Word',
		content: ex20,
	},
];
