import Accordion from '../../../components/Accordion/Accordion';
import styles from './Common.module.css';
import Explanation from '../../../components/Partials/Explanation';
import ExercisesLayout from '../../../components/Partials/ExercisesLayout';

const JavaInnerPage = ({ data, needExplanation }) => {
	return (
		<div>
			{needExplanation && <Explanation />} {/* TODO: Maybe put some skeleton rather than empty title ?*/}
			<ExercisesLayout>
				<div className={styles.accordionWrapper}>
					{data.map((dataToAccordion, index) => (
						<Accordion dataToAccordion={dataToAccordion} key={index} />
					))}
				</div>
			</ExercisesLayout>
		</div>
	);
};

export default JavaInnerPage;
