import React from 'react';
import styles from './Box.module.css';

/*
    padding: 10px
*/

const Box = React.forwardRef(({ className = null, id = null, type = null, children = null, style = {} }, ref) => {
	return (
		<button className={`${styles.c_box} ${className}`} id={id} type={type} style={style} ref={ref}>
			{children}
		</button>
	);
});
export default Box;
