import {
	HtmlElements,
	Locators,
	XPathCSS,
	Alerts,
	RadioButtons,
	DynamicElements,
	Waits,
	Checkboxes,
	MultipleWindows,
	LoginForm,
	FileDownload,
	FileUpload,
	IFrames,
	ForgotPassword,
	DynamicTables,
	Dropdown,
	Tables,
	Actions,
	Pagination,
	SortableTables,
	Calendar,
	Project1,
	Project2,
	Project3,
	Project4,
	Project5,
	Project6,
	Project7,
	Project8,
	Project9,
	Project10,
} from '../app/frontend-testing/pages';

const data = [
	{
		component: (props = {}) => <HtmlElements {...props} />,
		url: 'html-elements',
	},
	{
		component: (props = {}) => <Locators {...props} />,
		url: 'locators',
	},
	{
		component: (props = {}) => <XPathCSS {...props} />,
		url: 'xpath-css-locators',
	},
	{
		component: (props = {}) => <DynamicElements {...props} />,
		url: 'dynamic-elements',
	},
	{
		component: (props = {}) => <Waits {...props} />,
		url: 'waits',
	},
	{
		component: (props = {}) => <Dropdown {...props} />,
		url: 'dropdowns',
	},
	{
		component: (props = {}) => <RadioButtons {...props} />,
		url: 'radio-buttons',
	},
	{
		component: (props = {}) => <Checkboxes {...props} />,
		url: 'checkboxes',
	},
	{
		component: (props = {}) => <Alerts {...props} />,
		url: 'alerts',
	},
	{
		component: (props = {}) => <IFrames {...props} />,
		url: 'iframes',
	},
	{
		component: (props = {}) => <MultipleWindows {...props} />,
		url: 'multiple-windows',
	},
	{
		component: (props = {}) => <Tables {...props} />,
		url: 'tables',
	},
	{
		component: (props = {}) => <DynamicTables {...props} />,
		url: 'dynamic-tables',
	},
	{
		component: (props = {}) => <FileUpload {...props} />,
		url: 'file-upload',
	},
	{
		component: (props = {}) => <FileDownload {...props} />,
		url: 'file-download',
	},
	{
		component: (props = {}) => <Actions {...props} />,
		url: 'actions',
	},
	{
		component: (props = {}) => <LoginForm {...props} />,
		url: 'login-form',
	},
	{
		component: (props = {}) => <ForgotPassword {...props} />,
		url: 'forgot-password',
	},
	{
		component: (props = {}) => <SortableTables {...props} />,
		url: 'sortable-tables',
	},
	{
		component: (props = {}) => <Pagination {...props} />,
		url: 'pagination',
	},
	{
		component: (props = {}) => <Calendar {...props} />,
		url: 'calendar',
	},
	{
		component: (props = {}) => <Project1 {...props} />,
		url: 'project-1',
	},
	{
		component: (props = {}) => <Project2 {...props} />,
		url: 'project-2',
	},
	{
		component: (props = {}) => <Project3 {...props} />,
		url: 'project-3',
	},
	{
		component: (props = {}) => <Project4 {...props} />,
		url: 'project-4',
	},
	{
		component: (props = {}) => <Project5 {...props} />,
		url: 'project-5',
	},
	{
		component: (props = {}) => <Project6 {...props} />,
		url: 'project-6',
	},
	{
		component: (props = {}) => <Project7 {...props} />,
		url: 'project-7',
	},
	{
		component: (props = {}) => <Project8 {...props} />,
		url: 'project-8',
	},
	{
		component: (props = {}) => <Project9 {...props} />,
		url: 'project-9',
	},
	{
		component: (props = {}) => <Project10 {...props} />,
		url: 'project-10',
	},
];

// Add id
for (let i = 0; i < data.length; i++) {
	data[i]['id'] = `card-${i + 1}`;
}

export { data as frontEndRoute_data };
