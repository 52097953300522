import Accordion from '../../../../components/Accordion/Accordion';
import { frontEndProject2TestCases as data } from './frontEndProjectData';
import LoginForm from '../LoginForm/LoginForm';

const Project2 = () => {
	return (
		<div>
			<div className='content'>
				<p>
					This project aims to enrich students' comprehension of frontend automation, with a focus on login
					processes.
				</p>
				<ul>
					<li>
						Through this project, students will have the opportunity to practice locating web elements for
						logging in, logging out, resetting passwords, and handling input fields.
					</li>
					<li>
						Additionally, students will also learn how to validate form submissions and conduct positive and
						negative testing for these functions.
					</li>
				</ul>
				<p>
					Students are expected to automate all of the provided test cases and submit their work to the
					TechGlobal Instructor Team. We hope that you find this project to be an enjoyable and valuable
					learning experience!
				</p>
			</div>
			{data.map((testCase, idx) => (
				<Accordion dataToAccordion={testCase} key={idx} />
			))}
			<br />
			<div className='has-background-white-ter pt-6 pr-6 pl-6 pb-4'>
				<div className='mb-5'>
					<h1 className='is-size-3' style={{ color: 'var(--mainBlue)' }}>
						Login Form
					</h1>
				</div>
				<LoginForm embeddedInProject={true} />
			</div>
		</div>
	);
};

export default Project2;
