import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

/**
 * Infinite Scroll Project
 */

// Style
const style = {
	background: 'var(--mainBlue)',
	color: '#fff',
	borderRadius: '5px',
	marginBottom: '6px',
	padding: '10px',
};

// Component
function Project7() {
	// State
	const [items, setItems] = useState(Array.from({ length: 20 }));
	// Fake async api call to get 20 more boxes
	const fetchMoreData = () => {
		setTimeout(() => {
			setItems(items.concat(Array.from({ length: 20 })));
		}, 1500);
	};
	return (
		<div>
			<InfiniteScroll
				dataLength={items.length}
				next={fetchMoreData}
				hasMore={true}
				loader={<h3 style={{ textAlign: 'center', marginTop: '10px' }}>Loading...</h3>}
			>
				{items.map((i, index) => (
					<div style={style} key={index}>
						box - #{index}
					</div>
				))}
			</InfiniteScroll>
		</div>
	);
}
export default Project7;
