import { useState } from 'react';
import Box from '../Partials/Box';
import Button from '../Partials/Buttons';
import styles from './Waits.module.css';

export default function Waits() {
	const [isShown, setIsShown] = useState(false);
	const [isShown2, setIsShown2] = useState(false);

	const handleClick = () => {
		setTimeout(() => {
			setIsShown((current) => !current);
		}, 10000);
		// }, 100);
	};
	const handleClick2 = () => {
		setTimeout(() => {
			setIsShown2((current) => !current);
		}, 35000);
		// }, 100);
	};

	return (
		<div>
			<div className={styles.boxes}>
				<ul className={styles.items}>
					<li>
						<Button
							id='red'
							className={`${styles.red_btn} button is-light mt-1`}
							onClick={handleClick}
							children='Click on me to see a red box'
						/>
					</li>
					<li>{isShown && <Box className={`${styles.red_box} box has-background-danger`} />}</li>
				</ul>
			</div>

			<div className={styles.boxes}>
				<ul className={styles.items}>
					<li>
						<Button
							id='blue'
							className={`${styles.blue_btn} button is-light mt-1`}
							onClick={handleClick2}
							children='Click on me to see a blue box'
						/>
					</li>
					<li>{isShown2 && <Box className={`${styles.blue_box} box`} />}</li>
				</ul>
			</div>
		</div>
	);
}
