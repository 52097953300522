import { DummyPage } from '../app/frontend-testing/pages';
import JavaInnerPage from '../app/java-exercises/pages/JavaInnerPage';
import * as allData from '../app/java-exercises/pages/accordionData';

const data = [
	{
		component: (
			props = {
				data: allData.printExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'print-statements',
	},
	{
		component: (
			props = {
				data: allData.scannerExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'scanner-programs',
	},
	{
		component: (
			props = {
				data: allData.conditionalStatementsExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'conditional-statements',
	},
	{
		component: (
			props = {
				data: allData.basicExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'basic-methods',
	},
	{
		component: (
			props = {
				data: allData.stringExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'string-methods',
	},
	{
		component: (
			props = {
				data: allData.loopsExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'loops',
	},
	{
		component: (
			props = {
				data: allData.arraysExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'arrays',
	},
	{
		component: (
			props = {
				data: allData.arrayListExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'arraylists',
	},
	{
		component: (
			props = {
				data: allData.regexExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'regex',
	},
	{
		component: (
			props = {
				data: allData.collectionsExercisesData,
				needExplanation: true,
			}
		) => <JavaInnerPage {...props} />,
		url: 'collections',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-1',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-1',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-3',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-4',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-5',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-6',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-7',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-8',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-9',
	},
	{
		component: (props = {}) => <DummyPage {...props} />,
		url: 'exercise-10',
	},
];

// Add id
for (let i = 0; i < data.length; i++) {
	data[i]['id'] = `card-${i + 1}`;
}

export { data as javaRoute_data };
