// https://create-react-app.dev/docs/adding-custom-environment-variables/

let BASE_URL;
if (process.env.NODE_ENV === 'development') {
	BASE_URL = process.env.REACT_APP_BASE_DEV_URL;
	BASE_URL = 'http://localhost:8089';
} else {
	BASE_URL = process.env.REACT_APP_BASE_PROD_URL;
}

export { BASE_URL };
