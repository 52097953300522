const ex1 = `Write a function named \`makeNegative()\` that takes a number and returns its negative value.

**NOTE:** The number can be negative already, in which case no change is required.
**NOTE:** Zero (0) is not checked for any specific sign. Negative zeros make no mathematical sense. So, zero will stay as zero.

~~~js
makeNegative(10)  -> -10
makeNegative(-7)  -> -7
makeNegative(0)   -> 0
~~~`;

const ex2 = `Write a function named \`isSumEvenOrOdd()\` which takes three numbers as arguments and determines if the sum of these numbers is odd or even.

~~~js
isSumEvenOrOdd(0, 1, 4)     -> "odd"
isSumEvenOrOdd(0, -1, -5)   -> "even"
isSumEvenOrOdd(0, 0, 0)     -> "even"
isSumEvenOrOdd(7, 1, 9)     -> "odd"
isSumEvenOrOdd(1, 1, 1)     -> "odd"
~~~`;

const ex3 = `Write a function named \`decimal2()\` which takes an array of numbers as an argument and returns the array with the same numbers rounded up or down and represented with only two decimals.

~~~js
decimal2([94.356, 8.9752])                      -> [94.36, 8.98]
decimal2([76.62, 128.4, 84])                    -> [76.62, 128.40, 84.00]
decimal2([20987, 3.53245, 12.345, 32.9])        -> [20987.00, 3.53, 12.35, 32.90]
~~~`;

const ex4 = `Write a function named \`myPow()\` which takes two numbers, \`x\` and \`n\`, as its arguments and returns \`x\` to the power of \`n\` without using Math.pow(). For instance, 3 to the power of 3 is 3*3*3 = 27.

**NOTE:** Any number to the power of 0 equals 1. Any number to the power of 1 equals the number itself.

~~~js
myPow(3, 3)    -> 27
myPow(10, 1)   -> 10
myPow(100, 0)  -> 1
~~~`;

const ex5 = `Write a function named \`findLongestWord()\` which takes a \`string\` as input and returns the longest word in the string.

**NOTE:** If the string is empty or consists of spaces only, then return an empty string.
**NOTE:** If the string consists of multiple words having the longest word, then return the first occurrence.

~~~js
findLongestWord("The quick brown fox jumped over the lazy dog")   -> "jumped"
findLongestWord("This is a sample string for testing")            -> "testing"
findLongestWord("One two ten")                                    -> "One"
~~~`;

const ex6 = `Write a function named \`countVC()\` which takes a \`string\` argument and returns an object with the count of vowels and consonants.

~~~js
countVC("Hello")          -> {vowels: 2, consonants: 3}
countVC("Programming")    -> {vowels: 3, consonants: 8}
countVC("123AbC")         -> {vowels: 1, consonants: 2}
~~~`;

const ex7 = `Write a function named \`countChars()\` which takes a \`string\` argument and returns an object with the count of letters, numbers, and specials.

**NOTE:** If the count of a category is 0 then it should not be in the object. Also spaces do not count towards any category.

~~~js
countChars("Hello")               -> {letters: 5}
countChars("Programming 123")     -> {letters: 11, numbers: 3}
countChars("123AbC!@#")           -> {letters: 3, numbers: 3, specials: 3}
~~~`;

const ex8 = `Write a function named \`maxOccurrences()\` which takes a \`string\` argument and returns the character that appears the most number of times in the string.

**NOTE:** If there is a tie, return the first one that appears in the string. This task is case sensitive. Ignore spaces. If the string is empty or consists of spaces only, then return an empty string.

~~~js
maxOccurrences("Hello")          -> "l"
maxOccurrences("Occurrences")    -> "c"
maxOccurrences("    ab    ")     -> "a"
~~~`;

const ex9 = `Write a function named \`starOut()\` which takes a \`string\` argument and returns it back with every star removed as well as the right and left of the star.

**NOTE:** If there are 2 stars next to each other than remove the next non star. So "ab*cd" yields "ad" and "ab**cd" also yields "ad".


~~~js
starOut("ab*cd") 		-> "ad"
starOut("ab**cd") 		-> "ad"
starOut("xm*sm*sy") 	-> "xy"
starOut("abc")   	    -> "abc"
~~~`;

const ex10 = `Write a function named \`romanToInt()\` which takes a \`string\` roman numeral as its arguments and return the roman numeral converted to the number. A roman numeral is a set of symbols that add up to a number. CXII -> 100+10+1+1 -> 112

~~~js
Symbol       Value
I            1
V            5
X            10
L            50
C            100
D            500
M            1000

romanToInt("I") 		-> 1
romanToInt("IV") 		-> 4
romanToInt("CXII") 	    -> 112
romanToInt("MMM") 	    -> 3000
~~~`;

export const exercise05ExercisesData = [
	{
		title: 'Make Negative',
		content: ex1,
	},
	{
		title: 'Sum of Number is Even or Odd',
		content: ex2,
	},
	{
		title: 'Two Decimal Points',
		content: ex3,
	},
	{
		title: 'X to the Power of N',
		content: ex4,
	},
	{
		title: 'Longest Word',
		content: ex5,
	},
	{
		title: 'Count Vowels and Consonants',
		content: ex6,
	},
	{
		title: 'Count Characters',
		content: ex7,
	},
	{
		title: 'Maximum Occurring Character',
		content: ex8,
	},
	{
		title: 'Removing Star',
		content: ex9,
	},
	{
		title: 'Roman to Number',
		content: ex10,
	},
];
