import CustomHelmet from '../../../../utils/CustomHelmet';
import styles from './PageTitle.module.css';

const PageTitle = ({ title }) => {
	return (
		<div id='main_header_container' className={styles.main_header_container}>
			<CustomHelmet titleText={`TechGlobal Training | ${title}`} />
			<h1 id='main_heading'>{title}</h1>
		</div>
	);
};
export default PageTitle;
