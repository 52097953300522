const ex1 = `Write your code that prints out \`Hello World!\` to the console.`;

const ex2 = `Write your code that prints out \`Blue\` to the console.`;

const ex3 = `Write your code that prints out the list of fruits below to the console as it seems. Use **ONLY** one \`println()\` statement.
~~~
Favorite Fruits:
Pineapple
Grapes
Mango
~~~
`;

const ex4 = `Write your code that prints out the list of programming languages below to the console as it seems. Use **ONLY** one \`println()\` statement.
~~~
Programming Languages:
Java
Python
Ruby
~~~
`;

const ex5 = `Write your code that prints out the sentence below as it seems.
~~~
QA stands for "Quality Assurance", an umbrella term for testing a product.
~~~
`;

const ex6 = `Write your code that prints out the quote by Steve Jobs below as it seems. Use **ONLY** one \`println()\` statement.
~~~
"The only way to do great work is to love what you do."
Steve Jobs
~~~
`;

const ex7 = `Write your code that prints out the quote by Steve Jobs below as it seems. Use **ONLY** one \`println()\` statement.
~~~
	Automated testing is a process that validates if the software is functioning appropriately
and meeting requirements before it is released into production.
	This software testing method uses scripted sequences that are executed by testing
tools. It tests for bugs, defects, and any other issues that can arise with product development.
~~~
`;

// Diffculty levels will be implemented later
export const printExercisesData = [
	{
		title: 'Hello World',
		content: ex1,
		// difficulty: 'Easy',
	},
	{
		title: 'Blue',
		content: ex2,
		// difficulty: 'Easy',
	},
	{
		title: 'Favorite Fruits',
		content: ex3,
		// difficulty: 'Easy',
	},
	{
		title: 'Programming Languages',
		content: ex4,
		// difficulty: 'Easy',
	},
	{
		title: 'QA',
		content: ex5,
		// difficulty: 'Medium',
	},
	{
		title: 'Steve Jobs',
		content: ex6,
		// difficulty: 'Medium',
	},
	{
		title: 'Automation Testing',
		content: ex7,
		// difficulty: 'Hard',
	},
];
