const ex1 = `Write function named \`toInitials()\` which takes a \`string\` argument considered to be full name and returns initials of the given full name.

**NOTE:** Initials should be separated with a space and followed with a period.
Assume you will always be given first name and last name only. 

~~~js
toInitials( "Joe Doe")      -> "J. D."
toInitials( "Alex Reyes")   -> "A. R."
toInitials( "Tom Cruise")   -> "T. C."
~~~`;

const ex2 = `Write a function named \`hasNumbers()\` which takes a \`string\` argument and returns true if there is a number and \`false\` if there isn’t. 

~~~js
hasNumbers("")			            -> false
hasNumbers("John is 34 years old")	-> true
hasNumbers("Hello 3")		        -> true
~~~`;

const ex3 = `Write a function named \`elementLength()\` which takes an \`array\` argument and returns the length of each element as a separate array.

~~~js
elementLength( [ "Hello", "World" ] )				        -> [ 5, 5 ]
elementLength( [ "Apple", "Banana", "Orange", "Pear" ] )	-> [ 5, 6, 6, 4 ]
elementLength( ["BMW", "Mercedes", "Audi" ] )		        -> [ 3, 8, 4 ]
~~~`;

const ex4 = `Write a function named \`isArraySumEvenOrOdd()\` which takes an \`array\` of numbers and calculates if the sum of its elements is even or odd.

**NOTE:** If the array is empty return even.

~~~js
isArraySumEvenOrOdd( [ ] )			    -> "even"
isArraySumEvenOrOdd( [ 0,-1,-5 ] )      -> "even"
isArraySumEvenOrOdd( [ 7,1, 8,1 ] )     -> "odd"
~~~`;

const ex5 = `Write a function named \`reverse()\` which takes a \`string\` argument and returns the given string reversed.


~~~js
reverse("Hello World")	        -> "dlroW olleH"
reverse("TechGlobal")		    -> "labolGhceT"
reverse("Basketball is fun")	-> "nuf si llabteksaB"
~~~`;

const ex6 = `Write a function named \`reverseWords()\` which takes a \`string\` argument and returns a \`string\` with each word within that string reversed but still in the same order as the initial string. 

~~~js
reverseWords("Hello World")		    -> "olleH dlroW"
reverseWords("TechGlobal")		    -> "labolGhceT"
reverseWords("Basketball is fun")	-> "llabteksaB si nuf"
~~~`;

const ex7 = `Write a function named \`findMedian()\` which takes \`two arrays of numbers\` as its arguments and return the median of the two sorted arrays. 

**NOTE:** The median is the middle number of a sorted array. So the median of [1,3], [2] would be 2. If the array has an even length, you are to find the average of the 2 middle numbers

~~~js
findMedian([1, 3], [2]) 	 -> 2
findMedian([1, 2], [3, 4]) 	 -> 2.5
findMedian([4], [3]) 		 -> 3.5
~~~`;

const ex8 = `Write a function named \`calculateFactorial()\` which takes a \`number\` as an argument and returns the factorial of that number.

**NOTE:** Factorial is the product of all positive integers less than or equal to a given positive integer and denoted by that integer and an exclamation point. Thus, factorial seven is written 7!, meaning 1 × 2 × 3 × 4 × 5 × 6 × 7. Factorial zero is defined as equal to 1.

~~~js
calculateFactorial(0) 		-> 1
calculateFactorial(1)		-> 1
calculateFactorial(5)		-> 120
calculateFactorial(6) 		-> 720
~~~`;

const ex9 = `Write a function named \`calculateGCD()\` which takes \`two numbers\` as arguments and returns the greatest common divisor of the two numbers.

**NOTE:** GCD is a mathematical concept used to describe the largest number that divides two or more integers without leaving a remainder. In other words, it is the largest number that is a common factor of two or more numbers.


~~~js
calculateGCD(8, 12) 	-> 4
calculateGCD(17, 5) 	-> 1
calculateGCD(48, 18) 	-> 6
calculateGCD(0, 5) 		-> 5
~~~`;

const ex10 = `Write a function named \`calculateLCM()\` which takes \`two numbers\` as arguments and returns the least common multiple of the two numbers.

**NOTE:** GCD is a mathematical concept used to describe the largest number that divides two or more integers without leaving a remainder. In other words, it is the largest number that is a common factor of two or more numbers.

~~~js
calculateLCM(8, 12) 	-> 24
calculateLCM(17, 5) 	-> 85
calculateLCM(48, 18) 	-> 144
calculateLCM(0, 5) 		-> 0
~~~`;

export const exercise07ExercisesData = [
	{
		title: 'To Initials',
		content: ex1,
	},
	{
		title: 'Has Numbers',
		content: ex2,
	},
	{
		title: 'Length of Elements',
		content: ex3,
	},
	{
		title: 'Sum of Array is Even or Odd',
		content: ex4,
	},
	{
		title: 'Reverse String',
		content: ex5,
	},
	{
		title: 'Reverse Each Word in a String',
		content: ex6,
	},
	{
		title: 'Median of Two Sorted Arrays',
		content: ex7,
	},
	{
		title: 'Calculate Factorial',
		content: ex8,
	},
	{
		title: 'Calculate GCD (Greatest Common Divisor)',
		content: ex9,
	},
	{
		title: 'Calculate LCM (Least Common Multiple)',
		content: ex10,
	},
];
