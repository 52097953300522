import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSkull } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function PageNotFound() {
	return (
		<div className='has-text-centered pt-6'>
			<div className='is-size-1'>
				4<FontAwesomeIcon icon={faSkull} size='xs' />4 Error
			</div>
			<div className='is-size-3'>Sorry, the page not found.</div>
			<br />
			<Link to={'/'}>I'd like to study, take me back to practices.</Link>
		</div>
	);
}

export default PageNotFound;
