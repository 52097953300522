export default function Locators() {
	return (
		<div>
			<ul className='Fruits' style={{ listStyle: 'none', marginLeft: 0 }}>
				<li>
					<p id='item_kiwi'>Kiwi</p>
				</li>
				<li>
					<p name='item_orange'>Orange</p>
				</li>
				<li>
					<p className='item_grapes'>Grapes</p>
				</li>
			</ul>
			<ul className='links' style={{ listStyle: 'none', marginLeft: 0 }}>
				<li>
					<a href='https://en.wikipedia.org/wiki/Red_apple'>Red Apple</a>
				</li>
				<li>
					<a href='https://en.wikipedia.org/wiki/Green_apple'>Green Apple</a>
				</li>
				<li>
					<a href='https://en.wikipedia.org/wiki/Pineapple'>Pineapple</a>
				</li>
			</ul>
		</div>
	);
}
