const ex1 = `Write a function named \`fizzBuzz1()\` which takes a \`number\` argument and returns "**Fizz**" if the given number is divisible by 3, "**Buzz**" if the number is divisible by 5, and "**FizzBuzz**" if the number is divisible by both 3 and 5. Otherwise, it will return the number itself.
~~~js
fizzBuzz1(0)		-> "FizzBuzz"
fizzBuzz1(1)		-> 1
fizzBuzz1(3)		-> "Fizz"
fizzBuzz1(5)		-> "Buzz"
fizzBuzz1(30)		-> "FizzBuzz"
~~~`;

const ex2 = `Write a function named \`fizzBuzz2()\` which takes a \`number\` argument and returns and array consist of numbers starting from 1 to given number. However, it will return "**Fizz**" for the numbers divided by 3, "**Buzz**" for the numbers divided by 5, and "**FizzBuzz**" for the numbers divided both by 3 and 5.
~~~js
fizzBuzz2(3)	-> [ 1, 2, 'Fizz' ]
fizzBuzz2(5)	-> [ 1, 2, 'Fizz', 4, 'Buzz' ]
fizzBuzz2(10)	-> [ 1, 2, 'Fizz', 4, 'Buzz', 'Fizz', 7, 8, 'Fizz', 'Buzz' ]
fizzBuzz2(15)	-> [ 1, 2, 'Fizz', 4, 'Buzz', 'Fizz', 7, 8, 'Fizz', 'Buzz', 11, 'Fizz'. 13, 14,  'FizzBuzz' ]
~~~`;

const ex3 = `Write a function named \`findSumNumbers()\` which takes a \`string\` argument and returns sum of the all numbers appears in the string.
~~~js
findSumNumbers("abc$")			    -> 0
findSumNumbers("a1b4c  6#")		    -> 11
findSumNumbers("ab110c045d")		-> 155
~~~`;

const ex4 = `Write a function named \`findBiggestNumber()\` which takes a \`string\` argument and returns the biggest number appears in the string.
~~~js
findBiggestNumber("abc$")			-> 0
findBiggestNumber("a1b4c  6#")		-> 6
findBiggestNumber("ab110c045d")		-> 110
~~~`;

const ex5 = `Write a function named \`countOccurrencesOfCharacters()\` which takes a \`string\` argument and returns the count of repeated characters in the String.

**NOTE:** If given String is empty, then return empty String.

**NOTE:** It is case sensitive.
~~~js
countOccurrencesOfCharacters("")			-> ""
countOccurrencesOfCharacters("abc")		    -> "1a1b1c"
countOccurrencesOfCharacters("abbcca")		-> "1a2b2c1a"
~~~`;

const ex6 = `Write a function named \`fibonacciSeries1()\` which takes a \`number n\` argument and returns the n series of Fibonacci numbers as an array.

**REMEMBER:** Fibonacci series = 0, 1, 1, 2, 3, 5, 8, 13, 21
~~~js
fibonacciSeries1(3)		-> [0, 1, 1]
fibonacciSeries1(5)		-> [0, 1, 1, 2, 3]
fibonacciSeries1(7)		-> [0, 1, 1, 2, 3, 5, 8]
~~~`;

const ex7 = `Write a function named \`fibonacciSeries2()\` which takes a \`number n\` argument and returns the nth series of Fibonacci number as a number.

**REMEMBER:** Fibonacci series = 0, 1, 1, 2, 3, 5, 8, 13, 21
~~~js
fibonacciSeries2(2)		-> 1
fibonacciSeries2(4)		-> 2
fibonacciSeries2(8)		-> 13
~~~`;

const ex8 = `Write a function named \`findUniques()\` which takes two array of \`number\` arguments and returns the array which has only the unique values from both given arrays.

**NOTE:** If both arrays are empty, then return an empty array.

**NOTE:** If one of the array is empty, then return unique values from the other array.
~~~js
findUniques([], [])			                -> []
findUniques([], [1, 2, 3, 2])		        -> [1, 2, 3]
findUniques([1, 2, 3, 4], [3, 4, 5, 5])	    -> [1, 2, 5]
~~~`;

const ex9 = `Write a function named \`isPowerOf3()\` which takes a \`number\` argument and returns \`true\` if given number is equal to 3 power of the X. Otherwise, return \`false\`.

**NOTE:** Numbers that are power of 3 = 1, 3, 9, 27, 81, 243....

**NOTE:** Ignore negative scenarios.
~~~js
isPowerOf3(1)			-> true
isPowerOf3(2)			-> false
isPowerOf3(3)			-> true
isPowerOf3(27)		    -> true
isPowerOf3(100)		    -> false
~~~`;

const ex10 = `Write a function named \`parseData()\` which takes a \`string\` argument that contains keys in \`{}\` and values between \`}{\`. This function should return an object that has all the keys and values as entries.
~~~js
parseData("{104}LA{101}Paris{102}Berlin{103}Chicago{100}London")    -> { '100': 'London', '101': 'Paris', '102': 'Berlin', '103': 'Chicago', '104': 'LA' }
parseData("{schoolName}TechGlobal{Location}Des Plaines")            -> { schoolName: 'TechGlobal', Location: 'Des Plaines' }
~~~`;

const ex11 = `Write a function named \`calculateTotalPrice1()\` which takes an object of some shopping items with their quantities as key-value pairs and returns the total price of the given items based on the price list below.

1 Apple is $2.00

1 Orange is 3.29

1 Mango is $4.99

1 Pineapple $5.25
~~~js
calculateTotalPrice1({ apple: 3, mango: 1 }) 			        -> 10.99
calculateTotalPrice1({ apple: 2, pineapple: 1, orange: 3 })	    -> 19.12
calculateTotalPrice1({ apple: 0, mango: 0, orange: 0 }) 	    -> 0
~~~`;

const ex12 = `Write a function named \`calculateTotalPrice2()\` which takes an object of some shopping items with their quantities as key-value pairs and returns the total price of the given items based on the price list below.

1 Apple is $2.00

1 Orange is 3.29

1 Mango is $4.99

1 Pineapple $5.25
~~~js
calculateTotalPrice2({ Apple: 3, Mango: 5 }) 			        -> 24.96
calculateTotalPrice2({ Apple: 4, Mango: 8, Orange: 3 })	        -> 45.81
calculateTotalPrice2({ Apple: 0, Pineapple: 0, Orange: 0 }) 	-> 0
~~~`;

const ex13 = `Write a function named \`nthWord()\` which takes a \`string\` and a \`number\` arguments and returns the nth word in the string.

**Note:** Function should return empty string if the number argument is greater than the count of the words in the given string.
~~~js
nthWord("I like programming languages", 2) 		-> "like"
nthWord("QA stands for Quality Assurance", 4)	-> "Quality"
nthWord("Hello World", 3)				        -> ""
~~~`;

const ex14 = `Write a function named \`isArmstrong()\` which takes a \`number\` argument and returns \`true\` if given number is armstrong, return \`false\` otherwise.

**Note:** An armstrong number is a number that is equal to the sum of cubes of its digits

	- Let's take an example to understand it better. Consider the number 153.
	- To determine if 153 is an armstrong number, we need to check if the sum of its digits, each raised to the power of the number of digits, equals the original number.
	- 1^3 + 5^3 + 3^3 = 1 + 125 + 27 = 153
	- In this case, the sum of the individual digits raised to the power of 3 (the number of digits in 153) is equal to the original number, which means 153 is an armstrong number.

~~~js
isArmstrong(153) 		-> true
isArmstrong(123) 		-> false
isArmstrong(1634) 		-> true
~~~`;

const ex15 = `Write a function named \`reverseNumber()\` which takes a \`number\` argument and returns it back reversed without converting it to a String. 

**Note:** Do not convert number to string to complete the task.
~~~js
reverseNumber(371) 		-> 173
reverseNumber(123) 		-> 321
reverseNumber(12) 		-> 21
~~~`;

export const exercise03ExercisesData = [
	{
		title: 'Fizz Buzz 1',
		content: ex1,
	},
	{
		title: 'Fizz Buzz 2',
		content: ex2,
	},
	{
		title: 'Find Sum Numbers',
		content: ex3,
	},
	{
		title: 'Find Biggest Number',
		content: ex4,
	},
	{
		title: 'Count Occurrences Of Characters',
		content: ex5,
	},
	{
		title: 'Fibonacci Series 1',
		content: ex6,
	},
	{
		title: 'Fibonacci Series 2',
		content: ex7,
	},
	{
		title: 'Find Uniques',
		content: ex8,
	},
	{
		title: 'Is Power Of 3',
		content: ex9,
	},
	{
		title: 'Parse Data',
		content: ex10,
	},
	{
		title: 'Calculate Total Price 1',
		content: ex11,
	},
	{
		title: 'Calculate Total Price 2',
		content: ex12,
	},
	{
		title: 'Nth Word',
		content: ex13,
	},
	{
		title: 'Is Armstrong',
		content: ex14,
	},
	{
		title: 'Reverse Number',
		content: ex15,
	},
];
