import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import loginStudentIcon from './images/student.png';

export const Login = () => {
	const SELECT = 'select';
	const [email, setUsername] = useState('');
	const [pass, setPass] = useState('');
	const [message, setMessage] = useState('');
	const [lang, setLang] = useState(SELECT);

	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleLogin = (e) => {
		e.preventDefault();
		if (!email) {
			setMessage('Please enter your username!');
			return;
		} else if (!pass) {
			setMessage('Please enter your password!');
			return;
		} else if (email !== process.env.REACT_APP_LOGIN_EMAIL || pass !== process.env.REACT_APP_LOGIN_PASS) {
			setMessage('Email and password do not match!');
			return;
		} else {
			if (lang === SELECT) {
				setMessage('Please select a language!');
			} else {
				navigate(`/mock-interviews/${lang}`);
			}
		}
	};

	return (
		<div className={styles.loginPageWrapper}>
			<div className={styles.imageBox}>
				<img src={loginStudentIcon} alt='student' />
			</div>
			<div className={styles.loginForm}>
				<div className={styles.loginText}>
					<h2>Designed for TechGlobal Students</h2>
				</div>
				<form>
					<div>
						<label>Enter your username</label>
					</div>
					<div className={styles.inputOuterDiv}>
						<input type='email' value={email} onChange={(e) => setUsername(e.target.value)} />
					</div>
					<div>
						<label>Enter your password</label>
					</div>
					<div className={styles.inputOuterDiv}>
						<input type='password' value={pass} onChange={(e) => setPass(e.target.value)} />
					</div>
					<div className={styles.inputOuterDiv}>
						<label>Select a core language</label>
						<select value={lang} onChange={(e) => setLang(e.target.value)}>
							<option value={SELECT} disabled>
								-- Select --
							</option>
							{/* TODO: disabled until java mock created <option value='java'>Java</option> */}
							<option value='javascript'>JavaScript</option>
						</select>
					</div>
					<div className={styles.buttonOuterDiv}>
						<button type='submit' onClick={handleLogin}>
							Login
						</button>
					</div>
					<div className={styles.errorMessage}>{message && message.length > 0 && <p>{message}</p>}</div>
				</form>
			</div>
		</div>
	);
};
