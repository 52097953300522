import Countdown from 'react-countdown';

const Completionist = () => <span style={{ color: 'red' }}>00:00</span>;

export default function MockTimerBanner({ setShowDone, totalMockTime }) {
	const mTimer = localStorage.getItem('mTimer') ? parseInt(localStorage.getItem('mTimer')) : totalMockTime;

	const mockTimeRenderer = ({ minutes, seconds, total, completed }) => {
		// Set mock time each second
		localStorage.setItem('mTimer', total);
		// When timer complete
		if (completed) {
			setShowDone(true);
			return <Completionist />;
		} else {
			// Render a countdown
			return (
				<span>
					{('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}
				</span>
			);
		}
	};
	return <Countdown date={Date.now() + mTimer} renderer={mockTimeRenderer} zeroPadTime={2} />;
}
