import React, { useState, useEffect } from 'react';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookSquare,
	faInstagramSquare,
	faLinkedin,
	faSquareXTwitter,
	faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import logo from './images/TechGlobal-LOGO-white.png';
import { Link } from 'react-router-dom';

function currentYear() {
	return new Date().getFullYear();
}

const socials = [
	{ url: 'https://www.linkedin.com/in/techglobalschool', icon: faLinkedin },
	{ url: 'https://www.facebook.com/techglobalschool', icon: faFacebookSquare },
	{ url: 'https://www.instagram.com/techglobal.school', icon: faInstagramSquare },
	{ url: 'https://x.com/techglobalschl', icon: faSquareXTwitter },
	{ url: 'https://www.youtube.com/@techglobalschool', icon: faYoutubeSquare },
];

function Footer() {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth <= 768);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<footer className={`${styles.footer}`}>
			<div className={`${styles.container}`}>
				<div className={styles.up}>
					<div className={styles.left}>
						<figure>
							<img src={logo} alt='Tech Global Logo' className={`${styles.logo}`} />
						</figure>
						{!isMobile && (
							<div className={styles.socials}>
								{socials.map(({ url, icon }, index) => (
									<a href={url} target='_blank' rel='noreferrer' key={index}>
										<FontAwesomeIcon
											icon={icon}
											color='white'
											style={{ width: '26px', height: '28px' }}
										/>
									</a>
								))}
							</div>
						)}
					</div>
					<div className={styles.right}>
						<div className={styles.links}>
							<div className={styles.testing}>
								<ul>
									<p className='has-text-white'>
										<strong className='has-text-white'>Testings</strong>
									</p>
									<li>
										<a className='has-text-white' href='/frontend'>
											Frontend Testing
										</a>
									</li>
									<li>
										<a className='has-text-white' href='/backend'>
											Backend Testing
										</a>
									</li>
								</ul>
							</div>
							<div className={styles.exercises}>
								<ul>
									<p className='has-text-white'>
										<strong className='has-text-white'>Exercises</strong>
									</p>
									<li>
										<a className='has-text-white' href='/java-exercises'>
											Java Exercises
										</a>
									</li>
									<li>
										<a className='has-text-white' href='/js-exercises'>
											JS Exercises
										</a>
									</li>
								</ul>
							</div>
						</div>
						<Link to='/login'>
							<button className={`button is-white ${styles.button}`}>Mock Interviews</button>
						</Link>
					</div>
				</div>
				<div className={styles.down}>
					{isMobile && (
						<div className={styles.socials}>
							{socials.map(({ url, icon }, index) => (
								<a href={url} target='_blank' rel='noreferrer' key={index}>
									<FontAwesomeIcon
										icon={icon}
										color='white'
										style={{ width: '20.8px', height: '22.4px' }}
									/>
								</a>
							))}
						</div>
					)}
					<div className={styles.address}>
						<p>2800 S River Rd Suite 310, Des Plaines, IL 60018</p>
					</div>
					<div className={styles.copyright}>
						<p>© {currentYear()} TechGlobal - All Rights Reserved</p>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
