import React, { useState } from 'react';
import styles from './Accordion.module.css';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentGenerator from '../Partials/ContentGenerator';

const Accordion = ({ dataToAccordion }) => {
	const { title, content, difficulty } = dataToAccordion;
	const [isActive, setIsActive] = useState(false);

	let difStyle = {
		width: '50%',
		minWidth: '50px',
		margin: '0 auto',
		padding: '2px 6px',
		backgroundColor: 'rgba(255, 255, 255)',
		textAlign: 'center',
		borderRadius: '10px',
	};

	if (difficulty === 'Easy') {
		difStyle = { ...difStyle, color: 'green' };
	} else if (difficulty === 'Medium') {
		difStyle = { ...difStyle, color: 'orange' };
	} else {
		difStyle = { ...difStyle, color: 'red' };
	}
	return (
		<div className={styles.accordionItem}>
			<div className={styles.accordionTitle} onClick={() => setIsActive(!isActive)}>
				<div className={styles.title}>{title}</div>

				{difficulty && (
					<div className={styles.difficultyWrapper}>
						<p style={difStyle}>{difficulty}</p>
					</div>
				)}

				<div className={styles.arrow}>
					{isActive ? (
						<FontAwesomeIcon icon={faAngleDown} size='sm' />
					) : (
						<FontAwesomeIcon icon={faAngleUp} size='sm' />
					)}
				</div>
			</div>
			{isActive && (
				<div className={styles.accordionContent}>
					{<ContentGenerator title={title} taskDetails={content} />}
				</div>
			)}
		</div>
	);
};

export default Accordion;
