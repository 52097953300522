import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import './Calendar.css';

const Calendar = () => {
	const [value, onChange] = useState(new Date());
	const [isShown, setIsShown] = useState(false);

	const dateValue = (date) => {
		setIsShown(true);
		onChange(date);
		console.log(date);
	};

	return (
		<div>
			<DatePicker
				onChange={dateValue}
				minDate={new Date()}
				value={value}
				monthPlaceholder='mm'
				dayPlaceholder='dd'
				yearPlaceholder='yyyy'
			/>
			<br />
			<br />
			<br />
			<h2 id='result_id'>Result:</h2>

			{isShown && (
				<p style={{ color: 'var(--mainBlue)' }} id='result'>
					{value === null ? 'Please select a date.' : `You have selected ${value.toDateString()}.`}
				</p>
			)}
		</div>
	);
};

export default Calendar;
