const ex1 = `Write a function named as \`first()\` which takes an \`array\` as an argument and returns the first element when invoked.

**NOTE**: Assume you will not be given an empty array and it contains only \`number\` elements.

~~~js
first([3, 5, 8])   -> 3
first([10, -5])    -> 10
first([0])         -> 0
~~~`;

const ex2 = `Write a function named as \`last()\` which takes an \`array\` as an argument and returns the last element when invoked.

**NOTE**: Assume you will not be given an empty array and it contains only \`string\` elements.

~~~js
last(["Tech", "Global"])      -> "Global"
last(["Tic", "Tac", "Toe"])	  -> "Toe"
last(["Apple"])        	      -> "Apple"
~~~`;

const ex3 = `Write a function named as \`noNegatives()\` which takes an \`array\` as an argument and returns it back with all negative elements replaced with zero when invoked.

**NOTE**: Assume you will not be given an empty array and it contains only \`number\` elements.

~~~js
noNegatives([3, -5, 8])    	  -> [3, 0, 8]
noNegatives([-10])	          -> [0]
noNegatives([-2, -3, 7])  	  -> [0, 0, 7]\`
~~~`;

const ex4 = `Write a function named as \`swapEnds()\` which takes an \`array\` as an argument and returns it back with the first and last elements replaced with each other when invoked.

**NOTE**: Assume you will not be given an empty array it contains only \`string\` elements.

~~~js
swapEnds(["Tech", "Global"])     -> ["Global", "Tech"]
swapEnds(["Tic", "Tac", "Toe"])  -> ["Toe", "Tac", "Tic"]
swapEnds(["Apple"])         	 -> ["Apple"]
~~~`;

const ex5 = `Write a function named as \`reverseArr()\` which takes an \`array\` as an argument and returns it back reversed when invoked.

**NOTE**: Assume you will not be given an empty array it contains only \`number\` elements.

~~~js
reverseArr([3, 5, 8])           -> [8, 5, 3]
reverseArr([10])    	        -> [10]
reverseArr([-2, -3, 7, 10])     -> [10, 7, -3, -2]
~~~`;

const ex6 = `Write a function named as \`sumFirstLast()\` which takes an \`array\` as an argument and returns the sum of first and last elements of the array when invoked.

**NOTE**: Assume you will not be given an empty array it contains only \`number\` elements.

~~~js
sumFirstLast([3, 5, 8])           -> 11
sumFirstLast([10]) 	        	  -> 20
sumFirstLast([-2, -3, 7, 10])     -> 8
~~~`;

const ex7 = `Write a function named as \`has10()\` which takes an \`array\` as an argument and returns \`true\` if the array has an element equal to 10 or returns \`false\` otherwise when invoked.

**NOTE**: Assume you will not be given an empty array it contains only \`number\` elements.
~~~js
has10([3])  	        -> false
has10([-10, 0, 7])	    -> false
has10([ 7, 10])     	-> true
~~~`;

const ex8 = `Write a function named as \`hasPositive()\` which takes an \`array\` as an argument and returns \`true\` if the array has an element more than zero, or returns \`false\` otherwise when invoked.

**NOTE**: Assume you will not be given an empty array it contains only \`number\` elements.

~~~js
hasPositive([0, 0])	   	    -> false
hasPositive([-10, 0, -5])  	-> false
hasPositive([ 3, -5])       -> true
~~~`;

const ex9 = `Write a function named as \`hasNegative()\` which takes an \`array\` as an argument and returns \`true\` if the array has an element less than zero, or returns \`false\` otherwise when invoked.

**NOTE**: Assume you will not be given an empty array it contains only \`number\` elements.

~~~js
hasNegatives([0, 0])       	-> false
hasNegatives([10, 0, 7]) 	-> false
hasNegatives([ 3, -5])     	-> true
~~~`;

const ex10 = `Write a function named as \`multipleOfFive()\` which takes an \`array\` as an argument and returns \`true\` if all the array elements are multiple of 5, or returns \`false\` otherwise when invoked.

**NOTE**: Assume you will not be given an empty array and it contains only \`number\` elements.

~~~js
multipleOfFive([5, 10, 15, 0, -5])	    -> true
multipleOfFive([-10, 0, 7])	            -> false
multipleOfFive([ 7, 2])    	        	-> false
~~~`;

const ex11 = `Write a function named as \`replaceLong()\` which takes an \`array\` as an argument and returns it back with all elements has length more than four replaced with empty \`string\` when invoked.

**NOTE**: Assume you will not be given an empty array and it contains only \`string\` elements.

~~~js
replaceLong(["Tech", "Global"])     	-> ["Tech", ""]
replaceLong(["One", "Two", "Three"]) 	-> ["One", "Two", ""]
replaceLong(["Apple"])        	        -> [""]
~~~`;

const ex12 = `Write a function named as \`hasA()\` which takes an \`array\` as an argument and returns \`true\` if any element in the array starts with A-a, returns \`false\` otherwise when invoked.

**NOTE**: Assume you will not be given an empty array and it contains only \`string\` elements.

~~~js
hasA(["Tech", "Global"])    -> false
hasA(["", "abc", "xyz"])    -> true
hasA(["Apple"])          	-> true
~~~`;

const ex13 = `Write a function named as \`noEmpty()\` which takes an \`array\` as an argument and returns \`true\` if all the elements in the array are not empty, or returns \`false\` otherwise when invoked.

**NOTE**: Assume you will not be given an empty array and it contains only \`string\` elements.

~~~js
noEmpty(["Tech", "Global"])     -> true
noEmpty(["", "abc", "xyz"])  	-> false
noEmpty(["Apple"])          	-> true
~~~`;

export const jsArrays = [
	{
		title: 'First Element',
		content: ex1,
	},
	{
		title: 'Last Element',
		content: ex2,
	},
	{
		title: 'No Negatives',
		content: ex3,
	},
	{
		title: 'Swap Ends',
		content: ex4,
	},
	{
		title: 'Reverse Array',
		content: ex5,
	},
	{
		title: 'Sum First Last',
		content: ex6,
	},
	{
		title: 'Has Ten',
		content: ex7,
	},
	{
		title: 'Has Positive',
		content: ex8,
	},
	{
		title: 'Has Negative',
		content: ex9,
	},
	{
		title: 'Multiple of Five',
		content: ex10,
	},
	{
		title: 'Replace Long Words',
		content: ex11,
	},
	{
		title: 'Has Word Starts With A',
		content: ex12,
	},
	{
		title: 'No Empty Element',
		content: ex13,
	},
];
