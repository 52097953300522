import React from 'react';
import styles from './MultipleWindows.module.css';

const MultipleWindows = () => {
	return (
		<div className={styles.wrapper}>
			<ul>
				<li>
					<a className={styles.link} id='apple' href='https://www.apple.com' target='_blank' rel='noreferrer'>
						Apple
					</a>
				</li>
				<li>
					<a
						className={styles.link}
						id='microsoft'
						href='https://www.microsoft.com'
						target='_blank'
						rel='noreferrer'
					>
						Microsoft
					</a>
				</li>
				<li>
					<a className={styles.link} id='tesla' href='https://www.tesla.com' target='_blank' rel='noreferrer'>
						Tesla
					</a>
				</li>
			</ul>
		</div>
	);
};

export default MultipleWindows;
