const ex1 = `Write a method named \`doubleWord()\` which takes a \`String\` word as an argument and returns the given word doubled back as a \`String\` when invoked.  
**NOTE:** Assume you will not be given an empty word.
~~~java
doubleWord("Tech")	 -> "TechTech"
doubleWord("Global") -> "GlobalGlobal"
~~~`;

const ex2 = `Write a method named \`firstCharacter()\` which takes a \`String\` word as an argument and returns the first character of the given word as a \`char\` when invoked.  
**NOTE:** Assume you will not be given an empty word.
~~~java
firstCharacter("Tech")	 -> 'T'
firstCharacter("Global") -> 'G'
~~~`;

const ex3 = `Write a method named \`firstTwoCharacter()\` which takes a \`String\` word as an argument and returns the first two characters of the given word as a \`String\` when invoked.  
**NOTE:** if the given word does not have 2 or more characters, then return the \`String\` back.  
~~~java
firstTwoCharacter("Tech")	-> "Te"
firstTwoCharacter("Global") -> "Gl"
~~~`;

const ex4 = `Write a method named \`lastCharacter()\` which takes a \`String\` word as an argument and returns the last character of the given word as a \`char\` when invoked.  
**NOTE:** Assume you will not be given an empty word.
~~~java
lastCharacter("Tech")	-> 'h'
lastCharacter("Global") -> 'l'
~~~`;

const ex5 = `Write a method named \`lastTwoCharacter()\` which takes a \`String\` word as an argument and returns the last two characters of the given word as a \`String\` when invoked.  
**NOTE:** if the given word does not have 2 or more characters, then return the \`String\` back.
~~~java
lastTwoCharacter("Tech")   -> "ch"
lastTwoCharacter("Global") -> "al"
~~~`;

const ex6 = `Write a method named \`firstLast()\` which takes a \`String\` word as an argument and returns the first and  last characters of the given word as a \`String\` when invoked.  
**NOTE:** if the given word does not have 2 or more characters, then return the \`String\` back.
~~~java
firstLast("Tech")		-> "Th"
firstLast("TechGlobal") -> "Tl"
~~~`;

const ex7 = `Write a method named \`hasFive()\` which takes a \`String\` word as an argument and returns true if given \`String\` has at least 5 characters, and \`false\` otherwise when invoked.  
~~~java
hasFive("Tech")	  -> false
hasFive("Global") -> true
~~~`;

const ex8 = `Write a method named \`middle()\` which takes a \`String\` word as an argument and returns the middle character if the \`String\` has odd length, and returns the middle two characters if the \`String\` has even length as a \`String\` when invoked.  
**NOTE:** if the given word is empty, then return the empty \`String\` back.
~~~java
middle("Tech")	 -> "ec"
middle("Global") -> "ob"
middle("abcde")	 -> "c"
~~~`;

const ex9 = `Write a method named \`longer()\` which takes two \`String\` words as arguments and returns the \`String\` has more characters when invoked.  
**NOTE:**  if both of the words have the same length, then return the first \`String\`
~~~java
longer("Tech", "Global") -> "Global"
longer("Hello", "Hi")	 -> "Hello"
longer("Hello", "World") -> "Hello"
~~~`;

const ex10 = `Write a method named \`shorter()\` which takes two \`String\` words as arguments and returns the \`String\` has less characters when invoked.  
**NOTE:**  if both of the words have the same length, then return the second \`String\`
~~~java
shorter("Tech", "Global") -> "Tech"
shorter("Hello", "Hi")	 -> "Hi"
shorter("Hello", "World") -> "World"
~~~`;

const ex11 = `Write a method named \`concat()\` which takes two \`String\` words as arguments and returns the words concatenated when invoked.  
**NOTE:** Concatenation should always be as first \`String\` + second \`String\`
~~~java
concat("Tech", "Global") -> "TechGlobal"
concat("Hello", "World") -> "HelloWorld"
concat("", "Abc")		 -> "Abc"
~~~`;

const ex12 = `Write a method named \`startsVowel()\` which takes a \`String\` word as an argument and returns \`true\` if given \`String\` starts with a vowel letter, and \`false\` otherwise when invoked.  
**NOTE:** Vowel letters are A, E, O, U, I, a, e, o, u, i.
~~~java
startsVowel("Tech")	 -> false
startsVowel("Apple") -> true
startsVowel("abc")	 -> true
~~~`;

export const stringExercisesData = [
	{
		title: 'Double The Word',
		content: ex1,
	},
	{
		title: 'First Character',
		content: ex2,
	},
	{
		title: 'First Two Characters',
		content: ex3,
	},
	{
		title: 'Last Character',
		content: ex4,
	},
	{
		title: 'Last Two Characters',
		content: ex5,
	},
	{
		title: 'First and Last Characters',
		content: ex6,
	},
	{
		title: 'Has Five',
		content: ex7,
	},
	{
		title: 'Middle',
		content: ex8,
	},
	{
		title: 'Longer String',
		content: ex9,
	},
	{
		title: 'Shorter String',
		content: ex10,
	},
	{
		title: 'Concat Two String',
		content: ex11,
	},
	{
		title: 'Starts With Vowel',
		content: ex12,
	},
];
