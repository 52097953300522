export default function Checkboxes() {
	return (
		<div>
			<div id='checkbox-button-group_1'>
				<div>
					<label className='checkbox'>
						<input className='mr-2' type='checkbox' id='checkbox_1'></input>
						Apple
					</label>
				</div>
				<div>
					<label className='checkbox'>
						<input className='mr-2' type='checkbox' id='checkbox_2'></input>
						Microsoft
					</label>
				</div>
			</div>
			<h2 id='secondary_heading' className='title is-4 pt-6 mb-2'>
				Practice more
			</h2>
			<div id='checkbox-button-group_2'>
				<div>
					<label className='checkbox'>
						<input className='mr-2' type='checkbox' id='checkbox_3'></input>
						Tesla
					</label>
				</div>
				<div>
					<label className='checkbox'>
						<input className='mr-2' type='checkbox' id='checkbox_4'></input>
						SpaceX
					</label>
				</div>
			</div>
		</div>
	);
}
