import React, { useState, useId } from 'react';
import { ReactSortable } from 'react-sortablejs';
import Button from '../Partials/Buttons';
import styles from './Actions.module.css';

const BasicSwap = () => {
	const [state, setState] = useState([
		{ id: useId(), name: 'Drag Me', cssid: 'drag_element', draggable: true },
		{ id: useId(), name: 'Drop Here', cssid: 'drop_element', draggable: false },
	]);
	const [swapped, setSwapped] = useState('');
	const m4 = 'An element dropped here!';

	return (
		<div className={styles.colmn}>
			<ReactSortable
				group='groupName'
				animation={200}
				delayOnTouchStart={true}
				delay={2}
				list={state}
				setList={setState}
				onStart={() => setSwapped('')}
				onEnd={() => setSwapped(m4)}
			>
				{state.map((item) => (
					<Button key={item?.id} children={item?.name} draggable={item?.draggable} id={item?.cssid} />
				))}
			</ReactSortable>
			<p id='drag_and_drop_result'>{swapped}</p>
		</div>
	);
};

export default BasicSwap;
