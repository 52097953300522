import { useState } from 'react';
import Accordion from '../../../../components/Accordion/Accordion';
import { frontEndProject6TestCases as data } from './frontEndProjectData';
import Button from '../Partials/Buttons';
import styles from './Projects.module.css';
import { faCircleCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Project6 = () => {
	const [newTodo, setNewTodo] = useState('');
	const [todoList, setTodoList] = useState(
		localStorage.getItem('todoList') ? JSON.parse(localStorage.getItem('todoList')) : []
	);
	const [searchTxt, setSearchTxt] = useState('');
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [lengthError, setLengthError] = useState('');

	function handleSetTodoList(newTodoList) {
		setTodoList(newTodoList);
		localStorage.setItem('todoList', JSON.stringify(newTodoList));
		setIsDuplicate(false);
	}

	function addTodo(e) {
		e.preventDefault();
		if (newTodo.length > 30) {
			setLengthError('Error: Todo cannot be more than 30 characters!');
			setIsDuplicate(false);
		} else {
			setLengthError('');
			if (newTodo !== '') {
				if (todoList.some((todoItem) => todoItem.todo === newTodo)) {
					setIsDuplicate(true);
				} else {
					setIsDuplicate(false);
					handleSetTodoList([...todoList, { todo: newTodo, isActive: true }]);
				}
			}
		}
	}

	function deleteTodo(todoToRemove) {
		const newTodoList = todoList.filter((todoItem) => {
			return todoItem.todo !== todoToRemove;
		});
		handleSetTodoList(newTodoList);
	}

	function handleStrikethrough(idx) {
		const newTodoList = [...todoList];
		newTodoList[idx].isActive = !newTodoList[idx].isActive;
		newTodoList[idx].completed = !newTodoList[idx].completed;
		handleSetTodoList(newTodoList);
	}

	// Remove items that are checked (isActive is false)
	function removeCheckedItems() {
		const newTodoList = todoList.filter((todoItem) => todoItem.isActive);
		handleSetTodoList(newTodoList);
	}

	let filteredTodos = todoList.filter((todoItem) => todoItem.todo?.toLowerCase().includes(searchTxt?.toLowerCase()));
	const isAnyItemChecked = todoList.some((todoItem) => !todoItem.isActive);

	return (
		<div>
			<div className='content'>
				<p>
					This project aims to enhance students' understanding of frontend automation, specifically in the
					context of form submission and date-picking processes.
				</p>
				<ul>
					<li>
						Through this project, students will gain hands-on experience in identifying web elements related
						to scheduling and manipulating input fields.
					</li>
					<li>
						Additionally, they will develop expertise in validating form submissions and conducting both
						positive and negative testing on these functions.
					</li>
				</ul>
				<p>
					Students are expected to automate all the provided test cases and submit their work to the
					TechGlobal Instructor Team. This project will prove to be both a fulfilling and educational
					opportunity for students to broaden their knowledge and skills!
				</p>
			</div>
			{data.map((testCase, idx) => (
				<Accordion dataToAccordion={testCase} key={idx} />
			))}
			<br />
			<section className='section m-auto has-background-white-ter pt-4 pb-7'>
				<div className='mb-5'>
					<h1 className='is-size-3' style={{ color: 'var(--mainBlue)' }}>
						TODO List
					</h1>
				</div>
				<div className='columns is-centered'>
					<div className='column is-half'>
						<nav className='panel'>
							<p className='panel-heading has-text-white' style={{ backgroundColor: 'var(--mainBlue)' }}>
								My Tasks
							</p>
							<div className={styles.newTodo}>
								<div className='block m-auto'>
									<form className='field is-grouped'>
										<div className='control is-expanded'>
											<input
												className='input is-info new-todo'
												type='text'
												placeholder='New todo'
												id='input-add'
												onChange={(e) => {
													setNewTodo(e.target.value.trim());
													setIsDuplicate(false);
												}}
											/>
										</div>
										<div className='control'>
											<Button
												className={styles.addBtn}
												children='ADD'
												id='add-btn'
												onClick={(e) => addTodo(e)}
											/>
										</div>
									</form>
								</div>
							</div>
							<div className='panel-block control'>
								<p className='control'>
									<input
										className='input is-info'
										type='text'
										placeholder='Type to search'
										id='search'
										onChange={(e) => {
											setSearchTxt(e.target.value);
											setIsDuplicate(false);
										}}
									/>
								</p>
							</div>
							{filteredTodos.length > 0 ? (
								<div id='panel'>
									{filteredTodos.map((todoItem, idx) => (
										<div className={`panel-block todo-item ${styles.hover}`} key={idx}>
											<div
												className='mr-auto ml-1 toggle is-flex is-align-items-center'
												onClick={() => handleStrikethrough(idx)}
											>
												<span
													className={`panel-icon ${
														todoItem.isActive ? '' : 'has-text-success'
													}`}
												>
													<FontAwesomeIcon icon={faCircleCheck} />
												</span>
												<span
													style={{
														textDecoration: todoItem.isActive ? '' : 'line-through',
													}}
												>
													{todoItem.todo}
												</span>
											</div>
											<span
												className='panel-icon has-text-danger destroy'
												onClick={() => deleteTodo(todoItem.todo)}
											>
												<FontAwesomeIcon icon={faTrashAlt} />
											</span>
										</div>
									))}
								</div>
							) : (
								<div className='panel-block todo-item ml-1 has-text-danger'>
									<p>No tasks found!</p>
								</div>
							)}
							{isDuplicate && (
								<div className='notification is-danger'>
									<button className='delete' onClick={() => setIsDuplicate(false)}></button>
									Error: You already have <strong>{newTodo}</strong> in your todo list.
								</div>
							)}
							{isAnyItemChecked && (
								<div className={`has-text-centered pt-2 pb-2`}>
									<button id='clear' class='button is-danger' onClick={removeCheckedItems}>
										Remove completed tasks!
									</button>
								</div>
							)}
							{lengthError && <div className='notification is-danger'>{lengthError}</div>}
						</nav>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Project6;
