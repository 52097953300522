import './App.css';
import SubPageLayout from './app/frontend-testing/pages/SubPageLayout/SubPageLayout';
import { Outlet } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import CustomHelmet from './utils/CustomHelmet';
import React from 'react';
import FallbackSpinner from './components/FallbackSpinner';

function App() {
	const pageTitle = 'TechGlobal Training | Home';
	return (
		<React.Suspense fallback={<FallbackSpinner />}>
			<div className='App'>
				<CustomHelmet titleText={pageTitle} />
				<Header />
				<SubPageLayout>
					<Outlet />
				</SubPageLayout>
				<Footer />
			</div>
		</React.Suspense>
	);
}

export default App;
