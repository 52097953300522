export default function Project8Instructions() {
	return (
		<div className='content'>
			<p>
				The goal of this project is to provide students with hands-on experience in automating the validation of
				an ecommerce website's frontend functionalities.
			</p>
			<ul>
				<li>
					Through this project, students will learn to navigate through web pages, identify and interact with
					web elements, and validate the correctness of various sections such as the Available Courses and
					Cart
				</li>
				<li>
					Through a series of test cases, students will gain proficiency in writing robust automation scripts
					to ensure the integrity and functionality of the website.
				</li>
				<li>
					Upon completion of this project, students will have honed their skills in automating web testing
					processes, thereby preparing them for real-world scenarios in software testing and quality
					assurance.
				</li>
			</ul>
			<p>
				Students are expected to automate all the provided test cases and submit their work to the TechGlobal
				Instructor Team. This project offers a valuable opportunity for students to showcase their automation
				testing capabilities and enhance their career prospects in the field of software testing.
			</p>
		</div>
	);
}
