import { useState } from 'react';
import Accordion from '../../../../components/Accordion/Accordion';
import { frontEndProject1TestCases as data } from './frontEndProjectData';

const Project1 = () => {
	const [submitted, setSubmitted] = useState(false);

	function handleSubmit(event) {
		event.preventDefault();
		setSubmitted(true);
	}

	return (
		<div>
			<div className='content'>
				<p>
					Through this project, students can practice locating web elements, filling out forms with their
					input elements, and validating a form submission.
				</p>
				<ul>
					<li>
						Through this project, students can practice locating web elements, filling out forms with their
						input elements, and validating a form submission.
					</li>
					<li>
						Additionally, students will gain experience working with input labels, placeholders, texts, and
						other HTML attributes.
					</li>
				</ul>
				<p>
					Students are expected to automate all of the provided test cases and submit their work to the
					TechGlobal Instructor Team. We hope that you find this project to be an enjoyable and valuable
					learning experience!
				</p>
			</div>
			{data.map((testCase, idx) => (
				<Accordion dataToAccordion={testCase} key={idx} />
			))}
			<br />
			<div className='has-background-white-ter pt-6 pr-6 pl-6 pb-4'>
				<div className='mb-5'>
					<h1 className='is-size-3' style={{ color: 'var(--mainBlue)' }}>
						Contact Us
					</h1>
					<p id='address'>2800 S River Rd Suite 310, Des Plaines, IL 60018</p>
					<p id='email'>info@techglobalschool.com</p>
					<p id='phone-number'>(224) 580-2150</p>
				</div>
				<form onSubmit={handleSubmit}>
					<div className='field'>
						<label htmlFor='name' className='label'>
							Full name *
						</label>
						<div className='control'>
							<input className='input' type='text' placeholder='Enter your full name' required />
						</div>
					</div>

					<div className='field'>
						<div className='control'>
							<label className='label'>Gender *</label>
							<label className='radio'>
								<input type='radio' name='question' className='mr-1' required />
								Male
							</label>
							<label className='radio ml-0'>
								<input type='radio' name='question' className='mr-1' />
								Female
							</label>
							<label className='radio ml-0'>
								<input type='radio' name='question' className='mr-1' />
								Prefer not to disclose
							</label>
						</div>
					</div>

					<div className='field'>
						<label className='label'>Address</label>
						<div className='control'>
							<input className='input' type='text' placeholder='Enter your address' />
						</div>
					</div>

					<div className='field'>
						<label className='label'>Email *</label>
						<div className='control'>
							<input className='input' type='email' placeholder='Enter your email' required />
						</div>
					</div>

					<div className='field'>
						<label className='label'>Phone</label>
						<div className='control'>
							<input className='input' type='phone' placeholder='Enter your phone number' />
						</div>
					</div>

					<div className='field'>
						<label className='label'>Message</label>
						<div className='control'>
							<textarea className='textarea' placeholder='Type your message here...'></textarea>
						</div>
					</div>

					<div className='field'>
						<div className='control'>
							<label className='checkbox'>
								<input type='checkbox' required /> I give my consent to be contacted.
							</label>
						</div>
					</div>

					<div className='field is-grouped'>
						<div className='control'>
							<button className='button is-link' type='submit' onClick={() => handleSubmit()}>
								SUBMIT
							</button>
						</div>
					</div>
					{submitted && <strong className='mt-5'>Thanks for submitting!</strong>}
				</form>
			</div>
		</div>
	);
};

export default Project1;
