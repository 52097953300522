const ex1 = `Write a method named as \`noDigits()\` which takes a \`String\`word as an argument
and returns the given word back with all the digits removed when invoked.
~~~java
noDigits("Tech")	   -> "Tech"
noDigits("123 Street") -> " Street"
noDigits("A1b2c340")   -> "Abc"
~~~`;

const ex2 = `Write a method named as \`noLetters()\` which takes a \`String\`word as an argument
and returns the given word back with all the letters removed when invoked.
~~~java
noLetters("Tech")	    -> ""
noLetters("123 Street") -> "123 "
noLetters("A1b2 c340")  -> "12 340"
~~~`;

const ex3 = `Write a method named as \`noVowels()\` which takes a \`String\`word as an argument
and returns the given word back with all the vowel letters removed when invoked.  
**NOTE:** Vowel letters are A, E, O, U, I, a, e, o, u, i
~~~java
noVowels("TechGlobal")	-> "TchGlbl"
noVowels("Hello World") -> "Hll Wrld"
noVowels("12345") 		-> "12345"
~~~`;

const ex4 = `Write a method named as \`noConsonants()\` which takes a \`String\`word as an argument
and returns the given word back with all the consonant letters removed when invoked.  
**NOTE:** Vowel letters are A, E, O, U, I, a, e, o, u, i
~~~java
noConsonants("TechGlobal")	-> "eoa"
noConsonants("Hello World") -> "eo o"
noConsonants("12345") 		-> "12345"
~~~`;

const ex5 = `Write a method named as \`noExtraSpaces()\` which takes a \`String\`word as an argument
and returns the given word back with all the redundant spaces removed when invoked.
~~~java
noExtraSpaces("  Tech      Global    School  ")	-> "Tech Global School"
noExtraSpaces("Hello World") 			        -> "Hello World"
noExtraSpaces("12345") 				            -> "12345"
~~~`;

const ex6 = `Write a method named as \`isPhoneNumberValid()\` which takes a \`String\`word as an argument
and returns \`true\` if the given phone number follows the format \`(000) 000-0000\`, or returns \`false\`
otherwise when invoked.
~~~java
isPhoneNumberValid("(312) 123-1234") -> true
isPhoneNumberValid("312-123-1234")   -> false
isPhoneNumberValid("(312)-123-1234") -> false
isPhoneNumberValid("312.123.1234")   -> false
~~~`;

const ex7 = `Write a method named as \`isSNNValid()\` which takes a \`String\` ssn as an argument and returns
\`true\` if the given ssn follows the format \`000-00-0000\`, or returns \`false\` otherwise when invoked.
~~~java
isSSNValid("123-12-1234") -> true
isSSNValid("123 12 1234") -> false
isSSNValid("123/12/1234") -> false
~~~`;

const ex8 = `Write a method named as \`isZIPValid()\` which takes a \`String\` zip code as an argument and returns
\`true\` if the given  zip code follows the format \`00000\` or \`00000-0000\`, or returns \`false\` otherwise when invoked.
~~~java
isZIPValid("12345")       -> true
isZIPValid("12345-1234")  -> true
isZIPValid("1234")        -> false
isZIPValid("12345- 1234") -> false
~~~`;

export const regexExercisesData = [
	{
		title: 'Remove Digits',
		content: ex1,
	},
	{
		title: 'Remove Letters',
		content: ex2,
	},
	{
		title: 'Remove Vowels',
		content: ex3,
	},
	{
		title: 'Remove Consonants',
		content: ex4,
	},
	{
		title: 'Remove Extra Spaces',
		content: ex5,
	},
	{
		title: 'Validate the Phone Number Format',
		content: ex6,
	},
	{
		title: 'Validate the SSN Format',
		content: ex7,
	},
	{
		title: 'Validate the ZIP CODE Format',
		content: ex8,
	},
];
