import { Helmet, HelmetProvider } from 'react-helmet-async';

// For now implement title/meta only
const CustomHelmet = ({ titleText }) => {
	return (
		<HelmetProvider>
			<Helmet>
				<meta charSet='utf-8' />
				<title>{titleText}</title>
			</Helmet>
		</HelmetProvider>
	);
};

export default CustomHelmet;
