import { useLocation } from 'react-router-dom';
import { MockInnerPage } from './MockInnerPage';
import MOCK_QUESTIONS from './MockQuestions';

const TOTAL_MOCK_TIMES = {
	1: 30 * 60 * 1000,
	2: 45 * 60 * 1000,
	3: 45 * 60 * 1000,
	4: 45 * 60 * 1000,
	5: 30 * 60 * 1000,
};

const MockInnerPageController = () => {
	let location = useLocation();
	const pageRenderDecider = location.pathname.slice(location.pathname.length - 1);

	// In case no page url match
	if (!pageRenderDecider || !['1', '2', '3', '4', '5'].includes(pageRenderDecider)) {
		<div>Mock Page not found. Please try again later!</div>;
	}

	const getMockTotalTime = () => {
		return TOTAL_MOCK_TIMES[Number.parseInt(pageRenderDecider)];
	};

	const getMockQuestions = () => {
		return MOCK_QUESTIONS[`MOCK_QUESTIONS_${pageRenderDecider}`];
	};

	return (
		<MockInnerPage
			ALL_QUESTIONS={getMockQuestions()}
			mockTitle={`Mock Interview ${pageRenderDecider}`}
			totalMockTime={getMockTotalTime()}
		/>
	);
};

export default MockInnerPageController;
