import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HeroBanner.module.css';

const HeroBanner = () => {
	return (
		<div className={styles.heroBanner}>
			<div className={styles.container}>
				<div>
					<div className={styles.heading}>
						<h1>Welcome to TechGlobal </h1>
						<h1>School Training</h1>
						<p>
							This website is created by TechGlobal School Alumni who completed the 6 months of the
							Software Engineer Program with the guidance of founders and instructors.
						</p>
					</div>

					<div className={styles.seeProgram}>
						<div className={styles.btnLink}>
							<a href='https://www.techglobalschool.com/courses/' target={'_blank'} rel='noreferrer'>
								See Our Programs
							</a>
							<FontAwesomeIcon icon={faArrowRight} size='sm' />
						</div>
						<p>Be Part of TechGlobal Today </p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroBanner;
