import React from 'react';
import styles from './Button.module.css';

/*
   padding: 5px 10px;
*/

const Button = React.forwardRef(
	(
		{
			className = null,
			id = null,
			onClick = null,
			onDoubleClick = null,
			onContextMenu = null,
			onTouchStart = null,
			type = null,
			children = null,
			style = {},
			draggable = false,
			key = null,
			disabled = false,
		},
		ref
	) => {
		return (
			<button
				className={`${styles.c_button} ${className}`}
				id={id}
				onClick={onClick}
				onDoubleClick={onDoubleClick}
				onContextMenu={onContextMenu}
				onTouchStart={onTouchStart}
				type={type}
				ref={ref}
				style={style}
				draggable={draggable}
				key={key}
				disabled={disabled}
			>
				{children}
			</button>
		);
	}
);
export default Button;
