import styles from '../Project8.module.css';
import { useState } from 'react';

const courseList = [
	{ courseId: 1, title: 'SDET Course | Cypress Playwright', price: 100, discountPct: 20 },
	{ courseId: 2, title: 'Playwright Automation Testing', price: 80, discountPct: 10 },
	{ courseId: 3, title: 'Cypress Automation Course', price: 10 },
];

const CoursesColumn = ({ addToCart, setIsCourseInCart, isCourseIncart }) => {
	return courseList.map(({ courseId, title, price, discountPct }) => (
		<div
			id={`course-${courseId}`}
			className={`${styles.courseColumn} column p-0 has-background-white`}
			key={courseId}
		>
			<div>
				<img src={require(`../images/course${courseId}.png`)} alt={`Course ${courseId}`} />
				<div className='p-2'>
					<h3>{title}</h3>
					<p className='my-3'>TechGlobal School</p>
					<p data-testid='full-price' className='mb-1 '>
						<strong>${price}</strong>
					</p>
				</div>
			</div>
			<div>
				<p
					className={`has-background-${
						discountPct === 20 ? 'danger' : discountPct === 10 ? 'warning' : ' '
					} has-text-centered mb-1`}
				>
					{discountPct ? (
						<span
							data-testid='discount'
							className={discountPct === 20 ? 'has-text-white' : 'has-text-black'}
							style={{ fontSize: '12px' }}
						>
							%{discountPct} Discount
						</span>
					) : (
						<br />
					)}
				</p>
				<button className='button is-primary is-fullwidth' onClick={() => addToCart(courseId)}>
					Add to Cart
				</button>
				{isCourseIncart?.inCart && isCourseIncart?.courseId === courseId && (
					<div className='notification is-danger mt-1'>
						<button className='delete' onClick={() => setIsCourseInCart(false)}></button>
						You already have <strong>{title}</strong> in your cart!
					</div>
				)}
			</div>
		</div>
	));
};

const CardColumn = ({ cart, totalPrice, orderPlaced, placeOrder }) => {
	return (
		<div className='column p-0 is-half  is-flex is-flex-direction-column is-justify-content-space-between has-background-white'>
			<div className='p-2'>
				<h1 className='mb-1' style={{ color: 'var(--mainBlue)' }}>
					Cart
				</h1>
				<p className='mb-2'>Items Added to Cart</p>
				{cart.length > 0 &&
					cart.map(({ courseId, title, price, discountPct }) => (
						// Render each course in the cart
						<div className={`course-card ${styles.hasBorder}`} key={courseId}>
							<img src={require(`../images/course${courseId}.png`)} alt={`Course ${courseId}`}></img>
							<div className='course-card-content'>
								<p className='mb-1 has-text-black'>{title}</p>
								<p className='mb-1 mt-1'>
									<span data-testid='final-price'>
										${price - (price * (discountPct ? discountPct : 0)) / 100}
									</span>
									{discountPct && <span data-testid='discount'>({discountPct} % off)</span>}
								</p>
							</div>
						</div>
					))}
			</div>
			<div className={styles.cardBottom}>
				<p id='total-price' className='mb-1 subtitle is-4 p-2'>
					Total: ${totalPrice}
				</p>
				{orderPlaced ? (
					<div className='notification is-success mt-1'>Your order has been placed.</div>
				) : (
					<button
						className='button is-primary mt-3 is-fullwidth is-flex is-justify-self-flex-end '
						onClick={placeOrder}
						disabled={cart.length === 0}
					>
						Place Order
					</button>
				)}
			</div>
		</div>
	);
};

const Columns = () => {
	const [cart, setCart] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);
	const [orderPlaced, setOrderPlaced] = useState();
	const [isCourseIncart, setIsCourseInCart] = useState({ inCart: false, courseId: null });

	const addToCart = (courseId) => {
		setOrderPlaced(false);
		// Check if the course with the specified courseId is already in the cart
		const isAlreadyInCart = cart.some((course) => course.courseId === courseId);
		if (isAlreadyInCart) {
			setIsCourseInCart({ inCart: true, courseId: courseId });
		} else {
			const currentCourse = courseList[courseId - 1];
			setCart([...cart, currentCourse]);
			setTotalPrice(totalPrice + currentCourse.price * (1 - (currentCourse.discountPct || 0) / 100));
		}
	};

	const placeOrder = () => {
		setOrderPlaced(true);
		setCart([]);
		setTotalPrice(0);
		setIsCourseInCart(false);
	};

	return (
		<div className='columns is-4-desktop is-1-mobile is-2-tablet m-0'>
			<CoursesColumn
				addToCart={addToCart}
				setIsCourseInCart={setIsCourseInCart}
				isCourseIncart={isCourseIncart}
			/>
			<CardColumn cart={cart} placeOrder={placeOrder} orderPlaced={orderPlaced} totalPrice={totalPrice} />
		</div>
	);
};

export const ShoppingCard = () => {
	return (
		<section className='section pt-4 pb-6 my-4' style={{ background: 'var(--mainGray)' }}>
			<h1 className='mt-2 mb-4' style={{ color: 'var(--mainBlue)' }}>
				Available Courses
			</h1>
			<Columns />
		</section>
	);
};
