const ex1 = `Write a function named as \`driverPermit()\` which takes a \`number\` as an argument and returns \`true\` if given \`number\` is at least 16, and \`false\` otherwise when invoked.
~~~js
driverPermit(15)        -> false
driverPermit(16)        -> true
driverPermit(25)        -> true
~~~`;

const ex2 = `Write a function named as \`evenOrOdd()\` which takes a \`number\` as an argument and returns \`EVEN\` if given \`number\` is even, and \`ODD\` otherwise when invoked.
~~~js
evenOrOdd(2)           	-> "EVEN"
evenOrOdd(5)           	-> "ODD"
evenOrOdd(0)           	-> "EVEN"
~~~`;

const ex3 = `Write a function named as \`posNegZero()\` which takes a \`number\` as an argument and returns \`POSITIVE\` if given \`number\` is positive, returns \`NEGATIVE\` if given \`number\` is negative and \`ZERO\` otherwise when invoked.
~~~js
posNegZero(2)           -> "POSITIVE"
posNegZero(0)           -> "ZERO"
posNegZero(-3)          -> "NEGATIVE"
~~~`;

const ex4 = `Write a function named as isEqual() which takes two arguments and returns true if given arguments are strictly equal and false otherwise when invoked.
~~~js
isEqual(2, 2)           -> true
isEqual("2", "2")       -> true
isEqual(2, "2")        	-> false
~~~`;

const ex5 = `Write a function named as fizzBuzz() which takes a number as an argument.
If the number is divisible by 3, return Fizz 
If the number is divisible by 5, return Buzz
If the number is divisible by both 3 and 5, return FizzBuzz
Otherwise, output the number itself.
~~~js
fizzBuzz(3)         -> "Fizz"
fizzBuzz(5)        	-> "Buzz"
fizzBuzz(45)        -> "FizzBuzz"
fizzBuzz(7)        	-> 7
~~~`;

const ex6 = `Write a function named as fooBar() which takes a number as an argument.
If the number is divisible by 2, return Foo 
If the number is divisible by 3, return Bar
If the number is divisible by both 2 and 3, return FooBar
Otherwise, output the number itself.
~~~js
fooBar(3)           -> "Bar"
fooBar(2)        	-> "Foo"
fooBar(24)        	-> "FooBar"
fooBar(5)        	-> 5
~~~`;

export const conditionalStatementsData = [
	{
		title: 'Driver Permit',
		content: ex1,
	},
	{
		title: 'Even-Odd',
		content: ex2,
	},
	{
		title: 'Positive-Negative-Neutral',
		content: ex3,
	},
	{
		title: 'Check if equal',
		content: ex4,
	},
	{
		title: 'FizzBuzz',
		content: ex5,
	},
	{
		title: 'FooBar',
		content: ex6,
	},
];
