import { useState } from 'react';
import styles from './Pagination.module.css';
import countries from './countries.json';
import { useSwipeable } from 'react-swipeable';
import Button from '../Partials/Buttons';

/**
 * Basic Pagination for this use case only
 */

const formatPopulation = (n) => n.toLocaleString('en-US');

const getCustomStyle = (idx, index) => {
	const customStyle = {
		width: idx === index ? '20px' : '15px',
		height: idx === index ? '20px' : '15px',
		background: 'lightgray',
		boxShadow: idx === index ? '0 0 2px 2px gray' : null,
		borderRadius: '100%',
		margin: '0 5px',
	};
	return customStyle;
};

const Pagination = () => {
	const [idx, setIdx] = useState(0);

	// Change slider page
	const changePage = (dir) => {
		if (dir === 'prev' && idx > 0) {
			setIdx((prev) => prev - 1);
		}
		if (dir === 'next' && idx < 4) {
			setIdx((prev) => prev + 1);
		}
	};

	// Handle swipe on mobile
	const swipeHandlers = useSwipeable({
		onSwipedLeft: () => changePage('next'),
		onSwipedRight: () => changePage('prev'),
	});

	return (
		<div>
			<h2 id='sub_heading' style={{ marginBottom: '10px' }}>
				World City Populations 2022
			</h2>
			<p id='content' style={{ marginBottom: '8px' }}>
				What are the most populated cities in the world? Here is a list of the top five most populated cities in
				the world:
			</p>
			<div>
				<div className={styles.pagBody}>
					<div className={styles.pagBodyData}>
						<p className='city_info'>
							<strong>City: </strong>
							{countries[idx].city}
						</p>
						<p className='country_info'>
							<strong>Country:</strong> {countries[idx].country}
						</p>
						<p className='population_info'>
							<strong>Population:</strong> {formatPopulation(countries[idx].population)}
						</p>
					</div>
					<div className={styles.pagBodyImage} {...swipeHandlers}>
						<img
							className='city_image'
							src={`/images/pagination/${countries[idx].image}`}
							alt={countries[idx].city}
						/>
					</div>
				</div>
				<div className={styles.pagControls}>
					<Button id='previous' disabled={idx === 0} onClick={() => changePage('prev')}>
						<span className='is-hidden-touch'>{'Previous <'} </span>
						<span className='is-hidden-desktop'>{'<'} </span>
					</Button>
					<div>
						<p style={getCustomStyle(idx, 0)} className='circle'></p>
						<p style={getCustomStyle(idx, 1)} className='circle'></p>
						<p style={getCustomStyle(idx, 2)} className='circle'></p>
						<p style={getCustomStyle(idx, 3)} className='circle'></p>
						<p style={getCustomStyle(idx, 4)} className='circle'></p>
					</div>
					<Button id='next' disabled={idx === 4} onClick={() => changePage('next')}>
						<span className='is-hidden-touch'>{'> Next'} </span>
						<span className='is-hidden-desktop'>{'>'} </span>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Pagination;
