import styles from './Mission.module.css';
import whoIcon from './images/who.svg';
import missionIcon from './images/mission.svg';
import goalIcon from './images/goal.svg';

const Mission = () => (
	<div className={styles.mission}>
		<div className={styles.cardBox}>
			<div>
				<div className={styles.title}>
					<img src={whoIcon} alt='who we are' />
					<h2>Who we are</h2>
				</div>
				<p>
					We are a group of experienced Software Engineers committed to maintaining a high standard of
					education through real-life projects and technologies.
				</p>
			</div>
			<div>
				<div className={styles.title}>
					<img src={missionIcon} alt='mission' />
					<h2>Our mission</h2>
				</div>
				<p>
					Our company's mission is to provide you with real-world industry-level experience and Software
					Engineering training to guide you in a way that makes you a competitive candidate to tackle in the
					IT world.
				</p>
			</div>
			<div>
				<div className={styles.title}>
					<img src={goalIcon} alt='goal' />
					<h2>Main goal</h2>
				</div>
				<p>
					The main goal of this website is to help TechGlobal School students practice their skills and
					prepare for real jobs. You'll find various testing practices, coding exercises, and essential
					information for every programmer.
				</p>
			</div>
		</div>
	</div>
);

export default Mission;
